import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlightInformationResponse } from '../../../dtos/response/flight-status-response/flight-information-response';
import { FlightServiceState, flightServiceFeatureKey, flightStatusAdapter } from './flight-service.state';
import { FlightInformationFlightLeg } from '../../../dtos/response/flight-status-response/flight-information-flight-leg';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { AirportInfo } from '../../../models/airport-info';

const getFlightServiceState = createFeatureSelector<FlightServiceState>(flightServiceFeatureKey);
const { selectEntities } = flightStatusAdapter.getSelectors();
export const getAllFlightStatusEntities = createSelector(getFlightServiceState, (state: FlightServiceState) =>
  selectEntities(state.flightStatus)
);
export const getFlightStatus = ( id: string ) => createSelector(
  getAllFlightStatusEntities,
  (entities: Dictionary<FlightInformationResponse>) => entities[id]
);
export const getFlightStatusLoading = ( id: string ) => createSelector(
  getAllFlightStatusEntities,
  (entities: Dictionary<FlightInformationResponse>) => {
    return entities[id] === undefined;
  }
);

export const getFlightLegsByFlightSegment = ( flightSegment: Segment ) => createSelector(
  getAllFlightStatusEntities,
  (entities: Dictionary<FlightInformationResponse>): FlightInformationFlightLeg[] => {
    const departureDate = flightSegment.departureDateTime?.split('T')[0];
    return entities[flightSegment.operatingAirlineFlightNumber + departureDate]?.
      flightInformation?.flightInformation?.flightLegs ?? [] as FlightInformationFlightLeg[];
  }
);

export const getMappingFlightLegWithFlightSegment = ( flightSegment: Segment ) => createSelector(
  getFlightLegsByFlightSegment( flightSegment ),
  (flightLegs: FlightInformationFlightLeg[]): FlightInformationFlightLeg =>
    flightLegs?.find((flightLeg) =>
        (flightLeg.origin?.iataStationCode === flightSegment.departureAirport
        && flightLeg.destination?.iataStationCode === flightSegment.arrivalAirport))
          ?? flightLegs[0]
);

export const getFlightLegsAirportInfosByFligtSegment = ( flightSegment: Segment ) => createSelector(
  getFlightLegsByFlightSegment( flightSegment ),
  (flightLegs: FlightInformationFlightLeg[]): AirportInfo[] => {
    const airportInfos: AirportInfo[] = [] as AirportInfo[];
    flightLegs?.forEach((flightLeg) => {
      const departure = {
        code: flightLeg.origin?.iataStationCode ?? null,
        name: flightLeg.origin?.airportName ?? null
      } as AirportInfo;
      if(departure.code && departure.name
          && !airportInfos.some((airportInfo) => airportInfo.code === departure.code && airportInfo.name === departure.name)){
        airportInfos.push(departure);
      }
      const arrival = {
        code: flightLeg.destination?.iataStationCode ?? null,
        name: flightLeg.destination?.airportName ?? null
      } as AirportInfo;
      if(arrival.code && arrival.name
          && !airportInfos.some((airportInfo) => airportInfo.code === arrival.code && airportInfo.name === arrival.name)){
        airportInfos.push(arrival);
      }
    });
    return airportInfos;
  }
);

export const getFlightStatusRequestStatus = createSelector(getFlightServiceState, (state: FlightServiceState) => state.status);
