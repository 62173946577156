import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';
import { AddSsrsResponse } from '../../../dtos/response/ssr-response/add-ssrs-response';
import { RemoveSsrStatus } from '../../../dtos/response/ssr-response/remove-ssr-status';
import { RootState } from '../../../state/state';
import { SsrService } from '../ssr.service';
import {
  addCbbgSsrs,
  addExstSsrs,
  addOthsTeenSsrs,
  addSsrsComplete,
  addSsrsFullFailure,
  addSsrsFullSuccess,
  addSsrsPartialSuccess,
  addSsrsWithRemark,
  addSvanSsrs,
  addUmnrSsrs, addNewLapInfant,
  addWheelchairSsrs,
  removeSsr,
  removeSsrFailure,
  removeSsrs,
  removeSsrSuccess,
  updateLapInfant,
  batchDeleteSsrs,
  batchDeleteSsrsComplete,
  batchDeleteSsrsFailure,
  addPetsSsrs
} from './ssr-service.actions';
import {
  getAddSsrWithRemarkRequest,
  getAddOthsTeenRequest,
  getAddSvanRequest,
  getAddUmnrRequest,
  getAddExstRequest,
  getAddCbbgRequest } from './ssr-service.selectors';
import {
  getConfirmedSegments,
  getRoutedConfirmationCode,
  getRoutedReservation,
} from '../../reservation-service/state/reservation-service.selectors';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import {
  AddUpdateLapInfantRequest,
} from '../../../dtos/request/ssr-requests/add-lapInfant-request';
import { LapInfantSecureDocInfo } from '../../../dtos/request/ssr-requests/LapInfantSecureDocInfo';
import { SsrType } from '../../../dtos/request/secure-flight-info/ssr-type';
import { Reservation } from '../../../dtos/response/reservation-response/reservation';
import { AddSsrSelectedFlight } from '../../../dtos/request/ssr-requests/add-ssr-selected-flight';
import { LapInfantData } from '../../../models/passenger-forms/lap-infant-data';

// Date of birth doc type literal
const DB_DOC_TYPE = 'db';

type SsrResponseActionType = typeof addSsrsFullSuccess | typeof addSsrsPartialSuccess | typeof addSsrsFullFailure;
@Injectable()
export class SsrServiceEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private ssrService: SsrService) {}

  /**
   * Triggered by addWheelchairSsrs action. Calls the ssrService.addWheelchairs method, and depending on the result
   * return a addWheelchairsFullSuccess, addWheelchairsPartialSuccess, or addWheelchairsFailure action
   */
  addWheelchairSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addWheelchairSsrs),
      mergeMap((action) =>
        this.ssrService.addWheelchairs(action.addWheelchairSsrsInput, action.confirmationCode, action.ssrDetails).pipe(
          map((result) => this.getSsrResponseAction(result))
        )
      ),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  /**
   * Triggered by addUmnrSsrs action. Calls the ssrService.addUmnrs method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  addUmnrSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUmnrSsrs),
      withLatestFrom(this.store.select(getAddUmnrRequest)),
      mergeMap(([action, request]) => {
        return this.ssrService.addUmnrs(request, action.confirmationCode, action.ssrDetails).pipe(
          map((result) => this.getSsrResponseAction(result))
        );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  /**
   * Triggered by addSsrsWithRemark action. Calls the ssrService.addSsrsWithRemark method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  addSsrsWithRemark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSsrsWithRemark),
      withLatestFrom(this.store.select(getAddSsrWithRemarkRequest)),
      mergeMap(([action, request]) => {
        return this.ssrService.addSsrsWithRemark(request, action.confirmationCode, action.ssrDetails, action.ssrSelectedOption).pipe(
          map((result) => this.getSsrResponseAction(result))
         );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  /**
   * Triggered by addSvanSsrs action. Calls the ssrService.addSvans method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  addSvanSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSvanSsrs),
      withLatestFrom(this.store.select(getAddSvanRequest)),
      mergeMap(([action, request]) => {
        return this.ssrService.addSvans(request, action.confirmationCode, action.ssrDetails).pipe(
          map((result) => this.getSsrResponseAction(result))
        );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  /**
   * Triggered by addExstSsrs action. Calls the ssrService.addExst method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
   addExstSsrs$ = createEffect(() =>
   this.actions$.pipe(
     ofType(addExstSsrs),
     withLatestFrom(this.store.select(getAddExstRequest)),
     mergeMap(([action, request]) => {
       return this.ssrService.addExsts(request, action.confirmationCode, action.ssrDetails).pipe(
         map((result) => this.getSsrResponseAction(result))
       );
     }),
     catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
   )
 );

  /**
   * Triggered by addCbbgSsrs action. Calls the ssrService.addCbbg method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  addCbbgSsrs$ = createEffect(() =>

    this.actions$.pipe(
      ofType(addCbbgSsrs),
      withLatestFrom(this.store.select(getAddCbbgRequest)),
      mergeMap(([action, request]) => {
        return this.ssrService.addCbbg(request, action.confirmationCode, action.ssrDetails).pipe(
          map((result) => this.getSsrResponseAction(result))
        );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  addOthsTeenSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOthsTeenSsrs),
      withLatestFrom(this.store.select(getAddOthsTeenRequest)),
      mergeMap(([action, request]) => {
        return this.ssrService.addOthsTeens(request, action.confirmationCode, action.ssrDetails).pipe(
          map((result: AddSsrsResponse) => {
            return addSsrsComplete(result.failedToAddSsrs, result.successfullyAddedSsrs, result.status);
          })
        );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  /**
   * Triggered by addNewInfant action. Calls the ssrService.addUpdateLapInfants method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  addLapInfantSsr$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addNewLapInfant),
      withLatestFrom(
        this.store.select(getConfirmedSegments),
        this.store.select(getRoutedConfirmationCode),
        this.store.select(getRoutedReservation)),
      mergeMap(([action, segments, confirmationCode, reservation]) => {
        // Map the request
        const request = this.getAddUpdateLapInfantRequest(
          segments,
          action?.ssrType, reservation,
          action?.lapInfantInfos,
          action?.infantSsrIds,
          action?.infantDocId);

        // Call the lap infant endpoint
        return this.ssrService.addLapInfants(request, confirmationCode).pipe(
          map((result) => this.getSsrResponseAction(result))
        );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, []))),
    );
  });

  /**
   * Triggered by updateNewInfant action. Calls the ssrService.updateLapInfants method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
    updateLapInfantSsr$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(updateLapInfant),
        withLatestFrom(
          this.store.select(getConfirmedSegments),
          this.store.select(getRoutedConfirmationCode),
          this.store.select(getRoutedReservation)),
        mergeMap(([action, segments, confirmationCode, reservation]) => {
          // Map the request
          const request = this.getAddUpdateLapInfantRequest(
            segments,
            action?.ssrType, reservation,
            action?.lapInfantInfos,
            action?.infantSsrIds,
            action?.infantDocId);

          // Call the lap infant endpoint
          return this.ssrService.updateLapInfants(request, confirmationCode).pipe(
            map((result) => this.getSsrResponseAction(result))
          );
        }),
        catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, []))),
      );
    });

  /**
   * Triggered by addPetSsrs action. Calls the ssrService.addPetSsrs method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  addPetsSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPetsSsrs),
      mergeMap((action) => {
        return this.ssrService.addPetsSsrs(action.addPetsReuqest, action.confirmationCode).pipe(
          map((result) => this.getPetSsrResponseAction(result))
        );
      }),
      catchError(() => of(addSsrsFullFailure(AddSsrStatus.UNCAUGHT, [])))
    )
  );

  removeSsr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSsr),
      mergeMap((action) =>
        this.ssrService.removeSsr(action.confirmationCode, action.ssrId, action.ssrCode).pipe(
          map((result) => {
            if (result.status === RemoveSsrStatus.SUCCESS) {
              return removeSsrSuccess(result.status);
            } else {
              return removeSsrFailure(result.status);
            }
          })
        )
      ),
      catchError(() => of(removeSsrFailure(RemoveSsrStatus.UNCAUGHT)))
    )
  );

  removeSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSsrs),
      mergeMap((action) =>
        this.ssrService.removeSsrs(action.confirmationCode, action.ssrIds).pipe(
          map((result) => {
            if (result.status === RemoveSsrStatus.SUCCESS) {
              return removeSsrSuccess(result.status);
            } else {
              return removeSsrFailure(result.status);
            }
          })
        )
      ),
      catchError(() => of(removeSsrFailure(RemoveSsrStatus.UNCAUGHT)))
    )
  );

  batchDeleteSsrs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(batchDeleteSsrs),
      mergeMap((action) =>
        this.ssrService.batchDeleteSsrs(action.confirmationCode, action.ssrIds).pipe(
          map((result) => {
            return batchDeleteSsrsComplete(result);
          })
        )
      ),
      catchError(() => of(batchDeleteSsrsFailure('Batch delete ssrs failed')))
    )
  );

  private getSsrResponseAction(result: AddSsrsResponse) {
    if (result.status === AddSsrStatus.SUCCESS) {
      return addSsrsFullSuccess(result.successfullyAddedSsrs, AddSsrStatus.SUCCESS);
    } else if (result.status === AddSsrStatus.PARTIAL_SUCCESS) {
      return addSsrsPartialSuccess(
        result.failedToAddSsrs, result.successfullyAddedSsrs, AddSsrStatus.PARTIAL_SUCCESS);
    } else {
      return addSsrsFullFailure(result.status, result.failedToAddSsrs);
    }
  }

  private getPetSsrResponseAction(result: AddSsrsResponse) {
    if (result.status === AddSsrStatus.PET_REMARKS_ADD_FAILURE) {
      return addSsrsFullFailure(result.status, result.failedToAddSsrs);
    } else if (result.status === AddSsrStatus.PET_QUEUE_FAILURE) {
      return addSsrsPartialSuccess(result.failedToAddSsrs, result.successfullyAddedSsrs, AddSsrStatus.PET_QUEUE_FAILURE);
    }
    return this.getSsrResponseAction(result);
  }


  // Map the lap infant request
  private getAddUpdateLapInfantRequest(
    activeSegments: Segment[],
    ssrType: SsrType,
    reservation: Reservation | null,
    lapInfantData: LapInfantData,
    inftSsrIds: string[] | null,
    infantDocId: string | null,): AddUpdateLapInfantRequest {

    let passengerNumber: string | undefined;
    let receivedFrom: string | undefined;
    let lapInfantDocInfo: LapInfantSecureDocInfo[] = [];
    const segmentDetails: AddSsrSelectedFlight[] = [];

    if (reservation && activeSegments && lapInfantData) {
      passengerNumber = reservation.passengers?.find(pax => pax.hashId === lapInfantData.associatedPassengerHashId)?.id;
      receivedFrom = reservation.passengers[0].firstName + ' ' + reservation.passengers[0].lastName;

      // Map confirmed segments details
      activeSegments?.forEach(segment => {
        segmentDetails.push({
          flightNumber: segment.operatingAirlineFlightNumber,
          origin: segment.departureAirport,
          destination: segment.arrivalAirport,
          carrierCode: segment.operatedByAirlineCode,
          departureDate: segment.departureDateTime,
          classOfService: segment.serviceClassCode,
          bookingStatus: segment.actionCode,
          segmentHashId: segment.hashId
        } as AddSsrSelectedFlight);
      });

      // Map lap infant information
      lapInfantDocInfo = [{
        id: infantDocId,
        firstName: lapInfantData?.bio?.firstName,
        middleName: lapInfantData?.bio?.middleName,
        lastName: lapInfantData?.bio?.lastName,
        birthday: lapInfantData?.bio?.dateOfBirth,
        gender: lapInfantData?.bio?.gender,
        docType: lapInfantData?.internationalDocument?.[0]?.documentType?.toString() || DB_DOC_TYPE,
        number: lapInfantData?.internationalDocument?.[0]?.documentNumber,
        citizenship: lapInfantData?.internationalDocument?.[0]?.countryOfCitizenship,
        issueCountry: lapInfantData?.internationalDocument?.[0]?.countryOfCitizenship,
        expirationDate: lapInfantData?.internationalDocument?.[0]?.expirationDate,
      } as LapInfantSecureDocInfo];
    }

    return {
      marketingCarrier: ssrType,
      passengerNumber,
      lapInfantDocInfo,
      segmentDetails,
      inftSsrIds,
      receivedFrom
    } as AddUpdateLapInfantRequest;
  }
}
