import { Action, createReducer, on } from '@ngrx/store';
import { initialDrawerDisplaysState } from './drawer-displays.state';
import { initializeDrawerDisplaysState, setFlightInfoDrawerSegments } from './drawer-displays.actions';
import { ExpandedFlightInformation } from '../../../models/expanded-flight-information/expanded-flight-information';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialDrawerDisplaysState,
  /**
   * Set the flight info drawer segment hashId
   */
  on(setFlightInfoDrawerSegments, (state, { flightInfoDrawerSegments }) => {
    const updatedFlightInfoDrawerSegments: ExpandedFlightInformation[] = flightInfoDrawerSegments.map((segment) => {
      const existingSegment = state.flightInfoDrawerSegments.find(
        (existingSeg) =>
          existingSeg.segment?.marketingAirlineFlightNumber &&
          segment?.marketingAirlineFlightNumber &&
          existingSeg.segment.marketingAirlineFlightNumber === segment.marketingAirlineFlightNumber
      );
      return {
        segment,
        onTimePercentage: existingSegment ? existingSegment.onTimePercentage : '',
        amenities: existingSegment ? existingSegment.amenities : [],
      };
    });
    return {
      ...state,
      flightInfoDrawerSegments: updatedFlightInfoDrawerSegments,
    };
  }),
  /**
   * Set the state to the initial value defined in the state file
   */
  on(initializeDrawerDisplaysState, (state) => ({ ...state, ...initialDrawerDisplaysState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function drawerDisplaysReducer(state = initialDrawerDisplaysState, action: Action) {
  return featureReducer(state, action);
}
