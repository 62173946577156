import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CheckedBaggageDrawerComponent } from './checked-baggage-drawer/checked-baggage-drawer.component';
import { UXModule } from '../../shared/ux/ux.module';
import { MessageBarsModule } from '../../shared/message-bars/message-bars.module';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [
    CheckedBaggageDrawerComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    UXModule,
    MessageBarsModule,
    DirectivesModule
  ],
  exports: [
    CheckedBaggageDrawerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CheckedBaggageModule {
}
