import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializePurchaseServiceState,
  purchase,
  purchaseComplete,
  setCaptureContext,
  setCyberSourcePurchaseTriggered,
  setMaskedCardNumber,
  setPaymentInfo,
  setPurchaseEmail,
  setPurchaseEmailValid
} from './purchase-service.actions';
import { initialPurchaseServiceState } from './purchase-service.state';

const featureReducer = createReducer(
  initialPurchaseServiceState,
  on(initializePurchaseServiceState, (state) => ({ ...state, ...initialPurchaseServiceState })),
  on(purchase, (state) => ({
    ...state,
    response: null,
    status: Status.UPDATING,
  })),
  on(purchaseComplete, (state, { response }) => ({
    ...state,
    response,
    status: Status.STABLE,
  })),
  on(setMaskedCardNumber, (state, { maskedCardNumber }) => ({
    ...state,
    maskedCardNumber,
  })),
  on(setPurchaseEmail, (state, { purchaseEmail }) => ({
    ...state,
    purchaseEmail,
  })),
  on(setPurchaseEmailValid  , (state, { purchaseEmailValid }) => ({
    ...state,
    purchaseEmailValid,
  })),
  on(setPaymentInfo, (state, { paymentInfo }) => ({
    ...state,
    paymentInfo,
  })),
  on(setCaptureContext, (state, { captureContext, success }) => ({
    ...state,
    captureContext,
    captureContextError: !success,
  })),
  on(setCyberSourcePurchaseTriggered, (state, { triggered }) => ({
    ...state,
    cyberSourcePurchaseTriggered: triggered,
  }))
);

export function purchaseServiceReducer(state = initialPurchaseServiceState, action: Action) {
  return featureReducer(state, action);
}
