import { isCouponPassengerIdMatchExtraSeatPassengerId } from '../../utils/passenger-helper';
import { GDSTicketsCloudModelsResponsesServiceCouponTicket } from '../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesServiceCouponTicket';
import { GDSTicketsCloudModelsResponsesTicketDetail } from '../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicketDetail';
import { Passenger } from '../../dtos/response/reservation-response/passenger';
import { Reservation } from '../../dtos/response/reservation-response/reservation';
import { Segment } from '../../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../../dtos/response/vcr-response/coupon-status-data';
import { FareType } from '../../dtos/response/vcr-response/fare-type';
import { PassengerSegmentCoupon } from '../../models/passenger-segment-coupon';
import { TicketDesignator } from '../../models/tickets/ticket-designator';

/**
 * A collection of helper functions for dealing with coupons
 */
export class CouponMapper {
  /**
   * Filters a list of coupons to find coupons owned by the given passenger and for the given segment
   * if the correct number of coupons are found, return them, if they are not, return null
   * @param passenger the guest that the coupon must be for
   * @param segment the segment the coupon must be for
   * @param reservation the reservation that is used for passenger matching if a simple match cannot be done
   * @param coupons the list of coupons to filter
   */
  public static getPassengerCoupon(
    passenger: Passenger,
    segment: Segment,
    reservation: Reservation,
    coupons: CouponStatusData[]
  ): PassengerSegmentCoupon | null {
    // filter the list for coupons that this passenger owns for the given segment
    const passengersCoupons = coupons?.filter((coupon: CouponStatusData) => {

      // flight number may come back with leading 0's, cast to int to remove
      const segmentMarketingFlightNumber = segment.marketingAirlineFlightNumber && parseInt(segment.marketingAirlineFlightNumber, 10);
      const segmentOperatingFlightNumber = parseInt(segment.operatingAirlineFlightNumber, 10);
      const couponFlightNumber = parseInt(coupon.flightNumber, 10);
      const couponOperatingFlightNumber = coupon.operatingFlightNumber && parseInt(coupon.operatingFlightNumber, 10);

      // For the codeshare flights, the coupon flight number can be different from the segment's operating flight number
      // but it should match the segment's marketing flight number or
      // coupon's operating flight number should match the segment's operating flight number
      // Techincally, coupon's flightNumber is the coupon's marketing flight number returned from the GDS
      const isCouponFlightNumberMatch =
        couponOperatingFlightNumber === segmentOperatingFlightNumber ||
        couponFlightNumber === segmentMarketingFlightNumber ||
        couponFlightNumber === segmentOperatingFlightNumber;

      return (
        coupon.isValid &&
        this.confirmPassengerMatch(coupon, passenger, reservation) &&
        segment &&
        isCouponFlightNumberMatch &&
        coupon.airlineCode === segment.marketedByAirlineCode &&
        new Date(coupon.departureDate).toUTCString() === new Date(segment.departureDateTime).toUTCString() &&
        coupon.departureAirport === segment.departureAirport &&
        coupon.arrivalAirport === segment.arrivalAirport &&
        coupon.classOfService === (segment.originalClassOfService ?? segment.serviceClassCode) &&
        ('OK' === coupon.status || 'CKIN' === coupon.status)
      );
    });

    // always expect at least one coupon for the current guest, and an additional coupon for each extra seat
    const expectedCoupons = 1 + (passenger.extraSeatRefs?.length ?? 0);

    // if number of validated coupons is equal to expected number, return the first coupon
    if (passengersCoupons?.length === expectedCoupons) {
      return {
        ticketNumber: passengersCoupons[0].ticketNumber,
        couponNumber: passengersCoupons[0].couponNumber,
        status: passengersCoupons[0].status,
        segmentHashId: segment.hashId,
        passengerHashId: passenger.hashId
      } as PassengerSegmentCoupon;
    } else {
      return null;
    }
  }

  /**
   * Determine if the given coupon belongs to the given passenger based on first and last name
   * @param target the guest the check against
   * @param coupon the coupon to check ownership of
   * @param reservation the reservation to check for multiple instances of the same name
   */
  public static isPassengerNameMatchFound(target: Passenger, coupon: CouponStatusData, reservation: Reservation): boolean {
    // ensure we have first and last names on both coupon and target then compare names and strip spaces
    // the first and last name can be separated by a space on the ticket but combined on the reservation
    if (
      coupon.firstName &&
      coupon.lastName &&
      target.firstName &&
      target.lastName &&
      coupon.firstName.replace(' ', '') === target.firstName.replace(' ', '') &&
      coupon.lastName.replace(' ', '') === target.lastName.replace(' ', '')
    ) {
      let occurrenceCount = 0;
      // loop through passengers on reservation and count how many times the target first and last name occur
      reservation.passengers.forEach((passenger) => {
        if (target.firstName === passenger.firstName && target.lastName === passenger.lastName) {
          occurrenceCount++;
        }
      });
      // if the name being checked occurs more than once we can not match 100%, return false
      return occurrenceCount === 1;
    } else {
      return false;
    }
  }

  /**
   * Convert a single ticket detail into an array of CouponStatusData
   * @param ticketDetail the ticket detail to be converted
   */
  public static getCouponsFromTicketDetail(ticketDetail: GDSTicketsCloudModelsResponsesTicketDetail): CouponStatusData[] {
    const ticket = ticketDetail.ticket;
    const passenger = ticket?.customer?.traveler;
    const conjunctiveTicketNumber = ticket?.relatedDocument?.conjunctive?.[0]?.number;
    return (
      ticket?.serviceCoupon?.map(
        (coupon) =>
          ({
            airlineCode: coupon.marketingProvider?.value,
            arrivalAirport: coupon.endLocation,
            classOfService: coupon.classOfService,
            couponNumber: coupon.coupon,
            departureAirport: coupon.startLocation,
            departureDate: coupon.startDateTime?.toString(),
            flightNumber: coupon.marketingFlightNumber,
            isValid: ticket.isValid,
            passengerId: passenger?.nameNumber,
            firstName: passenger?.firstName,
            lastName: passenger?.lastName,
            status: coupon.currentStatus,
            ticketNumber: ticket.number,
            formattedConjunctiveTicketNumber:
              ticket.number && conjunctiveTicketNumber ? `${ticket.number}-${conjunctiveTicketNumber.slice(-2)}` : undefined,
          } as CouponStatusData)
      ) ?? []
    );
  }

  public static convertFareTypes(serviceCoupon: GDSTicketsCloudModelsResponsesServiceCouponTicket): FareType[] {
    const result: FareType[] = [];
    if (serviceCoupon?.ticketDesignator === TicketDesignator.ASWD || serviceCoupon?.ticketDesignator === TicketDesignator.PAWD) {
      result.push(FareType.AWARD);
    }

    if (serviceCoupon?.ticketDesignator === TicketDesignator.BRV1) {
      result.push(FareType.BEREAVEMENT);
    }

    if (serviceCoupon?.ticketDesignator === TicketDesignator.EC9V || serviceCoupon?.ticketDesignator === TicketDesignator.EC99) {
      result.push(FareType.COMPANION);
    }

    return result;
  }

  /**
   * Determine if the reservation has been divided
   * @param reservation the reservation to check for if it has been divided
   */
  public static IsDividedReservation(reservation: Reservation): boolean {
    const regex = /^DIVIDED\/[A-Z]{3}\d[A-Z0-9]{3}/;
    const divided = reservation?.remarks?.flatMap((remark) => remark.remarkLines).find(
      (remarkLine) => remarkLine.match(regex)
    );
    return divided ? true : false;
  }

  /**
   * Determine if the given coupon belongs to the given passenger
   * @param coupon the coupon to check ownership of
   * @param passenger the guest the check against
   * @param reservation the reservation to be used for additional information if an id based match cannot be performed
   */
  private static confirmPassengerMatch(coupon: CouponStatusData, passenger: Passenger, reservation: Reservation): boolean {
    if(this.IsDividedReservation(reservation)){
      return (
        // If reservation is divided, only attempt to match on name
        coupon &&
        // match with extra seat passenger id
        (isCouponPassengerIdMatchExtraSeatPassengerId(coupon, passenger) ||
          // match on ID failed, attempt to match on name
          this.isPassengerNameMatchFound(passenger, coupon, reservation))
      );
    }
    else{
      return (
        coupon &&
        // attempt to match coupon passenger ID with passenger ID
        (coupon.passengerId === parseFloat(passenger.id.replace('.0', '.')).toString() ||
          // match with extra seat passenger id
        isCouponPassengerIdMatchExtraSeatPassengerId(coupon, passenger) ||
          // match on ID failed, attempt to match on name
          this.isPassengerNameMatchFound(passenger, coupon, reservation))
      );
    }
  }
}
