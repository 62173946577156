import { createAction } from '@ngrx/store';
import { SecureFlightInfoAddStatus } from '../../../dtos/response/secure-flight-info/secure-flight-info-add-status';
import { SecureFlightInfo } from '../../../dtos/request/secure-flight-info/secure-flight-info';
import { SecureFlightInfoResponse } from '../../../dtos/response/secure-flight-info/secure-flight-info-response';
import { Status } from '../../../models/status';
import { ssrServiceFeatureKey } from '../../ssr-service/state/ssr-service.state';
import { secureFlightInfoFeatureKey } from './secure-flight-info.state';

/**
 * Submits the secure flight info to the API
 */
export const submitSecureFlightInfo = createAction(
  `[${secureFlightInfoFeatureKey}] Submit secure flight info`,
  (request: SecureFlightInfo) => ({
    request,
  })
);

/**
 * Updates the status of a single secure flight info
 */
export const updateSecureFlightInfoStatus = createAction(
  `[${secureFlightInfoFeatureKey}] Update the status of a secure flight info`,
  (hashId: string, status: Status) => ({
    hashId, status
  })
);

/**
 * Called when the secure flight info API call is complete
 */
export const secureFlightInfoUpdateComplete = createAction(
  `[${secureFlightInfoFeatureKey}] Secure flight info update complete`,
  (response: SecureFlightInfoResponse, passengerHashId: string) => ({
    response,
    passengerHashId
  })
);

/**
 * Called when the user wants to remove known traveler/redress numbers
 */
 export const removeKtnRedressNumbers = createAction(
  `[${secureFlightInfoFeatureKey}] Remove known traveler and redress numbers`,
  (confirmationCode: string, passengerHashId: string) => ({
    confirmationCode,
    passengerHashId
  })
);

/**
 * Called when the remove secure flight info API call is complete
 */
 export const ktnRedressRemovalComplete = createAction(
  `[${secureFlightInfoFeatureKey}] Ktn redress removal complete`,
  (response: SecureFlightInfoResponse) => ({
    response,
  })
);

/**
 * Resets the secure flight info
 */
export const initializeSecureFlightInfoState = createAction(
  `[${secureFlightInfoFeatureKey}] Initialize secure flight info`);

export const setSecureFlightAddSrrStatus = createAction(
  `[${ssrServiceFeatureKey}] All Secure flight info successfully added to reservation`,
  (addSsrStatus: SecureFlightInfoAddStatus) => ({
    addSsrStatus,
  })
);
