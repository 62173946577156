import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, mergeMap, map, catchError, of } from 'rxjs';
import { AddDeleteRemarkStatus } from '../../../dtos/response/remarks-response/add-delete-remark-status';
import { RemarksAddRemoveResponse } from '../../../dtos/response/reservation-response/remarks-add-remove-response';
import { ReservationService } from '../../../services/reservation-service/reservation.service';
import { getRoutedConfirmationCode, getAddRainBookingRemarksRequest } from '../../../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../../../state/state';
import { addRainBookingRemarks, addRainBookingRemarksComplete } from './initial-booking.actions';

@Injectable()
export class InitialBookingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private reservationService: ReservationService,
  ) {}

  addRainBookingRemarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRainBookingRemarks),
      withLatestFrom(
        this.store.select(getRoutedConfirmationCode),
        this.store.select(getAddRainBookingRemarksRequest)
      ),
      mergeMap(([action, routedConfirmationCode, request]) => {
        return this.reservationService.addRainBookingRemarks(routedConfirmationCode, request).pipe(
          map((result: RemarksAddRemoveResponse) => {
            return addRainBookingRemarksComplete(result.status);
          }),
          catchError(() => {
            return of(addRainBookingRemarksComplete(AddDeleteRemarkStatus.SYSTEM_FAILURE));
          })
        );
      })
    )
  );
}
