import { createAction } from '@ngrx/store';
import { FlightSearchAirports } from '../../../models/flight-search-forms/flight-search-airports';
import { flightSearchFormsFeatureKey } from './flight-search-forms.state';
import { FlightSearchForm } from '../../../models/flight-search-forms/flight-search-form';

/**
 * Resets the flight search forms state
 */
export const initializeFlightSearchFormsState = createAction(`[${flightSearchFormsFeatureKey}] Initialize flight search forms state`);

/**
 * Reset the flight search form data for a given feature ID
 */
export const resetFlightSearchFormData = createAction(`[${flightSearchFormsFeatureKey}] Reset flight search form data for a feature`, (featureId: string) => ({ featureId }));

/**
 * Add/updates a flight search form routing data
 */
export const addUpdateFlightSearchFormRoutingData = createAction(
  `[${flightSearchFormsFeatureKey}] Add/update a flight search form routing data entity state`,
  (featureId: string, flightSearchFormData: FlightSearchAirports) => ({
    featureId,
    flightSearchFormData,
  })
);

/**
 * Add/updates a flight search departure date data
 */
export const addUpdateFlightSearchDepartureDateData = createAction(
  `[${flightSearchFormsFeatureKey}] Add/update a flight search departure date data entity state`,
  (featureId: string, flightSearchFormData: FlightSearchAirports) => ({
    featureId,
    flightSearchFormData,
  })
);

/**
 * Add/updates a flight search form data
 */
export const addUpdateFlightSearchFormData = createAction(
  `[${flightSearchFormsFeatureKey}] Add/update a flight search form data entity state`,
  (featureId: string, flightSearchFormData: FlightSearchForm) => ({
    featureId,
    flightSearchFormData,
  })
);
