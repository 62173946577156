import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-small-drawer',
  templateUrl: './small-drawer.component.html',
  styleUrls: ['./small-drawer.component.scss']
})
export class SmallDrawerComponent {
  @Input() id: string;
  @Input() header: string;
  @Input() showBetaTag = false;
  @Output() drawerClosed = new EventEmitter<void>();

  toggleClosed(): void
  {
    this.drawerClosed.emit();
  }
}
