import { createAction } from '@ngrx/store';
import { passengerFormsFeatureKey } from './passenger-forms.state';
import { PassengerForm } from '../../../models/passenger-forms/passenger-form';
import { BioForm } from '../../../models/passenger-forms/bio-form';
import { MileagePlanForm } from '../../../models/passenger-forms/mileage-plan-form';
import { KtnRedressForm } from '../../../models/passenger-forms/ktn-redress-form';
import { EmergencyContactForm } from '../../../models/passenger-forms/emergency-contact-form';
import { LapInfantForm } from '../../../models/passenger-forms/lap-infant-form';
import { ContactInfoForm } from '../../../models/passenger-forms/contact-info-form';
import { PassengerData } from '../../../models/passenger-forms/passenger-data';
import { LapInfantData } from '../../../models/passenger-forms/lap-infant-data';
import { MileagePlanAutoFillData } from '../../../models/passenger-forms/mileage-plan-auto-fill-data';
import { InternationalDocumentsForm } from '../../../models/passenger-forms/international-documents-form';
import { ContactTracingForm } from '../../../models/passenger-forms/contact-tracing-form';
import { ContactInformationForm } from '../../../models/contact-information-forms/contact-information-form';

/**
 * Resets the passenger forms state
 */
export const initializePasengerFormsState = createAction(`[${passengerFormsFeatureKey}] Initialize passenger forms state`);

/**
 * Creates a blank entity for a given passenger
 */
export const initializePasengerForm = createAction(
  `[${passengerFormsFeatureKey}] Initialize a passenger form entity state`,
  (passengerFormData: PassengerForm) => ({
    passengerFormData,
  })
);

/**
 * Creates a blank entity for a given lap infant
 */
export const initializeLapInfantForm = createAction(
  `[${passengerFormsFeatureKey}] Initialize a lap infant form entity state`,
  (lapInfantFormData: LapInfantForm) => ({
    lapInfantFormData,
  })
);


/**
 * Sets the form expanded state for the guest contact form entity
 */
export const setGuestContactFormExpanded = createAction(
  `[${passengerFormsFeatureKey}] Set form expanded for the guest contact form entity`,
  (formExpanded: boolean) => ({
    formExpanded,
  })
);

/**
 * Sets the form expanded state for a given passenger form entity
 */
export const setPassengerFormExpanded = createAction(
  `[${passengerFormsFeatureKey}] Set form expanded for a passenger form entity`,
  (passengerHashId: string, formExpanded: boolean) => ({
    passengerHashId,
    formExpanded,
  })
);

/**
 * Sets the form expanded state for a given lap infant form entity
 */
export const setLapInfantFormExpanded = createAction(
  `[${passengerFormsFeatureKey}] Set form expanded for a passenger form entity`,
  (passengerHashId: string, formExpanded: boolean) => ({
    passengerHashId,
    formExpanded,
  })
);

/**
 * Updates the status of guest contact info
 */
export const setContactInfoFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for passenger contact info form`,
  (contactInfoForm: ContactInfoForm) => ({
    contactInfoForm
  })
);

/**
 * Updates the status of the guest/caller contact information validity
 */
export const setContactInfoValidity = createAction(
  `[${passengerFormsFeatureKey}] Sets validity for passenger contact info form`,
  (formValid: boolean) => ({
    formValid
  })
);

/**
 * Updates the contactInfoSaved slice of state
 */
export const setContactInfoSaved = createAction(
  `[${passengerFormsFeatureKey}] Set passenger contact info saved`,
  (formSaved: boolean, lastSavedData: ContactInformationForm) => ({
    formSaved,
    lastSavedData,
  })
);

/**
 * Updates the infantCount slice of state
 */
export const addNewInfant = createAction(`[${passengerFormsFeatureKey}] add new infant`);

/**
 * Updates the infantIds slice of state, and remove the infant form entity from the store
 */
export const removeInfant = createAction(
  `[${passengerFormsFeatureKey}] remove infant`,
  (infantHashId: string) => ({
    infantHashId,
  })
);

/**
 * Sets the associated passenger form data for a given lap infant
 */
export const setLapInfantAssociatedPassengerFormData = createAction(
  `[${passengerFormsFeatureKey}] Set the associated passenger for a lap infant`,
  (lapInfantHashId: string, passengerHashId: string, isValid: boolean) => ({
    lapInfantHashId,
    passengerHashId,
    isValid,
  })
);

/**
 * Sets the mileage plan form data for a given passenger
 */
export const setPassengerMileagePlanFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for a passenger mileage plan form`,
  (passengerHashId: string, mileagePlanForm: MileagePlanForm, isLapInfant: boolean = false) => ({
    passengerHashId,
    mileagePlanForm,
    isLapInfant,
  })
);

/**
 * Sets the bio form data for a given passenger
 */
export const setPassengerBioFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for a passenger bio form`,
  (passengerHashId: string, bioForm: BioForm, isLapInfant: boolean = false) => ({
    passengerHashId,
    bioForm,
    isLapInfant,
  })
);

/**
 * Sets the ktn/redress form data for a given passenger
 */
export const setPassengerKtnRedressFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for a passenger ktn/redress form`,
  (passengerHashId: string, ktnRedressForm: KtnRedressForm, isLapInfant: boolean = false) => ({
    passengerHashId,
    ktnRedressForm,
    isLapInfant,
  })
);

/**
 * Sets the emergency contact form data for a given passenger
 */
export const setPassengerEmergencyContactFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for a passenger emergency contact form`,
  (passengerHashId: string, emergencyContactForm: EmergencyContactForm) => ({
    passengerHashId,
    emergencyContactForm,
  })
);

/**
 * Sets the international documents form data for a given passenger
 */
export const setPassengerInternationalDocumentsFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for a passenger international documents form`,
  (passengerHashId: string, internationalDocumentsForm: any, isLapInfant: boolean = false) => ({
    passengerHashId,
    internationalDocumentsForm,
    isLapInfant,
  })
);

/**
 * Sets the contact tracing form data for a given passenger
 */
export const setPassengerContactTracingFormData = createAction(
  `[${passengerFormsFeatureKey}] Sets data for a passenger contact tracing form`,
  (passengerHashId: string, contactTracingForm: any, isLapInfant: boolean = false) => ({
    passengerHashId,
    contactTracingForm,
    isLapInfant,
  })
);

/**
 * Sets the available data for a given passenger from the auto fill service
 */
export const autoFillPassengerData = createAction(
  `[${passengerFormsFeatureKey}] Auto fill passenger data`,
  (passengerHashId: string, autoFillData: MileagePlanAutoFillData) => ({
    passengerHashId,
    autoFillData,
  })
);

/**
 * Sets whether an emergency contact form should be shown for a given passenger
 */
export const setShowPassengerEmergencyContactForm = createAction(
  `[${passengerFormsFeatureKey}] Set a passenger's emergency contact show form`,
  (passengerHashId: string, showForm: boolean) => ({
    passengerHashId,
    showForm,
  })
);

/**
 * Sets whether an international documents form should be shown for a given passenger
 */
export const setShowPassengerInternationalDocumentsForm = createAction(
  `[${passengerFormsFeatureKey}] Set a passenger's international dcouments show form`,
  (passengerHashId: string, showForm: boolean) => ({
    passengerHashId,
    showForm,
  })
);

export const setShowPassengerContactTracingForm = createAction(
  `[${passengerFormsFeatureKey}] Set a passenger's contact tracing show form`,
  (passengerHashId: string, showForm: boolean) => ({
    passengerHashId,
    showForm,
  })
);

/**
 * Sets whether an emergency contact form has previously been saved for a given passenger
 */
export const setPassengerEmergencyContactSaved = createAction(
  `[${passengerFormsFeatureKey}] Set data saved for a passenger's emergency contact form`,
  (passengerHashId: string, saved: boolean) => ({
    passengerHashId,
    saved,
  })
);

/**
 * Sets whether an international documents form has previously been saved for a given passenger
 */
export const setPassengerInternationalDocumentsSaved = createAction(
  `[${passengerFormsFeatureKey}] Set data saved for a passenger's international dcouments form`,
  (passengerHashId: string, saved: boolean) => ({
    passengerHashId,
    saved,
  })
);

export const setPassengerContactTracingSaved = createAction(
  `[${passengerFormsFeatureKey}] Set data saved for a passenger's contact tracing form`,
  (passengerHashId: string, saved: boolean) => ({
    passengerHashId,
    saved,
  })
);

/**
 * Sets the last saved data for a given passenger
 */
export const setPassengerLastSavedData = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's form`,
  (passengerHashId: string, passengerData: PassengerData) => ({
    passengerHashId,
    passengerData,
  })
);

/**
 * Sets the last saved data for a given passenger
 */
export const setLapInfantLastSavedData = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a lap infant's form`,
  (infantHashId: string, lapInfantData: LapInfantData) => ({
    infantHashId,
    lapInfantData,
  })
);

/**
 * Sets whether a form for a given passenger has been saved
 */
export const setHasPrepopulatedData = createAction(
  `[${passengerFormsFeatureKey}] Set hasPrepopulatedData to true for a given form`,
  (passengerHashId: string, isLapInfant = false) => ({
    passengerHashId,
    isLapInfant
  })
);

/**
 * Sets whether a form for a given passenger has been saved
 */
export const setFormSaved = createAction(
  `[${passengerFormsFeatureKey}] Set form saved for a passenger's form`,
  (passengerHashId: string, isLapInfant: boolean = false) => ({
    passengerHashId,
    isLapInfant,
  })
);

/**
 * Sets whether mileage plan auto fill should be triggered for a given passenger
 */
export const setAutoFillTrigger = createAction(
  `[${passengerFormsFeatureKey}] Set auto fill trigger for a passenger's form`,
  (passengerHashId: string, autoFill: boolean = false) => ({
    passengerHashId,
    autoFill,
  })
);

/**
 * Sets whether mileage plan has been saved for a given passenger
 */
export const setPassengerMileagePlanSaved = createAction(
  `[${passengerFormsFeatureKey}] Set mileage plan saved flag for a passenger's form`,
  (passengerHashId: string, mileagePlanSaved: boolean, mileagePlanNumber) => ({
    passengerHashId,
    mileagePlanSaved,
    mileagePlanNumber
  })
);

/**
 * Sets infantIds slice of state
 */
export const setInfantIds = createAction(
  `[${passengerFormsFeatureKey}] Set infant ids`,
  (infantIds: number[]) => ({
    infantIds
  })
);

/**
 * Set the lastSavedData bio info for a passenger
 */
export const setLastSavedDataBioForm = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's bio form`,
  (passengerHashId: string, bioForm: BioForm, isLapInfant = false) => ({
    passengerHashId,
    bioForm,
    isLapInfant
  })
);

/**
 * Set the lastSavedData mileage plan info for a passenger
 */
export const setLastSavedDataMileagePlanForm = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's mileage plan form`,
  (passengerHashId: string, mpForm: MileagePlanForm, isLapInfant = false) => ({
    passengerHashId,
    mpForm,
    isLapInfant
  })
);

/**
 * Set the lastSavedData ktn/redress info for a passenger
 */
export const setLastSavedDataKtnRedressForm = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's ktn/redress form`,
  (passengerHashId: string, ktnRedressForm: KtnRedressForm, isLapInfant = false) => ({
    passengerHashId,
    ktnRedressForm,
    isLapInfant
  })
);

/**
 * Set the lastSavedData emergency contact info for a passenger
 */
export const setLastSavedDataEmergencyContactForm = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's emergency contact form`,
  (passengerHashId: string, emergencyContactForm: EmergencyContactForm, isLapInfant = false) => ({
    passengerHashId,
    emergencyContactForm,
    isLapInfant
  })
);

/**
 * Set the lastSavedData international documents info for a passenger
 */
export const setLastSavedDataInternationalDocumentsForm = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's international documents form`,
  (passengerHashId: string, internationalDocumentsForm: InternationalDocumentsForm, isLapInfant = false) => ({
    passengerHashId,
    internationalDocumentsForm,
    isLapInfant
  })
);

/**
 * Set the lastSavedData contact tracing info for a passenger
 */
export const setLastSavedDataContactTracingForm = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a passenger's contact tracing form`,
  (passengerHashId: string, contactTracingForm: ContactTracingForm, isLapInfant = false) => ({
    passengerHashId,
    contactTracingForm,
    isLapInfant
  })
);

/**
 * Set the lastSavedData associated passenger for a lap infant
 */
export const setLastSavedDataLapInfantAssociatedPassenger = createAction(
  `[${passengerFormsFeatureKey}] Set last saved data for a lap infant's associated passenger`,
  (lapInfantHashId: string, passengerHashId: string) => ({
    lapInfantHashId,
    passengerHashId,
  })
);

/**
 * Resets the international documents form data for a given passenger
 */
export const resetPassengerInternationalDocumentsForm = createAction(
  `[${passengerFormsFeatureKey}] Reset a passenger's international documents form`,
  (passengerHashId: string) => ({
    passengerHashId,
  })
);

/**
 * Resets the emergency contact form data for a given passenger
 */
export const resetPassengerEmergencyContactForm = createAction(
  `[${passengerFormsFeatureKey}] Reset a passenger's emergency contact form`,
  (passengerHashId: string) => ({
    passengerHashId,
  })
);

/**
 * Resets the contact tracing form data for a given passenger
 */
export const resetPassengerContactTracingForm = createAction(
  `[${passengerFormsFeatureKey}] Reset a passenger's contact tracing form`,
  (passengerHashId: string) => ({
    passengerHashId,
  })
);
