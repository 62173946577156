<link rel="stylesheet" href="https://unpkg.com/@alaskaairux/design-tokens@latest/dist/tokens/CSSCustomProperties.css" />
<auro-drawer id="{{ id }}" sm (toggle)="toggleClosed()">
  <span slot="header" class="header"
    >{{ header }}
    <ng-container *ngIf="showBetaTag">
      <auro-badge
        label
        style="color: #de750c; border: 2px solid #de750c; background-color: white; font-weight: bold"
        title="Beta: feature testing in progress"
        >Beta</auro-badge
      >
    </ng-container>
  </span>
  <span slot="content">
    <ng-content></ng-content>
  </span>
</auro-drawer>
