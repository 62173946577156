import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Loyalty } from '../../../dtos/response/reservation-response/loyalty';
import { Status } from '../../../models/status';
import {
  addPassengerLoyalty,
  removePassengerLoyalty,
  setPassengerLoyalty,
  syncPassengerLoyalty,
} from '../../reservation-service/state/reservation-service.actions';
import { ReservationMileagePlanService } from '../reservation-mileage-plan.service';
import { MileagePlanMessageConverter } from '../../../utils/message-converters/mileage-plan/mileage-plan-message-converter';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { addStackedMessage } from '../../message-service/state/message.actions';
import { MessageKey } from '../../../models/message/message-key';

@Injectable()
export class ReservationMileagePlanServiceEffects {
  constructor(
    private actions$: Actions,
    private reservationMileagePlanService: ReservationMileagePlanService,
    private mileagePlanMessageConverter: MileagePlanMessageConverter,
    private store: Store<RootState>
  ) { }

  addPassengerLoyalty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPassengerLoyalty),
      mergeMap(({
        confirmationCode,
        passengerHashId,
        mileagePlanDetails,
        frequentFlyerCarrier,
        frequentFlyerNumber,
      }) => {
        return this.reservationMileagePlanService.add(
          confirmationCode,
          mileagePlanDetails,
          frequentFlyerCarrier,
          frequentFlyerNumber
        ).pipe(
          switchMap((response) => {
            const loyalty: Loyalty = {
              airlineCode: response.success ? frequentFlyerCarrier : null,
              number: response.success ? frequentFlyerNumber : null,
              status: Status.STABLE,
              tierStatus: null,
              id: null,
            };
            if (response.success) {
              return [
                setPassengerLoyalty(confirmationCode, passengerHashId, loyalty),
                // need to dispatch getMileagePlan here to re-fetch the reservation to get the completed loyalty info
                // it is not returned by the mileagePlanService
                syncPassengerLoyalty(confirmationCode, passengerHashId),
              ];
            } else {
              // Dispatch error message
              this.mileagePlanMessageConverter.convertAddMileagePlanMessage(response);
              return [
                setPassengerLoyalty(confirmationCode, passengerHashId, null),
              ];
            }
          }),
          catchError((error) => {
            this.store.dispatch(addStackedMessage(MessageKey.UNKNOWN_WITH_LINK));
            return of(setPassengerLoyalty(confirmationCode, passengerHashId, null));
          })
        );
      })
    )
  );

  removePassengerLoyalty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePassengerLoyalty),
      mergeMap((action) => {
        return this.reservationMileagePlanService.remove(action.confirmationCode, action.loyaltyId).pipe(
          switchMap((response) => {
            if (response.success) {
              return [setPassengerLoyalty(action.confirmationCode, action.passengerHashId, null)];
            } else {
              // Dispatch error message
              this.mileagePlanMessageConverter.convertRemoveMileagePlanMessage(response);
              return [
                setPassengerLoyalty(action.confirmationCode, action.passengerHashId, null),
              ];
            }
          }),
          catchError((error) => {
            this.store.dispatch(addStackedMessage(MessageKey.UNKNOWN_WITH_LINK));
            return of(setPassengerLoyalty(action.confirmationCode, action.passengerHashId, null));
          })
        );
      })
    )
  );
}
