import { PurchaseResponse } from '../../../dtos/response/purchase-response/purchase-response';
import { BluefinShieldConexPayment } from '../../../models/payment-info/bluefin-payment';
import { CardOnFilePayment } from '../../../models/payment-info/card-on-file-payment';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';

export const purchaseServiceFeatureKey = 'purchaseService';

export interface State extends RootState {
  purchaseService: PurchaseServiceState;
}

export interface PurchaseServiceState {
  response: PurchaseResponse | null;
  status: Status;
  maskedCardNumber: string | null;
  purchaseEmail: string | null;
  purchaseEmailValid: boolean;
  paymentInfo: CardOnFilePayment | BluefinShieldConexPayment | null;
  captureContext: string;
  captureContextError: boolean;
  cyberSourcePurchaseTriggered: boolean;
}

export const initialPurchaseServiceState: PurchaseServiceState = {
  response: null,
  status: Status.STABLE,
  maskedCardNumber: null,
  purchaseEmail: null,
  purchaseEmailValid: false,
  paymentInfo: null,
  captureContext: '',
  captureContextError: false,
  cyberSourcePurchaseTriggered: false,
};
