import { Observable, catchError, defer, map, of, throwError, timeout } from 'rxjs';
import { SavePassengerRequest } from '../../dtos/request/save-passenger-request/save-passenger-request';
import { SavePassengerResponse } from '../../dtos/response/save-passenger-response/save-passenger-response';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TimeoutLimit } from '../../models/timeout-limit';
import { timeoutError } from '../../models/timeout-error';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { HttpStatusCode } from '../../dtos/http-status-code';
import { RemovePassengerRequest } from '../../dtos/request/save-passenger-request/remove-passenger-request';

export interface SavePassengerServiceAPI {
  savePassengerInfo(request: SavePassengerRequest, confirmationCode: string): Observable<SavePassengerResponse>;
  removePassengerInfo(request: SavePassengerRequest, confirmationCode: string): Observable<SavePassengerResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class SavePassengerService implements SavePassengerServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  savePassengerInfo(request: SavePassengerRequest, confirmationCode: string): Observable<SavePassengerResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http.post<SavePassengerResponse>(
      `api/reservation/${confirmationCode}/passenger/add-or-update/bulk-passenger-info`, request, options).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => response),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ statusCode: HttpStatusCode.GATEWAY_TIMEOUT, exceptionContent: 'Timeout', response: null });
        }
        if (err.status === HttpStatusCode.BAD_REQUEST) {
          return of({ statusCode: HttpStatusCode.BAD_REQUEST, exceptionContent: JSON.stringify(err.error) });
        }
        return of(
          {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
            exceptionContent: err.error.exceptionContent,
            response: err.error.response
          });
      })
    );
  }

  removePassengerInfo(request: RemovePassengerRequest, confirmationCode: string): Observable<SavePassengerResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http.post<SavePassengerResponse>(
      `api/reservation/${confirmationCode}/passenger/remove/passenger-info`, request, options).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => response),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ statusCode: HttpStatusCode.GATEWAY_TIMEOUT, exceptionContent: 'Timeout', response: null });
        }
        return of(
          {
            statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
            exceptionContent: err.error.exceptionContent,
            response: err.error.response
          });
      })
    );
  }
}
