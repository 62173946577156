import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { RootState } from '../../../state/state';
import { FlightSearchForm } from '../../../models/flight-search-forms/flight-search-form';

/**
 * The string name of the flight search forms 'slice' of state
 */
export const flightSearchFormsFeatureKey = 'flightSearchForms';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  flightSearchForms: FlightSearchFormsState;
}

/**
 * The state that represents the flight search forms
 */
export interface FlightSearchFormsState {
  flightSearchForms: FlightSearchFormEntity;
}

/**
 * Interface for storing FlightSearchForm objects as an ordered array of ids and an unordered dictionary
 */
export interface FlightSearchFormEntity extends EntityState<FlightSearchForm> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the FlightSearchForm entity
 */
export const flightSearchFormsAdapter: EntityAdapter<FlightSearchForm> = createEntityAdapter<FlightSearchForm>({
  selectId: (flightSearchForm) => flightSearchForm.featureId,
});

/**
 * Initial state of the FlightSearchFormEntity as defined by the flightSearchFormsAdapter
 */
export const initialFlightSearchFormsEntityState: FlightSearchFormEntity = flightSearchFormsAdapter.getInitialState({});


/**
 * Initial state for the flight search forms state
 */
export const initialFlightSearchFormsState: FlightSearchFormsState = {
  flightSearchForms: initialFlightSearchFormsEntityState,
};
