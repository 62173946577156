import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resource-center-drawer',
  templateUrl: './resource-center-drawer.component.html',
  styleUrls: ['./resource-center-drawer.component.scss']
})
export class ResourceCenterDrawerComponent implements OnInit {

  resourceCenterCategory: Map<string, Map<string, object>> = new Map();

  ngOnInit(): void {
    this.initializeEnterpriseToolsLinks();
    this.initializeImportantFormsLinks();
  }

  initializeEnterpriseToolsLinks() {
    this.resourceCenterCategory.set('Enterprise Tools', this.getAllEnterpriseToolsLinks());
  }

  initializeImportantFormsLinks() {
    this.resourceCenterCategory.set('Important Forms', this.getAllImportantFormsLinks());
  }

  // Preserve original property order
  originalOrder = (a: any, b: any): number => {
    return 0;
  };

  getAllEnterpriseToolsLinks() {
    return new Map < string, object > ([
      ['AMA', { id: 'resource-link-ama', linkText: 'AMA', url: 'https://alaskaair.kore.ai/' }],
      ['Post Departure Information (PDI)', { id: 'resource-link-pdi', linkText: 'Post Departure Information (PDI)', url: 'https://fit.alaskaair.com/PDISearch/' }],
      ['Call Center Operations (CCO) SharePoint', { id: 'resource-link-cco', linkText: 'Call Center Operations (CCO) SharePoint', url: 'https://alaskaair.sharepoint.com/sites/ASCSA/CallCenters/SitePages/Home.aspx' }],
      ['Email Archiver', { id: 'resource-link-email-archiver', linkText: 'Email Archiver', url: 'https://archiver.sageflo.com/login' }],
      ['Call Center Manual (CCM) Home', { id: 'resource-link-ccm-home', linkText: 'Call Center Manual (CCM) Home', url: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/default.aspx' }],
      ['Cornerstone', { id: 'resource-link-cornerstone', linkText: 'Cornerstone', url: 'https://aagtraining.csod.com/samldefault.aspx' }],
      ['Bulletin Archive', { id: 'resource-link-bulletin-archiver', linkText: 'Bulletin Archive', url: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16751' }],
      ['TeamAAG', { id: 'resource-link-teamaag', linkText: 'TeamAAG', url: 'https://www.teamaag.com/' }],
      ['Extreme Flight Irregularity (EFI) declaration', { id: 'resource-link-efi', linkText: 'Extreme Flight Irregularity (EFI) declaration', url: 'https://alaskaair.sharepoint.com/sites/ops/Custadvo/EFI/Lists/EFIDeclarations2/AllItems.aspx' }],
      ['Policy and Procedure SharePoint', { id: 'resource-link-policy-and-procedure', linkText: 'Policy and Procedure SharePoint', url: 'https://alaskaair.sharepoint.com/sites/ASCSA/SupportOps/PolicyProcedure/Pages/CallCenter.aspx' }],
      ['TravelDoc', { id: 'resource-link-travel-doc', linkText: 'TravelDoc', url: 'https://alaskaairlines.traveldoc.aero/' }],
      ['Care Framework', { id: 'resource-link-care-framework', linkText: 'Care Framework', url: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16620#framework' }],
      ['Alaska Visa Signature', { id: 'resource-link-alaska-visa-signature', linkText: 'Alaska Airlines Visa Signature® internal loyalty subsite', url: 'https://www.teamaag.com/content/page/65ce7e8eeeeeaa021e1a25be' }],
      ['Compensation History', { id: 'resource-link-compensation-history', linkText: 'Compensation history', url: 'https://compensationhistory.alaskaair.com/' }],
    ]);
  }

  getAllImportantFormsLinks() {
    return new Map <string, object> ([
      ['Refunds SharePoint', { id: 'resource-link-refunds-sharepoint', linkText: 'Refunds SharePoint', url: 'https://alaskaair.sharepoint.com/sites/finance/Fincontrol/AcctOp/RevAcctg/Sales/Refunds/Lists/Call%20Center%20Refund%20Request/New%20Items.aspx' }],
      ['Thinking of you', { id: 'resource-link-thinking-of-you', linkText: 'Thinking of you', url: 'https://app.smartsheet.com/b/form/ac722162e4264c62a06efe4ed4106d1f' }],
      ['Report an issue', { id: 'resource-link-report-issue', linkText: 'Report an issue', url: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx' }],
    ]);
  }
}
