import { createAction } from '@ngrx/store';
import { TicketSearchByRoute } from '../../../dtos/request/ticket-search-request/ticket-search-by-route';
import { TicketSearchByTicketNumberRequest } from '../../../dtos/request/ticket-search-request/ticket-search-by-ticket-number-request';
import { EMDTicketDetail } from '../../../dtos/response/emd-ticket-detail-response/emd-ticket-detail';
import { EMDTicketDetailError } from '../../../dtos/response/emd-ticket-detail-response/emd-ticket-detail-error';
import { GDSTicketsCloudModelsResponsesTicketDetail } from '../../../dtos/response/gds-ticket-cloud-response/gDSTicketsCloudModelsResponsesTicketDetail';
import { TicketDetailsError } from '../../../dtos/response/ticket-detail-response/ticket-detail-error';
import { TicketSearchError } from '../../../dtos/response/ticket-search-response/ticket-search-error';
import { VcrResponse } from '../../../dtos/response/vcr-response/vcr-response';
import { VcrStatus } from '../../../dtos/response/vcr-response/vcr-status';
import { GetVcrType } from '../../../models/get-vcr-type';
import { TicketSearchResultRow } from '../../../models/ticket-search-result-row';
import { ticketServiceFeatureKey, TicketServiceState } from './ticket-service.state';

/**
 * Trigger the reducer to set the ticket service state to its initial values
 */
export const initializeTicketServiceState = createAction(`[${ticketServiceFeatureKey}] Initialize`);

/**
 * Triggers the getVcr$ effect and triggers the reducer to set vcrLoading to true
 */
export const loadVcr = createAction(
  `[${ticketServiceFeatureKey}] Load VCR`,
  (ticketNumbers: string[], background: boolean, vcrType: GetVcrType, useCache?: boolean) => ({
    ticketNumbers,
    background,
    vcrType,
    useCache: useCache !== undefined ? useCache : true,
  })
);

/**
 * Passes cached state to the reducer to update the store
 */
export const reloadVcrSuccess = createAction(`[${ticketServiceFeatureKey}] Reload VCR Success`, (cachedState: TicketServiceState) => ({
  cachedState,
}));

/**
 * Passes new state from a network call to the reducer to update the store
 */
export const loadVcrSuccess = createAction(`[${ticketServiceFeatureKey}] Load VCR Success`, (vcrResponse: VcrResponse) => ({
  vcrResponse,
}));

/**
 * Passes a VcrResponse containing failure info to the reducer to update the store
 */
export const loadVcrFailure = createAction(`[${ticketServiceFeatureKey}] Load VCR Failure`, () => ({
  status: VcrStatus.SYSTEM_FAILURE,
}));

/**
 * Triggers the searchByRoute$ effect and triggers the store to set the searchStatus to UPDATING
 */
export const searchByRoute = createAction(
  `[${ticketServiceFeatureKey}] Search VCR by name and date`,
  (ticketSearchByRoute: TicketSearchByRoute) => ({
    ticketSearchByRoute,
  })
);

/**
 * Passes a list of ticket search results to the reducer to update the store
 */
export const searchByRouteSuccess = createAction(
  `[${ticketServiceFeatureKey}] Search VCR by name and date success`,
  (searchResults: TicketSearchResultRow[]) => ({
    searchResults,
  })
);

/**
 * Passes a ticket search error to the reducer to update the store
 */
export const searchByRouteFailure = createAction(
  `[${ticketServiceFeatureKey}] Search VCR by name and date failure`,
  (searchError: TicketSearchError) => ({
    searchError,
  })
);

/**
 * Triggers the searchByTicketNumber$ effect and triggers the store to set the searchStatus to UPDATING
 */
export const searchByTicketNumber = createAction(
  `[${ticketServiceFeatureKey}] Search VCR by ticket number`,
  (ticketSearchByTicketNumber: TicketSearchByTicketNumberRequest) => ({
    ticketSearchByTicketNumber,
  })
);

/**
 * Passes a list of ticket search results to the reducer to update the store
 */
export const searchByTicketNumberSuccess = createAction(
  `[${ticketServiceFeatureKey}] Search VCR by ticket number success`,
  (searchResults: TicketSearchResultRow[]) => ({
    searchResults,
  })
);

/**
 * Passes a ticket search error to the reducer to update the store
 */
export const searchByTicketNumberFailure = createAction(
  `[${ticketServiceFeatureKey}] Search VCR by ticket number failure`,
  (searchError: TicketSearchError) => ({
    searchError,
  })
);

/**
 * Reset the ticket search result parts of the ticket service state
 */
export const resetTicketSearchResults = createAction(`[${ticketServiceFeatureKey}] Reset ticket search results`);

/**
 * Set the selected state on a given ticket search result
 */
export const setTicketSearchResultSelected = createAction(
  `[${ticketServiceFeatureKey}] Set ticket search result row selected`,
  (id: string, selected: boolean) => ({
    id,
    selected,
  })
);

/**
 * Mark the search results with the given ticket numbers with the given pinned state
 */
export const setTicketSearchResultsPinned = createAction(
  `[${ticketServiceFeatureKey}] Pin selected results`,
  (ids: string[], pinned: boolean) => ({
    ids,
    pinned,
  })
);

/**
 * Triggers the getEMDTicketDetails$ effect to make a network call via the ticket service to get EMD ticket details
 */
export const getEMDTicketDetails = createAction(`[${ticketServiceFeatureKey}] Get EMD ticket details`, (ticketNumbers: string[]) => ({
  ticketNumbers,
}));

/**
 * Update the store with the given EMD Ticket Details
 */
export const getEMDTicketDetailsSuccess = createAction(
  `[${ticketServiceFeatureKey}] Get EMD ticket details success`,
  (EMDTicketDetails: EMDTicketDetail[]) => ({
    EMDTicketDetails,
  })
);

/**
 * Update the tickets in the store with the given error message
 */
export const getEMDTicketDetailsFailure = createAction(
  `[${ticketServiceFeatureKey}] Get EMD ticket details failure`,
  (ticketNumbers: string[], error: EMDTicketDetailError) => ({
    ticketNumbers,
    error,
  })
);

/**
 * Triggers the getTicketDetails$ effect to make a network call via the ticket service to get the full ticket details
 */
export const getTicketDetails = createAction(`[${ticketServiceFeatureKey}] Get ticket details`, (ticketNumbers: string[], background: string = 'true') => ({
  ticketNumbers,
  background,
}));

/**
 * Update the store with the given Ticket Details data
 */
export const getTicketDetailsSuccess = createAction(
  `[${ticketServiceFeatureKey}] Get ticket details success`,
  (ticketDetails: GDSTicketsCloudModelsResponsesTicketDetail[]) => ({
    ticketDetails,
  })
);

/**
 * Update the tickets in the store with the given error message
 */
export const getTicketDetailsFailure = createAction(
  `[${ticketServiceFeatureKey}] Get ticket details failure`,
  (ticketDetailsError: TicketDetailsError) => ({
    ticketDetailsError,
  })
);

/**
 * Reset the store ticket details data
 */
export const resetTicketDetails = createAction(`[${ticketServiceFeatureKey}] Reset ticket details`);

/**
 * Triggers the getAncillaryTicketDetails$ effect to make a network call via the ticket service to get the full ticket details
 */
export const getAncillaryTicketDetails = createAction(
  `[${ticketServiceFeatureKey}] Get ancillary ticket details`,
  (ticketNumbers: string[]) => ({
    ticketNumbers,
  })
);

/**
 * Update the store with the given Ancillary Ticket Details data
 */
export const getAncillaryTicketDetailsSuccess = createAction(
  `[${ticketServiceFeatureKey}] Get ancillary ticket details success`,
  (ancillaryTicketDetails: GDSTicketsCloudModelsResponsesTicketDetail[]) => ({
    ancillaryTicketDetails,
  })
);

/**
 * Update the ancillary tickets in the store with the given error message
 */
export const getAncillaryTicketDetailsFailure = createAction(
  `[${ticketServiceFeatureKey}] Get ancillary ticket details failure`,
  (ancillaryTicketDetailsError: TicketDetailsError) => ({
    ancillaryTicketDetailsError,
  })
);
