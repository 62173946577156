import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { FlightReshopRequest } from '../../dtos/request/reshop-request/flight-reshop-request';
import { FlightReshopResponse } from '../../dtos/response/reshop-response/flight-reshop-response';
import { ReshopResponse } from '../../dtos/response/reshop-response/reshop-response';
import { Store } from '@ngrx/store';
import { RootState } from '../../state/state';
import { addMultipleJetStreamMessagesNoDuplicate } from '../message-service/state/message.actions';
import { MessageKey } from '../../models/message/message-key';

export interface FlightReshopServiceAPI {
  getFlightReshopResults(request: FlightReshopRequest): Observable<FlightReshopResponse>;
}

@Injectable()
export class FlightReshopService implements FlightReshopServiceAPI {
  constructor(private http: HttpClient, private store: Store<RootState>) {}

  getFlightReshopResults(request: FlightReshopRequest): Observable<FlightReshopResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<ReshopResponse>('api/flight/shopping/reshop', request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return {
          reshopResponse: response,
        } as FlightReshopResponse;
      }),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          this.store.dispatch(addMultipleJetStreamMessagesNoDuplicate([MessageKey.RESHOP_FLIGHT_SEARCH_TIMEOUT]));
          return of({ error: 'Timeout' });
        } else {
          // Unsure which errors to catch at this time. Will add more as they are discovered.
          this.store.dispatch(addMultipleJetStreamMessagesNoDuplicate([MessageKey.UNKNOWN_WITH_LINK]));
          return of({ error: 'Uncaught' });
        }
      })
    );
  }
}
