import { Action, createReducer, on } from '@ngrx/store';
import { initialSavePassengerState, removePassengerAdapter, saveLapInfantAdapter, savePassengerAdapter } from './save-passenger.state';
import { initializeSavePassengerState, removePassengerData, removePassengerDataComplete, resetRemovePassengerData, resetSaveLapInfantResponseInfo, saveLapInfantDataComplete, savePassengerData, savePassengerDataComplete, setPassengerSaved, setRemovePassengerInfoType, updateLapInfantData } from './save-passenger.actions';
import { Status } from '../../../models/status';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';

const featureReducer = createReducer(
  initialSavePassengerState,
  /**
   * Reinitialize the secure flight info state
   */
  on(initializeSavePassengerState, (state) => ({ ...state, ...initialSavePassengerState })),
  /**
   * Submits the savePassenger request to the API
   */
  on(savePassengerData, (state, { passengerHashId }) => {
    return {
      ...state,
      savePassengerResponseInfos: savePassengerAdapter.setOne({
        passengerHashId,
        statusCode: null,
        exceptionContent: null,
        response: null,
        saved: false
      }, state.savePassengerResponseInfos),
      loadStatus: Status.LOADING
    };
  }),
  /**
   * Completes the savePassenger request to the API
   */
  on(savePassengerDataComplete, (state, { response, passengerHashId }) => {
    return {
      ...state,
      savePassengerResponseInfos: savePassengerAdapter.setOne({
        passengerHashId,
        statusCode: response.statusCode,
        exceptionContent: response.exceptionContent,
        response: response.response,
        saved: response.response?.failedList?.length === 0 && response.statusCode === 200
      }, state.savePassengerResponseInfos),
      loadStatus: Status.STABLE
    };
  }),
  /**
   * Submits the updateLapInfant request to the API
   */
  on(updateLapInfantData, (state, { request, passengerHashId }) => {
    return {
      ...state,
      saveLapInfantResponseInfos: saveLapInfantAdapter.setOne({
        infantHashId: passengerHashId,
        status: undefined,
        errorMessage: undefined,
        failedToAddSsrs: undefined,
        successfullyAddedSsrs: undefined,
        saved: false
      }, state.saveLapInfantResponseInfos),
      loadStatus: Status.STABLE
    };
  }),
  /**
   * Completes the AddUpdateLapInfantRequest to the API
   */
  on(saveLapInfantDataComplete, (state, { response, infantHashId }) => {
    return {
      ...state,
      saveLapInfantResponseInfos: saveLapInfantAdapter.setOne({
        infantHashId,
        status: response.status,
        errorMessage: response.errorMessage,
        failedToAddSsrs: response.failedToAddSsrs,
        successfullyAddedSsrs: response.successfullyAddedSsrs,
        saved: (!response?.failedToAddSsrs || response?.failedToAddSsrs?.length === 0) && response.status === AddSsrStatus.SUCCESS
      }, state.saveLapInfantResponseInfos),
      loadStatus: Status.STABLE
    };
  }),
  on(resetSaveLapInfantResponseInfo, (state, { infantHashId }) => {
    return {
      ...state,
      saveLapInfantResponseInfos: saveLapInfantAdapter.removeOne(infantHashId, state.saveLapInfantResponseInfos),
      loadStatus: Status.STABLE
    };
  }),
  /**
   * Submits the savePassenger request to the API to remove the International Documents
   */
  on(removePassengerData, (state, { passengerHashId }) => {
    return {
      ...state,
      removePassengerResponseInfos: removePassengerAdapter.setOne({
        passengerHashId,
        statusCode: null,
        exceptionContent: null,
        response: null,
        saved: false
      }, state.removePassengerResponseInfos),
      loadStatus: Status.LOADING
    };
  }),
  /**
   * Resets the removePassenger state for a passenger
   */
  on(resetRemovePassengerData, (state, { passengerHashId }) => {
    return {
      ...state,
      removePassengerResponseInfos: removePassengerAdapter.removeOne(passengerHashId, state.removePassengerResponseInfos),
      loadStatus: Status.STABLE,
      removePassengerInfoType: null
    };
  }),
  /**
   * Completes the savePassenger request to the API to remove the International Documents
   */
  on(removePassengerDataComplete, (state, { response, passengerHashId }) => {
    return {
      ...state,
      removePassengerResponseInfos: removePassengerAdapter.setOne({
        passengerHashId,
        statusCode: response.statusCode,
        exceptionContent: response.exceptionContent,
        response: response.response,
        saved: response.response?.failedList?.length === 0 && response.statusCode === 200
      }, state.removePassengerResponseInfos),
      loadStatus: Status.STABLE
    };
  }),
  on(setPassengerSaved, (state, { passengerHashId, saved }) => {
    return {
      ...state,
      savePassengerResponseInfos: savePassengerAdapter.updateOne({
        id: passengerHashId,
        changes: {
          saved
        }
      }, state.savePassengerResponseInfos)
    };
  }),
  /**
   * Sets the removal type
   */
  on(setRemovePassengerInfoType, (state, { dataSlice }) => {
    return {
      ...state,
      removePassengerInfoType: dataSlice,
    };
  }),
);

export function savePassengerReducer(state = initialSavePassengerState, action: Action) {
  return featureReducer(state, action);
}
