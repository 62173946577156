import { createAction } from '@ngrx/store';
import { AddDeleteRemarkStatus } from '../../../dtos/response/remarks-response/add-delete-remark-status';
import { initialBookingFeatureKey } from './initial-booking.state';

export const initializeInitialBookingState = createAction(`[${initialBookingFeatureKey}] Initialize`);

/**
 * Adds RAIN booking remarks to a reservation
 */
export const addRainBookingRemarks = createAction(
  `[${initialBookingFeatureKey}] Add Rain Booking Remarks`
);

/**
 * Adds RAIN booking remarks to a reservation
 */
export const addRainBookingRemarksComplete = createAction(
  `[${initialBookingFeatureKey}] Add Rain Booking Remarks Complete`,
  (status: AddDeleteRemarkStatus) => ({
    status
  })
);

/**
 * Resets add RAIN booking remarks status
 */
export const resetAddRainBookingRemarksStatus = createAction(
  `[${initialBookingFeatureKey}] Resets Add Rain Booking Remarks`
);

/**
 * Sets rainBookingRemarksAdded to true
 * This marks the entire Rain Booking Remarks process(add -> check error -> navigate) as complete.
 */
export const setRainBookingRemarksAdded = createAction(
  `[${initialBookingFeatureKey}] Sets rainBookingRemarksAdded property to true`
);

export const updateFareRulesCheckboxState = createAction(
  `[${initialBookingFeatureKey}] Fare Rules Checkbox State Change`,
  (checked: boolean) => ({ checked })
);

/**
 * Sets the Initial Booking flight search id
 */
export const setInitialBookingCurrentFlightSearchId = createAction(
  `[${initialBookingFeatureKey}] Set the current Initial Booking Flight Search Id`,
  (flightSearchId: string) => ({ flightSearchId })
);
