import { Injectable } from '@angular/core';
import { SeatMapLookupStatus } from '../../dtos/response/seat-map-lookup-response/seat-map-lookup-status';
import { SeatMapData } from '../../dtos/response/seat-map-lookup-response/seat-map-data';
import { Passenger } from '../../dtos/response/reservation-response/passenger';
import { PassengerSeatLocation } from '../../dtos/response/seat-map-lookup-response/passenger-seat-location';
import { SeatMapDisplayService } from '../../services/seat-map-display-service/seat-map-display.service';
import { SeatMap } from '../../models/seat-map/seat-map';
import { Row } from '../../models/seat-map/row';
import { Seat } from '../../models/seat-map/seat';
import { SpecialServiceRequest } from '../../dtos/response/reservation-response/special-service-request';
import { SeatMapSeatUtil } from './seat-map-seat-util';
import { Store } from '@ngrx/store';
import { RootState } from '../../state/state';
import { reservationSeatMapLookup, setSeatMapLookupCrudStatus } from '../../services/seat-service/state/seats-service.actions';
import { Status } from '../../models/status';
import { SeatMapLookupRequest } from '../../dtos/request/seat-map-lookup-request/seat-map-lookup-request';
import { RowData } from '../../dtos/response/seat-map-lookup-response/row-data';
import { RowProperties } from '../../dtos/response/seat-map-lookup-response/cabin-row-properties';

@Injectable({
  providedIn: 'root',
})
export class SeatMapUtil {
  constructor(
    private store: Store<RootState>,
    private seatMapDisplayService: SeatMapDisplayService,
    private seatMapSeatUtil: SeatMapSeatUtil
  ) {}

  /**
   * Lookup multiple reservation based seatmaps
   */
  public lookupReservationSeatMaps(seatMapRequests: SeatMapLookupRequest[]) {
    this.store.dispatch(setSeatMapLookupCrudStatus(Status.LOADING));
    seatMapRequests.forEach((seatMapRequest) => {
      this.store.dispatch(reservationSeatMapLookup(seatMapRequest));
    });
  }

  /**
   * Maps the seat map lookup error code to the SeatMapLookupStatus
   */
  public mapSeatMapLookupErrorStatus(errorCode: string): SeatMapLookupStatus {
    switch (errorCode) {
      case 'INVALID_DATES':
        return SeatMapLookupStatus.INVALID_DATES;
      case 'UNSUPPORTED_CARRIER':
        return SeatMapLookupStatus.UNSUPPORTED_CARRIER;
      case 'INSIDE_CHECK_IN_WINDOW':
        return SeatMapLookupStatus.INSIDE_CHECK_IN_WINDOW;
      case 'ALREADY_CHECKED_IN':
        return SeatMapLookupStatus.ALREADY_CHECKED_IN;
      case 'UNKNOWN_CITY_CODE':
        return SeatMapLookupStatus.UNKNOWN_CITY_CODE;
      default:
        return SeatMapLookupStatus.SYSTEM_FAILURE;
    }
  }

  /**
   * Sets up a seatmap display for a flight on a reservation
   *     i.e. a seatmap that has passenger data (seat assignments, ssr restrictions, etc)
   */
  public postProcessReservationSeatMap(seatMap: SeatMapData, preReservedSeats: PassengerSeatLocation[]): SeatMap {
    this.setupSeatDatas(seatMap);

    // Handle any pre-reserved seats
    if (preReservedSeats?.length >= 1) {
      const updatedPrereservedSeats = this.updateUpSellInformationForPassengerSeat(seatMap, preReservedSeats);
      seatMap = this.mapPreReservedSeatMapSeat(seatMap, updatedPrereservedSeats);
    }
    const updatedSeatMap = this.postProcessSeatMap(seatMap);
    return updatedSeatMap;
  }

  /**
   * Sets up a seatmap display for a flight not on a reservation
   *   i.e. a seatmap that does not have passenger data and is not being used to assign seats (flight search)
   */
  public postProcessStaticSeatMap(seatMap: SeatMapData): SeatMap {
    this.setupSeatDatas(seatMap);
    const updatedSeatMap = this.postProcessSeatMap(seatMap);
    return updatedSeatMap;
  }

  /**
   * Processes seatmap data returned from the API and maps it to a seatmap display
   */
  private postProcessSeatMap(seatMap: SeatMapData): SeatMap {
    // Extend the seatmap with new properties used for display
    return this.seatMapDisplayService.extendSeatMapData(seatMap);;
  }

  /**
   * Setup the seatdatas property used for display
   */
  private setupSeatDatas(seatMap: SeatMapData) {
    seatMap.rows?.forEach((row) => {
      this.handlePartialExitRows(row, seatMap);
    });
  }

  /**
   * Handle partial exit rows
   * Certain seatmap configurations have partial exit rows, meaning the exit row is only on one side of the aircraft
   */
  private handlePartialExitRows(row: RowData, seatMap: SeatMapData) {
    if (row && row.exitRow) {
      const seatMapRowData = seatMap.cabin?.rows?.find((seatMapRow) => seatMapRow.number === row.number);
      row.exitRowLeft = seatMapRowData?.properties?.includes(RowProperties.ExitRowLeft) || false;
      row.exitRowRight = seatMapRowData?.properties?.includes(RowProperties.ExitRowRight) || false;
    }
  }

  /**
   * Updates the pre-reserved seat information with the upsell information from seatmap data
   */
  private updateUpSellInformationForPassengerSeat(
    seatMap: SeatMapData,
    preReservedSeats: PassengerSeatLocation[]
  ): PassengerSeatLocation[] {
    const updatedPrereservedSeats: PassengerSeatLocation[] = [];
    preReservedSeats.forEach((seatLocation) => {
      const seat = seatMap.rows?.
        find((row) => row.number === seatLocation.row)?.seats?.
        find((seatData) => seatData.letter === seatLocation.letter);
      const seatLocationCopy = {
        ...seatLocation,
        upsellPrice: seat?.upsellPrice,
        upsellName: seat?.upsellName,
      };
      updatedPrereservedSeats.push(seatLocationCopy);
    });
    return updatedPrereservedSeats;
  }

  /**
   * Updates the seatmap with the pre-reserved seat information
   */
  private mapPreReservedSeatMapSeat(seatMap: SeatMapData, prereservedInPNR: PassengerSeatLocation[]): SeatMapData {
    prereservedInPNR.forEach((seatLocation) => {
      const seat = seatMap.rows?.
        find((row) => row.number === seatLocation.row)?.seats?.
        find((seatData) => seatData.letter === seatLocation.letter);
      if (seat) {
        seat.assignedToAnyPNRPassenger = true;
        seat.passenger = seatLocation.passenger;
      }
    });
    return seatMap;
  }

  /**
   * Return a seat
   * @param seatMap the seatmap to search for the seat
   * @param rowNumber the row number the seat is in
   * @param letter the letter of the seat
   */
  public getSeat(seatMap: SeatMap, rowNumber: number | undefined, letter: string | null): Seat | undefined {
    return this.getRow(seatMap, rowNumber)?.seats.find((seat) => seat.letter === letter);
  }

  /**
   * Return the window seat in a given row for a contiguous group of seats
   * @param seatMap the seatmap to search for the window seat
   * @param rowNumber the row number to check
   * @param contiguousGroupLetters the contiguous seating group (eg: not interrupted by an aisle A,B,C))
   */
  public getWindowSeat(seatMap: SeatMap, rowNumber: number | undefined, contiguousGroupLetters: string[] | undefined): Seat | null {
    if (contiguousGroupLetters) {
      for (const contiguousGroupLetter of contiguousGroupLetters) {
        const seatInGroup = this.getSeat(seatMap, rowNumber, contiguousGroupLetter);
        if (seatInGroup && seatInGroup.windowSeat) {
          return seatInGroup;
        }
      }
    }
    return null;
  }

  /**
   * Get a row based on row number
   * @param seatMap the seatmap to get the row from
   * @param rowNumber the row number to return
   */
  public getRow(seatMap: SeatMap, rowNumber: number | undefined): Row | undefined {
    return seatMap?.rows?.find((row) => rowNumber === row.number);
  }

  /**
   * Returns an array of all seats (for all rows).
   * @param seatMap the seat map to get the seats from
   */
  public getAllSeats(seatMap: SeatMap): Seat[] {
    return seatMap.rows?.flatMap((row) => row.seats) ?? [];
  }

  /**
   * Check if a seatmap has Premium seats that the list of passengers can be assigned to
   * @param seatMap the seatmap to check for premium seats
   * @param passengers the passengers to check for disqualifying ssrs when determining assignability
   * @param segmentIndex the 0 based index for the segment to check on the passenger SSRs array
   */
  public hasAssignablePremiumSeats(seatMap: SeatMap, passengers: Passenger[], segmentIndex: number): boolean {
    return this.getAllSeats(seatMap)
      .filter((seat: Seat) => seat.assignableInPNR && this.seatMapSeatUtil.hasUpsellPrice(seat))
      .some((seat: Seat) => {
        if (!seat.disqualifyingSSRs) {
          return true;
        }

        return passengers.some((passenger: Passenger) => {
          if (!passenger.ssrs || !passenger.ssrs.length || !passenger.ssrs || !passenger.ssrs.length) {
            return true;
          }
          let availableSeat = true;
          passenger.ssrs.forEach((ssr: SpecialServiceRequest) => {
            if (seat.disqualifyingSSRs.indexOf(ssr.serviceCode) > -1) {
              availableSeat = false;
            }
          });
          return availableSeat;
        });
      });
  }

  /**
   * Returns true if the seatmap has any premium seats that are not occupied
   */
  public checkPremiumSeatsUnoccupied(seatMap: SeatMap): boolean {
    if (seatMap) {
      for (const row of seatMap.rows) {
        for (const seat of row.seats) {
          if (seat.upsellName === 'Premium') {
            if (!seat.occupied) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}
