import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { GuestProfileResponse } from '../../../dtos/response/mileage-plan-profile-lookup-response/guest-profile-response';
import { MileagePlanProfileLookupStatus } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-status';
import { MessageKey } from '../../../models/message/message-key';
import { MileagePlanProfileSearchStatus } from '../../../dtos/response/mileage-plan-profile-search-response/mileage-plan-profile-search-status';
import { PersonLookup } from '../../../dtos/response/mileage-plan-profile-search-response/person-lookup';

/**
 * The string name of the mileage plan profile service "slice" of state
 */
export const mileagePlanProfileServiceFeatureKey = 'mileagePlanProfileService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  mileagePlanProfileService: MileagePlanProfileServiceState;
}

/**
 * The state that is used in relation to mileage plan profile service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface MileagePlanProfileServiceState {
  mileagePlanProfileLookupResponse: MileagePlanProfileLookupEntity;
  mileagePlanProfileLookupCRUDStatus: Status;
  mileagePlanProfileLookupStatus: MileagePlanProfileLookupStatus | null;
  mileagePlanProfileLookupMappedMessageKeys: MessageKey[];

  mileagePlanProfileSearchByPhoneNumberResponse: MileagePlanProfileSearchEntity;
  mileagePlanProfileSearchByPhoneNumberCRUDStatus: Status;
  mileagePlanProfileSearchByPhoneNumberStatus: MileagePlanProfileSearchStatus | null;
  mileagePlanProfileSearchByPhoneNumberMappedMessageKeys: MessageKey[];

  mileagePlanProfileSearchByNameAndDobResponse: MileagePlanProfileSearchEntity;
  mileagePlanProfileSearchByNameAndDobCRUDStatus: Status;
  mileagePlanProfileSearchByNameAndDobStatus: MileagePlanProfileSearchStatus | null;
  mileagePlanProfileSearchByNameAndDobMappedMessageKeys: MessageKey[];
}

export interface MileagePlanProfileLookupEntity extends EntityState<GuestProfileResponse> {}

export const mileagePlanProfileLookupAdapter: EntityAdapter<GuestProfileResponse> = createEntityAdapter<GuestProfileResponse>({
  selectId: (response) => response.mileagePlanNumber,
});

export const initialMileagePlanProfileLookupState: MileagePlanProfileLookupEntity = mileagePlanProfileLookupAdapter.getInitialState({});

// Search by phone number + name and dob entities
export interface MileagePlanProfileSearchEntity extends EntityState<PersonLookup> {}

export const mileagePlanProfileSearchAdapter: EntityAdapter<PersonLookup> = createEntityAdapter<PersonLookup>(
  {
    // Select ID based on phone number if it exists, otherwise use the combination of first name, last name, and date of birth
    selectId: (response) => {
      const phoneNumber = response?.searchByPhoneNumberRequest?.phoneNumber ?? '';
      const firstName = response?.searchByNameAndDobRequest?.firstName ?? '';
      const lastName = response?.searchByNameAndDobRequest?.lastName ?? '';
      const dateOfBirth = response?.searchByNameAndDobRequest?.dateOfBirth ?? '';

      return phoneNumber || `${firstName}${lastName}${dateOfBirth}`;
    },
  }
);

export const initialMileagePlanProfileSearchState: MileagePlanProfileSearchEntity = mileagePlanProfileSearchAdapter.getInitialState({});

/**
 * Initial state of the mileage plan profile service slice of state, used when mileage plan profile service state is
 * bootstrapped via the reducer in an app module also used when state is reset
 */
export const initialMileagePlanProfileServiceState: MileagePlanProfileServiceState = {
  mileagePlanProfileLookupResponse: initialMileagePlanProfileLookupState,
  mileagePlanProfileLookupCRUDStatus: Status.STABLE,
  mileagePlanProfileLookupStatus: null,
  mileagePlanProfileLookupMappedMessageKeys: [],

  mileagePlanProfileSearchByPhoneNumberResponse: initialMileagePlanProfileSearchState,
  mileagePlanProfileSearchByPhoneNumberCRUDStatus: Status.STABLE,
  mileagePlanProfileSearchByPhoneNumberStatus: null,
  mileagePlanProfileSearchByPhoneNumberMappedMessageKeys: [],

  mileagePlanProfileSearchByNameAndDobResponse: initialMileagePlanProfileSearchState,
  mileagePlanProfileSearchByNameAndDobCRUDStatus: Status.STABLE,
  mileagePlanProfileSearchByNameAndDobStatus: null,
  mileagePlanProfileSearchByNameAndDobMappedMessageKeys: [],
};
