import { createAction } from '@ngrx/store';
import { newsFeedServiceFeatureKey } from './news-feed-service.state';
import { NewsFeedResponse } from '../../../dtos/response/news-feed-response/news-feed-response';

export const initializeNewsFeedServiceState = createAction(`[${newsFeedServiceFeatureKey}] Initialize`);

export const clearNewsFeed = createAction(`[${newsFeedServiceFeatureKey}] Clear news feed`);

export const loadNewsFeed = createAction(`[${newsFeedServiceFeatureKey}] Load news feed`);

export const loadNewsFeedComplete = createAction(
  `[${newsFeedServiceFeatureKey}] Load News Feed Complete`,
  (newsFeedResponse: NewsFeedResponse | null) => ({
    newsFeedResponse,
  })
);
