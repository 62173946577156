import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { PhoneFieldService } from '../phone-field.service';
import { savePhoneFields, savePhoneFieldComplete } from './phone-field.actions';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { PhoneFieldMessageConverter } from '../../../utils/message-converters/phone-field/phone-field-message-converter';
import { MessageKey } from '../../../models/message/message-key';

@Injectable()
export class PhoneFieldEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private phoneFieldService: PhoneFieldService,
    private phoneFieldMessageConverter: PhoneFieldMessageConverter
  ) { }

  savePhoneFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePhoneFields),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        return this.phoneFieldService.savePhoneFields(action.request, confirmationCode).pipe(
          map((result) => {
            result = this.phoneFieldMessageConverter.convertSavePhoneFieldMessage(result);
            return savePhoneFieldComplete(result);
          }),
          catchError((err: HttpErrorResponse) => {
            const mappedErrorKeys = [MessageKey.RESERVATION_INFO_SAVE_FAILURE_GENERIC];
            return of(savePhoneFieldComplete({ statusCode: err.status, exceptionContent: err.message, mappedErrorKeys }));
          })
        );
      })
    )
  );
}
