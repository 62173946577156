import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import { initializeCardOnFileServiceState, loadCardsOnFile, loadCardsOnFileLookupComplete, resetCardsOnFile, setSelectedCardOnFile } from './card-on-file-service.actions';
import { cardsOnFileAdapter, initialCardOnFileServiceState } from './card-on-file-service.state';

const featureReducer = createReducer(
  initialCardOnFileServiceState,
  on(loadCardsOnFile, (state) => {
    return {
      ...state,
      status: Status.LOADING,
      cardsOnFileSuccess: true
    };
  }),
  on(resetCardsOnFile, (state) => ({
    ...state,
    cardsOnFile: cardsOnFileAdapter.removeAll(state.cardsOnFile),
    selectedCardOnFile: null,
    status: Status.LOADING,
    cardsOnFileSuccess: true
  })),
  on(loadCardsOnFileLookupComplete, (state, { cardsOnFile, success }) => {
    return {
      ...state,
      cardsOnFile: cardsOnFile && cardsOnFile.length > 0 ? cardsOnFileAdapter.setAll(cardsOnFile, state.cardsOnFile) : state.cardsOnFile,
      cardsOnFileSuccess: success,
      status: Status.STABLE
    };
  }),
  on(setSelectedCardOnFile, (state, { selectedCardOnFile }) => ({ ...state, selectedCardOnFile })),
  on(initializeCardOnFileServiceState, (state) => ({ ...state, ...initialCardOnFileServiceState }))
);


export function cardOnFileServiceReducer(state = initialCardOnFileServiceState, action: Action) {
  return featureReducer(state, action);
}
