<div class="dynamic-baggage-container">
    <div class="checked-baggage-advisory" *ngIf="showAdvisory">
      <app-jet-stream-short-message-bar></app-jet-stream-short-message-bar>
    </div>
    <div *ngIf="!showAdvisory">
        <div *ngIf="!isLoading; else loadingDots" >
            <ng-container *ngFor="let checkedBag of checkedBaggage; let i = index">
                <div class="checked-baggage-separator" [ngClass]="{ 'checked-baggage-separator-first-row': i===0 }">
                    <div class="checked-baggage-guest-container">
                        <div class="guest-name">{{checkedBag.guestFirstName | titlecase}} {{checkedBag.guestLastName | titlecase}}</div>
                        <div class="guest-name">{{checkedBag.bags.length}} {{checkedBag.bags.length > 1 ? 'bags' : 'bag'}}</div>
                    </div>
                    <ng-container *ngFor="let bag of checkedBag.bags">
                        <div class="checked-baggage-bag-container">
                            <div class="checked-baggage-icon-id-container">
                                <span class="bag-icon" inlineSVG="/assets/icon-baggage-title.svg"></span>
                                <div class="bag-tag-number">{{bag.bagTagNumber}}</div>
                            </div>
                            <div class="bag-checked-status">{{bag.bagCheckedStatus}}</div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <ng-template #loadingDots>
            <span class="loading-dots-container">
                <app-loading-dots></app-loading-dots>
            </span>
        </ng-template>
    </div>
</div>
