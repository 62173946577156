import { RootState } from '../../../state/state';
import { ExpandedFlightInformation } from '../../../models/expanded-flight-information/expanded-flight-information';

/**
 * The string name of the drawer displays 'slice' of state
 */
export const drawerDisplaysFeatureKey = 'drawerDisplays';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  drawerDisplays: DrawerDisplaysState;
}

/**
 * The state that represents the drawer displays
 */
export interface DrawerDisplaysState {
  flightInfoDrawerSegments: ExpandedFlightInformation[];
}

/**
 * Initial state for the drawer displays state
 */
export const initialDrawerDisplaysState: DrawerDisplaysState = {
  flightInfoDrawerSegments: [],
};
