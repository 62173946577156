import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { catchError, map, timeout } from 'rxjs/operators';
import { ZipLookupResponse } from '../../dtos/response/location-lookup-response/zip-lookup-response';
import { ZipLookupDetail } from '../../dtos/response/location-lookup-response/zip-lookup-detail';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';

export interface ZipLookupServiceAPI {
  getCityStateByZip(country: string, zip: string): Observable<ZipLookupResponse>;
}
/**
 * Injectable service to interface with fee endpoint in RAIN API
 */
@Injectable({
  providedIn: 'root',
})
export class ZipLookupService implements ZipLookupServiceAPI {
  constructor(
    private http: HttpClient,
    private eventService: GlobalEventService) { }

  getCityStateByZip(country: string, zip: string): Observable<ZipLookupResponse> {
    const options = {
      headers: new HttpHeaders({ }),
    };
    return this.http.get<ZipLookupDetail>(`api/zip-lookup?country=${country}&zip=${zip}`, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map(detail => {
        return { success: true, detail, error: null };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END);
          return observableOf({ success: false, response: null, error: err.statusText });
        }
        return observableOf({ success: false, response: null, error: err.statusText });
      })
    );
  }
}

