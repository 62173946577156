import { ContactInfoToEnrollAnotherGuest } from '../../../models/mileage-plan-enrollment/contact-info-to-enroll-another-guest';
import { RootState } from '../../../state/state';
import { MileagePlanEnrollmentRequest } from '../../../dtos/request/mileage-plan-enrollment-request';
import { Status } from '../../../models/status';
import { MileagePlanEnrollmentResult } from '../../../dtos/response/mileage-plan-response/mileage-plan-enrollment-result';

export const mileagePlanEnrollmentServiceFeatureKey = 'mileagePlanEnrollmentService';

export interface State extends RootState {
  mileagePlanEnrollmentService: MileagePlanEnrollmentServiceState;
}

export interface MileagePlanEnrollmentServiceState {
  mileagePlanData: MileagePlanEnrollmentRequest;
  contactInfoToEnrollAnotherGuest: ContactInfoToEnrollAnotherGuest;
  memberNumber: string | undefined;
  mileagePlanEnrollmentStatus: Status;
  mileagePlanEnrollmentResult: MileagePlanEnrollmentResult;
}

export const initialMileagePlanEnrollmentServiceState: MileagePlanEnrollmentServiceState = {
  mileagePlanData: null as unknown as MileagePlanEnrollmentRequest,
  contactInfoToEnrollAnotherGuest:
    {
      countryCode: '',
      phoneNumber: '',
      email: '',
      country: '',
      addressLine1: '',
      addressLine2: null,
      zipCode: '',
      city: '',
      state: '',
      shareContactInfoToEnrollAnotherGuest: false
    } as ContactInfoToEnrollAnotherGuest,
  memberNumber: null as unknown as string,
  mileagePlanEnrollmentStatus: Status.STABLE,
  mileagePlanEnrollmentResult: null as unknown as MileagePlanEnrollmentResult
};

