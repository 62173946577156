import { OsiStatus } from '../../../dtos/response/osi-response/osi-status';
import { TcpDetail } from '../../../dtos/response/osi-response/tcp-detail';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { AddOsiResponse } from '../../../dtos/response/osi-response/add-osi-response';
import { DeleteOsiResponse } from '../../../dtos/response/osi-response/delete-osi-response';

/**
 * The string name of the osi service "slice" of state
 */
export const osiServiceFeatureKey = 'osiService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  osiService: OsiServiceState;
}

/**
 * The state that is used in relation to osi service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface OsiServiceState {
  osiLoadingStatus: Status;
  osiDeletingStatus: Status;
  addOsiResponse: AddOsiResponse | null;
  deleteOsiResponse: DeleteOsiResponse | null;
  tcpOsiStatus?: OsiStatus | null;
  tcpOsiFailure?: TcpDetail[];
  tcpOsiSuccess?: TcpDetail[];
}

/**
 * Initial state of the osi service slice of state,
 * used when osi service state is bootstrapped via the reducer in an app module
 * also used when state is reset
 */
export const initialOsiServiceState: OsiServiceState = {
  osiLoadingStatus: Status.STABLE,
  osiDeletingStatus: Status.STABLE,
  addOsiResponse: null,
  deleteOsiResponse: null,
  tcpOsiStatus: null,
  tcpOsiSuccess: [],
  tcpOsiFailure: [],
};
