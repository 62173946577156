import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SdcRule } from '../../../dtos/response/sdc-eligibility-response/sdc-rule';
import { SdcEligibilityStatus } from '../../../dtos/response/sdc-eligibility-response/sdc-eligibility-status';
import { sdcEligibilityServiceFeatureKey, SdcEligibilityServiceState } from './sdc-eligibility-service.state';
import { getAllNamesMatchPnrAndVcr, getAllOKUsedAndCkinCoupons } from '../../ticket-service/state/ticket-service.selectors';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../../../dtos/response/vcr-response/coupon-status-data';
import { getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist } from '../../reservation-service/state/reservation-service.selectors';
import { Status } from '../../../models/status';

const getSdcEligibilityServiceState = createFeatureSelector<SdcEligibilityServiceState>(sdcEligibilityServiceFeatureKey);

/**
 * Returns true if the request for SDC eligibility loading
 */
export const getSdcEligibilityRequestLoading = createSelector(
  getSdcEligibilityServiceState,
  (state) => (state?.getSdcEligibleResponseStatus === null) && (state?.getSdcEligibleRequestStatus === Status.LOADING)
);

/**
 * Returns true if the request for SDC eligibility failed
 */
export const getSdcEligibilityRequestFailed = createSelector(
  getSdcEligibilityServiceState,
  (state) => state?.getSdcEligibleResponseStatus !== SdcEligibilityStatus.SUCCESS
);

/**
 * Returns true if the request for SDC eligibility timedout
 */
export const getSdcEligibilityRequestTimedOut = createSelector(
  getSdcEligibilityServiceState,
  (state) => state?.getSdcEligibleResponseStatus === SdcEligibilityStatus.TIMEOUT
);

/**
 * Returns the list of SDC ineligible reason messages returned from the service
 */
export const getSdcIneligibleReasonMessages = createSelector(
  getSdcEligibilityServiceState,
  (state: SdcEligibilityServiceState) =>
    state.sdcIneligibleReasons?.map(({ brokenRule, message }) => {
      switch (brokenRule) {
        case SdcRule.NO_GROUPS:
          return 'Group reservations with more than 7 guests are ineligible';
        case SdcRule.NO_GUEST_PASSES:
          return 'Reservation is booked on a guest pass or stand-by booking';
        case SdcRule.NO_INELIGIBLE_SSRS:
          return 'Reservation has one or more of the following ineligible SSRs: ' +
            'AVIH, DEPU, DEPA, MEDA, PNUT, PETC, STCR, TWOV, UMNR, and/or OTHS with certain remarks';
        case SdcRule.MISSING_PHONE_FIELD:
          return 'Phone number not on reservation and must be added';
        default:
          return message;
      }
    }) ?? []
);

export const getSdcEligibleSegmentSequenceIds = createSelector(
  getSdcEligibilityServiceState,
  (state: SdcEligibilityServiceState): string[] =>
    state?.sdcEligibleSegments
);

/**
 * Match the sdc eligible segment sequence IDs to their full segment data
 * Return a list of the full segment data for sdc eligible segments
 */
export const getSdcEligibleSegments = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  getSdcEligibleSegmentSequenceIds,
  (allSegments: Segment[], sdcEligibleSegmentSequenceIds: string[]): Segment[] =>
    allSegments.filter((segment) => sdcEligibleSegmentSequenceIds?.includes(segment.sequence))
);

/**
 * Returns true if SDC eligible segments have CKIN coupons, false otherwise.
 */
export const getSdcEligibleSegmentsHaveCkinCoupons = createSelector(
  getAllOKUsedAndCkinCoupons,
  getSdcEligibleSegments,
  (serviceCoupons: CouponStatusData[], segments: Segment[]): boolean => {
    let hasCkinCoupons = false;
    if (segments) {
      for (const segment of segments) {
        const matchingCoupons = serviceCoupons.filter((coupon) => {
          return segment.departureAirport === coupon.departureAirport && segment.arrivalAirport === coupon.arrivalAirport;
        });
        if (matchingCoupons.find((coupon) => coupon.status === 'CKIN')) {
          hasCkinCoupons = true;
        }
      }
    }
    return hasCkinCoupons;
  }
);

/**
 * Returns true if the current reservation is eligible for SDC (same day confirm)
 * Returns false if not eligible or if service request failed
 */
export const getSdcEligible = createSelector(
  getSdcEligibilityRequestFailed,
  getSdcEligibilityRequestTimedOut,
  getSdcEligibilityServiceState,
  getAllNamesMatchPnrAndVcr,
  getSdcEligibleSegmentsHaveCkinCoupons,
  (sdcEligibilityRequestFailed, sdcEligibilityRequestTimedOut, state, allNamesMatch, haveCkinCoupons) =>
    (state?.isSdcEligible &&
      !(sdcEligibilityRequestFailed || sdcEligibilityRequestTimedOut) &&
      allNamesMatch &&
      !haveCkinCoupons ? true : false)
);
