import { Injectable } from '@angular/core';
import { SeatMapData } from '../../dtos/response/seat-map-lookup-response/seat-map-data';
import { Row } from '../../models/seat-map/row';
import { Seat } from '../../models/seat-map/seat';
import { SSRCode } from '../../models/ssr/ssr-code';
import { SeatMap } from '../../models/seat-map/seat-map';

@Injectable({
  providedIn: 'root',
})
export class SeatMapDisplayService {
  seatMap: SeatMap;
  sectionedSeatLetters: string[][];
  windowLetters: string[];
  aisleLetters: string[];

  extendSeatMapData(seatMap: SeatMapData): SeatMap {

    // // Update the seatmap with the extended data
    const updatedSeatMap: SeatMap = {
      rows: seatMap.rows,
      aisleFollowsColumn: seatMap.aisleFollowsColumn,
      seatLetters: seatMap.seatLetters,
      cabinType: seatMap.cabin?.cabinType,
      maxContiguousCount: 0
    };

    this.populateSeatMapLayout(updatedSeatMap);
    return updatedSeatMap;
  }

  initializeSeatMapLayout(seatMap: SeatMap) {
    this.seatMap = seatMap;
    this.seatMap.maxContiguousCount = 0;
    this.populateSectionedSeatLetters();
    this.populateWindowLetters();
    this.populateAisleLetters();
  }

  /**
   * Sets window, aisle, assignableInPNR and contiguous seat indications. Seats are contiguous if they are not separated by an
   * aisle and are available to be assigned in the current PNR.
   * @param seatMap the seat map, which has unique row and aisle structures based on the aircraft/equipment type
   */
  populateSeatMapLayout(seatMap: SeatMap): void {
    this.initializeSeatMapLayout(seatMap);
    seatMap.rows.forEach((row: Row) => {
      this.setLayoutForRow(row);
    });
  }

  /**
   * Sets the layout properties of each seat in the row.
   * @param row the row with seats to populate the layout for
   * @param layout Seat map layout, containing sectionedSeatLetters seat letters in a row, grouped by aisle
   * separations, e.g. [ ['A','B','C'], ['D','E','F'] ]
   */
  setLayoutForRow(row: Row): void {
    this.sectionedSeatLetters.forEach((sectionLetters: string[]) => {
      const contiguousSeats: string[] = this.getContiguousSeatGroupings(row, sectionLetters);
      contiguousSeats.forEach((seats: string) => {
        this.setLayoutForRowSeatGroupings(row, seats);
      });

      this.postProcessSeatSection(row, sectionLetters);
    });
  }

  /**
   * Sets the layout properties for the contiguous seat group (e.g. 'ABC' or 'BC', or 'A') in the supplied row.
   * @param row The row that the group is in
   * @param seats The group of contiguous seat letters
   * @param layout Seat map layout
   */
  setLayoutForRowSeatGroupings(row: Row, seats: string) {
    const contiguousSeatGroupLetters: string[] = seats.split('');
    contiguousSeatGroupLetters.forEach((letter: string) => {
      const seat = row.seats.find((rowSeat) => rowSeat.letter === letter);
      if (seat) {
        seat.contiguousGroupLetters = contiguousSeatGroupLetters;
        this.setLayoutForSeat(seat, seats.length);
      }
    });
  }

  /**
   * Sets window, aisle, and contiguousCount indicators for the seat.
   * @param seat the seat to populate
   * @param contiguousCountForGroup the count of how many contiguous seats there are in the group that the seat is in
   * @param layout Seat Map layout data
   */
  setLayoutForSeat(seat: Seat, contiguousCountForGroup: number): void {
    seat.aisleSeat = this.aisleLetters.indexOf(seat.letter) > -1;

    if (seat.aisleSeat) {
      if (!seat.disqualifyingSSRs) {
        seat.disqualifyingSSRs = [];
      }
      if (contiguousCountForGroup >= 3 && seat.disqualifyingSSRs.indexOf(SSRCode.CBBG) < 0) {
        seat.disqualifyingSSRs.push(SSRCode.CBBG);
      }
    }

    seat.windowSeat = this.windowLetters.indexOf(seat.letter) > -1;

    if (!seat.assignableInPNR) {
      seat.contiguousCount = 0;
    } else {
      seat.contiguousCount = contiguousCountForGroup;
      if (this.seatMap.maxContiguousCount < seat.contiguousCount) {
        this.seatMap.maxContiguousCount = seat.contiguousCount;
      }
    }
  }

  /**
   * Groups seat letters into contiguous seat chunks. Any seat that is not assignable in the current PNR will break
   * contiguousness.
   *
   * Example
   *    sectionLetters: ['A','B','C']
   *    If only seat A is occupied by a passenger outside of the current PNR, the return value of this function will be ['A','BC']
   *    If only seat B is occupied by a passenger outside of the current PNR, the return value of this function will be ['A','B','C']
   *
   * @param row The row that the seats with the section letters are in
   * @param sectionLetters seat row letters that are on the same side of an aisle
   */
  getContiguousSeatGroupings(row: Row, sectionLetters: string[]): string[] {
    const aisleChar = '|';
    const seatLettersClone = sectionLetters.slice(0);
    let unusableCount = 0;
    sectionLetters.forEach((letter: string, letterIndex: number) => {
      const seat = row.seats.find((rowSeat) => rowSeat.letter === letter);
      if (seat) {
        const unusable = this.isSeatUnusable(seat);
        if (unusable) {
          if (letterIndex && aisleChar !== seatLettersClone[letterIndex + unusableCount - 1]) {
            seatLettersClone.splice(letterIndex + unusableCount, 0, aisleChar);
            unusableCount++;
          }
          if (letterIndex < sectionLetters.length - 1) {
            seatLettersClone.splice(letterIndex + 1 + unusableCount, 0, aisleChar);
            unusableCount++;
          }
        }
      }
    });

    return seatLettersClone.join('').split(aisleChar);
  }

  /**
   * Returns true if there is no seat at the seat's location (maybe there is a lavatory there instead) or if the seat is
   * already occupied by a passenger outside of the current PNR. This function sets the seat's assignableInPNR value.
   * @param seat the seat to examine
   */
  isSeatUnusable(seat: Seat): boolean {
    let unusable = false;
    if (seat.missing || (seat.occupied && !seat.assignedToAnyPNRPassenger)) {
      unusable = true;
    }
    seat.assignableInPNR = !unusable;
    return unusable;
  }

  /**
   * After layout properties are set on each seat, this function will run additional logic (per section) that cannot be run
   * until the layout properties are already set.
   * @param row The row that the seats with the section letters are in
   * @param sectionLetters seat row letters that are on the same side of an aisle
   */
  postProcessSeatSection(row: Row, sectionLetters: string[]) {
    let windowSeat: Seat | null = null;
    const nonWindowSeats: Seat[] = [];
    sectionLetters.forEach((letter: string) => {
      const seat = row.seats.find((rowSeat) => rowSeat.letter === letter);
      if (seat) {
        if (seat.windowSeat) {
          windowSeat = seat;
        } else {
          nonWindowSeats.push(seat);
        }
      }
    });

    this.processWindowSeatBlock(windowSeat, nonWindowSeats);
  }

  private processWindowSeatBlock(windowSeat: Seat | null, nonWindowSeats: Seat[]) {
    if (windowSeat && !windowSeat.assignableInPNR) {
      nonWindowSeats.forEach((seat: Seat) => {
        if (seat.assignableInPNR) {
          if (!seat.disqualifyingSSRs) {
            seat.disqualifyingSSRs = [];
          }
          if (seat.disqualifyingSSRs.indexOf(SSRCode.CBBG) < 0) {
            seat.disqualifyingSSRs.push(SSRCode.CBBG);
          }
        }
      });
    }
  }

  /**
   * Groups the seat letters in a seat map row, based upon aisle separators.
   * Example
   *    seatLetters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
   *    aisleFollowsColumns: ['B', 'E', 'H'];
   *    sectionedSeatLetters will be: [ ['A','B'], ['C','D','E'], ['F','G','H'], ['I','J'] ]
   */
  private populateSectionedSeatLetters(): void {
    const aisleChar = '|';
    const seatLetters: string[] = this.seatMap.seatLetters;
    const aisleFollowsColumns: string[] = this.seatMap.aisleFollowsColumn;
    const clone = seatLetters.slice(0);
    if (aisleFollowsColumns && aisleFollowsColumns.length) {
      aisleFollowsColumns.forEach((aisle: string) => {
        clone.splice(clone.indexOf(aisle) + 1, 0, aisleChar);
      });

      const sectionStrings: string[] = clone.join('').split(aisleChar);
      this.sectionedSeatLetters = [];
      sectionStrings.forEach((section: string) => {
        if (section.length) {
          this.sectionedSeatLetters.push(section.split(''));
        }
      });
    } else {
      this.sectionedSeatLetters = [clone];
    }
  }

  private populateWindowLetters(): void {
    this.windowLetters = [this.seatMap.seatLetters[0], this.seatMap.seatLetters[this.seatMap.seatLetters.length - 1]];
  }

  private populateAisleLetters(): void {
    this.aisleLetters = [];
    if (this.sectionedSeatLetters.length <= 1) {
      return;
    }
    this.sectionedSeatLetters.forEach((sectionLetters: string[], sectionIndex: number) => {
      if (sectionIndex === 0) {
        this.aisleLetters.push(sectionLetters[sectionLetters.length - 1]);
      } else if (sectionIndex === this.sectionedSeatLetters.length - 1) {
        this.aisleLetters.push(sectionLetters[0]);
      } else {
        this.aisleLetters.push(sectionLetters[0]);
        this.aisleLetters.push(sectionLetters[sectionLetters.length - 1]);
      }
    });
  }
}
