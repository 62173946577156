import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  getAncillaryTicketDetails,
  getAncillaryTicketDetailsFailure,
  getAncillaryTicketDetailsSuccess,
  getEMDTicketDetails,
  getEMDTicketDetailsFailure,
  getEMDTicketDetailsSuccess,
  getTicketDetails,
  getTicketDetailsFailure,
  getTicketDetailsSuccess,
  initializeTicketServiceState,
  loadVcr,
  loadVcrFailure,
  loadVcrSuccess,
  reloadVcrSuccess,
  resetTicketDetails,
  resetTicketSearchResults,
  searchByRoute,
  searchByRouteFailure,
  searchByRouteSuccess,
  searchByTicketNumber,
  searchByTicketNumberFailure,
  searchByTicketNumberSuccess,
  setTicketSearchResultSelected,
  setTicketSearchResultsPinned,
} from './ticket-service.actions';
import {
  ancillaryTicketDetailsAdapter,
  couponAdapter,
  EMDAdapter,
  initialTicketServiceState,
  searchResultsAdapter,
  ticketDetailsAdapter,
} from './ticket-service.state';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialTicketServiceState,
  /**
   * Set vcrLoading to true
   */
  on(loadVcr, (state) => ({ ...state, vcrLoading: true })),
  /**
   * Replace state with cached state and set loading to false
   */
  on(reloadVcrSuccess, (state, { cachedState }) => ({
    ...state,
    coupons: cachedState.coupons,
    status: cachedState.status,
    vcrLoading: false,
  })),
  /**
   * Add/update coupons, set fare types and status and set loading to false
   */
  on(loadVcrSuccess, (state, { vcrResponse }) => ({
    ...state,
    coupons: couponAdapter.upsertMany(vcrResponse.coupons ?? [], state.coupons),
    status: vcrResponse.status,
    vcrLoading: false,
  })),
  /**
   * Add/update using an empty array for coupons, set the fareTypes and status, set loading to false
   */
  on(loadVcrFailure, (state, { status }) => ({
    ...state,
    status,
    vcrLoading: false,
  })),
  /**
   * Remove all unpinned search results in the store, set searchStatus to UPDATING
   */
  on(searchByRoute, (state) => ({
    ...state,
    searchResults: searchResultsAdapter.removeMany((result) => !result.pinned, state.searchResults),
    searchError: null,
    searchStatus: Status.UPDATING,
    searchStartTime: state.searchStartTime ? state.searchStartTime : Date.now(),
  })),
  /**
   * Add search results in the store, set searchStatus to STABLE
   */
  on(searchByRouteSuccess, (state, { searchResults }) => ({
    ...state,
    searchResults: searchResultsAdapter.addMany(searchResults, state.searchResults),
    searchStatus: Status.STABLE,
  })),
  /**
   * Set searchError, set searchStatus to STABLE
   */
  on(searchByRouteFailure, (state, { searchError }) => ({
    ...state,
    searchError,
    searchStatus: Status.STABLE,
  })),
  /**
   * Remove all unpinned search results in the store, set searchStatus to UPDATING
   */
  on(searchByTicketNumber, (state) => ({
    ...state,
    searchResults: searchResultsAdapter.removeMany((result) => !result.pinned, state.searchResults),
    searchError: null,
    searchStatus: Status.UPDATING,
    searchStartTime: state.searchStartTime ? state.searchStartTime : Date.now(),
  })),
  /**
   * Add search results in the store, set searchStatus to STABLE
   */
  on(searchByTicketNumberSuccess, (state, { searchResults }) => ({
    ...state,
    searchResults: searchResultsAdapter.addMany(searchResults, state.searchResults),
    searchStatus: Status.STABLE,
  })),
  /**
   * Set searchError, set searchStatus to STABLE
   */
  on(searchByTicketNumberFailure, (state, { searchError }) => ({
    ...state,
    searchError,
    searchStatus: Status.STABLE,
  })),
  /**
   * Update selected state for a given search result
   */
  on(setTicketSearchResultSelected, (state, { id, selected }) => ({
    ...state,
    searchResults: searchResultsAdapter.updateOne({ id, changes: { selected } }, state.searchResults),
  })),
  /**
   * Map the array of id's to a partial update with pinned set by the passed in value
   */
  on(setTicketSearchResultsPinned, (state, { ids, pinned }) => ({
    ...state,
    searchResults: searchResultsAdapter.updateMany(
      ids.map((id) => ({ id, changes: { pinned } })),
      state.searchResults
    ),
  })),
  /**
   * Reset the parts of the ticket service state that represent coupon record search to their initial values
   */
  on(resetTicketSearchResults, (state) => ({
    ...state,
    searchResults: initialTicketServiceState.searchResults,
    searchError: initialTicketServiceState.searchError,
    searchStatus: initialTicketServiceState.searchStatus,
    searchStartTime: null,
  })),
  /**
   * Add EMD ticket detail shells to the store, setting the primary ID to the ticket number and the status to LOADING
   */
  on(getEMDTicketDetails, (state, { ticketNumbers }) => ({
    ...state,
    EMDs: EMDAdapter.upsertMany(
      ticketNumbers.map((ticketNumber) => ({ ticketNumber, status: Status.LOADING })),
      state.EMDs
    ),
  })),
  /**
   * Add full EMD ticket details to the store
   */
  on(getEMDTicketDetailsSuccess, (state, { EMDTicketDetails }) => ({
    ...state,
    EMDs: EMDAdapter.upsertMany(EMDTicketDetails, state.EMDs),
  })),
  /**
   * Set the status to stable and add the error to EMD Ticket details in the store
   */
  on(getEMDTicketDetailsFailure, (state, { ticketNumbers, error }) => ({
    ...state,
    EMDs: EMDAdapter.upsertMany(
      ticketNumbers.map((ticketNumber) => ({ ticketNumber, status: Status.STABLE, error })),
      state.EMDs
    ),
  })),
  /**
   * Remove all ancillary ticket details results in the store, set ticket details status to UPDATING
   */
  on(getAncillaryTicketDetails, (state) => ({
    ...state,
    ancillaryTicketDetails: ancillaryTicketDetailsAdapter.removeMany((result) => !result, state.ancillaryTicketDetails),
    ancillaryTicketDetailsError: null,
    ancillaryTicketDetailsStatus: Status.UPDATING,
  })),
  /**
   * Add ancillary ticket details results in the store
   */
  on(getAncillaryTicketDetailsSuccess, (state, { ancillaryTicketDetails }) => ({
    ...state,
    ancillaryTicketDetails: ancillaryTicketDetailsAdapter.addMany(ancillaryTicketDetails, state.ancillaryTicketDetails),
    ancillaryTicketDetailsStatus: Status.STABLE,
  })),
  /**
   * Set ancillaryTicketDetailsError
   */
  on(getAncillaryTicketDetailsFailure, (state, { ancillaryTicketDetailsError }) => ({
    ...state,
    ancillaryTicketDetailsError,
    ancillaryTicketDetailsStatus: Status.STABLE,
  })),
  /**
   * Remove all ticket details results in the store, set ticket details status to UPDATING
   */
  on(getTicketDetails, (state) => ({
    ...state,
    ticketDetails: ticketDetailsAdapter.removeMany((result) => !result, state.ticketDetails),
    ticketDetailsError: null,
    ticketDetailsStatus: Status.UPDATING,
  })),
  /**
   * Add search results in the store
   */
  on(getTicketDetailsSuccess, (state, { ticketDetails }) => ({
    ...state,
    ticketDetails: ticketDetailsAdapter.addMany(ticketDetails, state.ticketDetails),
    ticketDetailsStatus: Status.STABLE,
  })),
  /**
   * Set ticketDetailsError
   */
  on(getTicketDetailsFailure, (state, { ticketDetailsError }) => ({
    ...state,
    ticketDetailsError,
    ticketDetailsStatus: Status.STABLE,
  })),
  /**
   * Remove all ticket details results in the store
   */
  on(resetTicketDetails, (state) => ({
    ...state,
    ticketDetails: ticketDetailsAdapter.removeAll(state.ticketDetails),
    ticketDetailsError: null,
    ticketDetailsStatus: Status.STABLE,
  })),
  /**
   * Set the state to the initial value defined in the ticket-service.state file
   */
  on(initializeTicketServiceState, (state) => ({ ...state, ...initialTicketServiceState }))
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function ticketServiceReducer(state = initialTicketServiceState, action: Action) {
  return featureReducer(state, action);
}
