import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CallerContext } from '../../../dtos/response/caller-context-response/caller-context';
import { setActiveCallerId } from '../../../services/caller-context/state/caller-context-service.actions';
import { RootState } from '../../../state/state';

@Component({
  selector: 'app-caller-context-dropdown',
  templateUrl: './caller-context-dropdown.component.html',
  styleUrls: ['./caller-context-dropdown.component.scss']
})
export class CallerContextDropdownComponent {
  @Input() callers: CallerContext[];
  @Input() activeCaller: CallerContext | null | undefined;
  @Input() activeCallerIndex: number | null;
  @Input() isDisconnected: boolean;
  @Input() callerCount: number;
  @Input() callerHasInfo: (caller: CallerContext) => boolean;

  constructor(
    private store: Store<RootState>
  ) { }

  handleClick(callerId: string) {
    this.store.dispatch(setActiveCallerId(callerId));
    this.hideDropdown();
  }

  hideDropdown() {
    const callerContextDropdownElm = document.querySelector<any>('#callerContextOption');
    if (callerContextDropdownElm) {
      callerContextDropdownElm.hide();
    }
  }

  getCallerName(caller: CallerContext, isTitle = false) {
    if (caller) {
      const callerName = caller.callerFirstName + ' ' + caller.callerLastName;
      if (this.isLongCallerName(callerName)) {
        return !isTitle ? callerName.slice(0, this.getCallerNameClipSize()) + '...' : callerName;
      }
      return !isTitle ? callerName : '';
    }
    return null;
  }

  private isLongCallerName(name: string) {
    return window.innerWidth <= 768 ? name.length > 20 : name.length > 50;
  }

  private getCallerNameClipSize() {
    return window.innerWidth <= 768 ? 15 : 50;
  }
}
