import { createAction } from '@ngrx/store';
import { contactInformationFormsFeatureKey } from './contact-information-forms.state';
import { ContactInformationForm } from '../../../models/contact-information-forms/contact-information-form';
import { PhoneFormData } from '../../../models/contact-information-forms/phone-form-data';
import { EmailFormData } from '../../../models/contact-information-forms/email-form-data';

/**
 * Resets the contact information forms state
 */
export const initializeContactInformationFormsState = createAction(`[${contactInformationFormsFeatureKey}] Initialize contact information forms state`);

/**
 * Add a contact information form data
 */
export const addContactInformationFormData = createAction(
  `[${contactInformationFormsFeatureKey}] Add a contact information form data entity state`,
  (featureId: string, contactInformationForm: ContactInformationForm) => ({
    featureId,
    contactInformationForm,
  })
);

/**
 * Updates a contact information form phone data
 */
export const updateContactInformationFormPhoneData = createAction(
  `[${contactInformationFormsFeatureKey}] Add/update a contact information form phone data entity state`,
  (featureId: string, phoneData: PhoneFormData, index: number) => ({
    featureId,
    phoneData,
    index,
  })
);

/**
 * Updates a contact information form email data
 */
export const updateContactInformationFormEmailData = createAction(
  `[${contactInformationFormsFeatureKey}] Add/update a contact information form email data entity state`,
  (featureId: string, emailData: EmailFormData, index: number) => ({
    featureId,
    emailData,
    index,
  })
);
