import { createAction } from '@ngrx/store';
import { flightReshopServiceFeatureKey } from './flight-reshop-service.state';
import { ReshopResponse } from '../../../dtos/response/reshop-response/reshop-response';

export const initializeFlightReshopServiceState = createAction(`[${flightReshopServiceFeatureKey}] Initialize`);

export const loadFlightReshopResults = createAction(`[${flightReshopServiceFeatureKey}] Load flight reshop results`);

export const loadFlightReshopResultsComplete = createAction(
  `[${flightReshopServiceFeatureKey}] Load flight reshop results complete`,
  (response: ReshopResponse | null, error: string | null = null) => ({
    response,
    error,
  })
);
