import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RootState } from '../state/state';
import { getUser } from '../services/login-service/state/login-service.selector';

@Injectable({
  providedIn: 'root',
})
export class BaggageJourneyGuard implements CanActivate {
  constructor(private store: Store<RootState>) {}

  /**
   * Do not allow access to the baggage journey routes if the user is not in bag journey admin role
   */
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(getUser).pipe(
      // take the first result emitted and kill the subscription, this prevents memory leaks as this method is called multiple times
      take(1),
      map(user => user.isCBSAgent ?? false)
    );
  }
}
