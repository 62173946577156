import { createAction } from '@ngrx/store';
import { FlightShoppingRequest } from '../../../dtos/request/shopping-request/flight-shopping-request';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { FlightShoppingResponseFull } from '../../../dtos/response/shopping-response/flight-shopping-response-full';
import { flightShoppingServiceFeatureKey } from './flight-shopping-service.state';
import { FlightShoppingFrapiRequest } from '../../../dtos/request/flight-shopping-frapi-request/flight-shopping-frapi-request';
import { FlightShoppingFrapiResponse } from '../../../models/flight-shopping-frapi-response';

export const initializeFlightShoppingServiceState = createAction(`[${flightShoppingServiceFeatureKey}] Initialize`);

export const loadFlightShoppingResults = createAction(
  `[${flightShoppingServiceFeatureKey}] Load flight shopping results`,
  (request: FlightShoppingRequest) => ({
    request
  })
);

export const loadFlightShoppingResultsComplete = createAction(
  `[${flightShoppingServiceFeatureKey}] Load flight shopping results complete`,
  (success: boolean, response: FlightShoppingResponseFull | null) => ({
    success,
    response
  })
);

export const setSelectedShoppingOption = createAction(
  `[${flightShoppingServiceFeatureKey}] Set selected shopping option`,
  (selectedOption: ShoppingSegment[], selectedIndex: number) => ({
    selectedOption,
    selectedIndex
  })
);

/**
 * Load flight shopping frapi results for initial booking
 */
export const loadFlightShoppingFrapiResultsInitialBooking = createAction(
  `[${flightShoppingServiceFeatureKey}] Load flight shopping frapi results for initial booking`,
  (featureId: string, request: FlightShoppingFrapiRequest) => ({
    featureId,
    request,
  })
);

/**
 * Load flight shopping frapi results complete
 */
export const loadFlightShoppingFrapiResultsComplete = createAction(
  `[${flightShoppingServiceFeatureKey}] Load flight shopping frapi results complete`,
  (result: FlightShoppingFrapiResponse) => ({
    result
  })
);
