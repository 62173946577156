import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { involuntaryChangeReducer } from '../features/involuntary-change/state/involuntary-change.reducers';
import { involuntaryChangeFeatureKey } from '../features/involuntary-change/state/involuntary-change.state';
import { seatMapReducer } from '../features/seat-map/state/seat-map.reducer';
import { seatMapFeatureKey } from '../features/seat-map/state/seat-map.state';
import { purchaseReducer } from '../shared/purchase/state/purchase.reducer';
import { purchaseFeatureKey } from '../shared/purchase/state/purchase.state';
import { couponRecordSearchReducer } from '../state/coupon-record-search/coupon-record-search.reducers';
import { couponRecordSearchFeatureKey } from '../state/coupon-record-search/coupon-record-search.state';
import { mileagePlanProfileSearchReducer } from '../state/mileage-plan-profile-search/mileage-plan-profile-search.reducers';
import { mileagePlanProfileSearchFeatureKey } from '../state/mileage-plan-profile-search/mileage-plan-profile-search.state';
import { AuthService } from './auth-service/auth.service';
import { CallerContextServiceEffects } from './caller-context/state/caller-context-service.effects';
import { callerContextReducer } from './caller-context/state/caller-context-service.reducers';
import { callerContextFeatureKey } from './caller-context/state/caller-context-service.state';
import { CardOnFileService } from './card-on-file-service/card-on-file.service';
import { CardOnFileServiceEffects } from './card-on-file-service/state/card-on-file-service.effects';
import { cardOnFileServiceReducer } from './card-on-file-service/state/card-on-file-service.reducers';
import { cardOnFileFeatureKey } from './card-on-file-service/state/card-on-file-service.state';
import { CompensationServiceEffects } from './compensation-service/state/compensation-service.effects';
import { compensationServiceReducer } from './compensation-service/state/compensation-service.reducer';
import { compensationServiceFeatureKey } from './compensation-service/state/compensation-service.state';
import { ContactTracingService } from './contact-tracing-service/contact-tracing.service';
import { ContactTracingEffects } from './contact-tracing-service/state/contact-tracing.effects';
import { contactTracingReducer } from './contact-tracing-service/state/contact-tracing.reducers';
import { contactTracingFeatureKey } from './contact-tracing-service/state/contact-tracing.state';
import { FeeService } from './fee-service/fee.service';
import { FeeEffects } from './fee-service/state/fee.effects';
import { feeServiceReducer } from './fee-service/state/fee.reducer';
import { feeFeatureKey } from './fee-service/state/fee.state';
import { FlightAvailabilityService } from './flight-availability-service/flight-availability.service';
import { FlightAvailabilityServiceEffects } from './flight-availability-service/state/flight-availability-service.effects';
import { flightAvailabilityServiceReducer } from './flight-availability-service/state/flight-availability-service.reducer';
import { flightAvailabilityServiceFeatureKey } from './flight-availability-service/state/flight-availability-service.state';
import { FlightService } from './flight-service/flight.service';
import { FlightServiceEffects } from './flight-service/state/flight-service.effects';
import { flightServiceReducer } from './flight-service/state/flight-service.reducer';
import { flightServiceFeatureKey } from './flight-service/state/flight-service.state';
import { FlightShoppingService } from './flight-shopping-service/flight-shopping.service';
import { FlightShoppingServiceEffects } from './flight-shopping-service/state/flight-shopping-service.effects';
import { flightShoppingServiceReducer } from './flight-shopping-service/state/flight-shopping-service.reducer';
import { flightShoppingServiceFeatureKey } from './flight-shopping-service/state/flight-shopping-service.state';
import { GlobalEventService } from './global-event-service/global-event.service';
import { ItineraryService } from './itinerary-service/itinerary.service';
import { ItineraryEffects } from './itinerary-service/state/itinerary.effects';
import { itineraryReducer } from './itinerary-service/state/itinerary.reducers';
import { itineraryFeatureKey } from './itinerary-service/state/itinerary.state';
import { ZipLookupEffects } from './location-service/state/zip-lookup.effects';
import { zipLookupServiceReducer } from './location-service/state/zip-lookup.reducer';
import { zipLookupFeatureKey } from './location-service/state/zip-lookup.state';
import { ZipLookupService } from './location-service/zip-lookup.service';
import { LoginService } from './login-service/login.service';
import { LoginServiceEffects } from './login-service/state/login-service.effects';
import { loginServiceReducer } from './login-service/state/login-service.reducers';
import { loginServiceFeatureKey } from './login-service/state/login-service.state';
import { MileagePlanEnrollmentServiceEffects } from './mileage-plan-enrollment-service/state/mileage-plan-enrollment-service.effects';
import { mileagePlanEnrollmentServiceReducer } from './mileage-plan-enrollment-service/state/mileage-plan-enrollment-service.reducers';
import { mileagePlanEnrollmentServiceFeatureKey } from './mileage-plan-enrollment-service/state/mileage-plan-enrollment-service.state';
import { MileagePlanProfileServiceEffects } from './mileage-plan-profile-service/state/mileage-plan-profile-service.effects';
import { mileagePlanProfileServiceReducer } from './mileage-plan-profile-service/state/mileage-plan-profile-service.reducers';
import { mileagePlanProfileServiceFeatureKey } from './mileage-plan-profile-service/state/mileage-plan-profile-service.state';
import { mileagePlanCompanionServiceReducer } from './mileage-plan-companion-service/state/mileage-plan-companion-service.reducers';
import { mileagePlanCompanionServiceFeatureKey } from './mileage-plan-companion-service/state/mileage-plan-companion-service.state';
import { PurchaseService } from './purchase-service/purchase.service';
import { PurchaseServiceEffects } from './purchase-service/state/purchase-service.effects';
import { purchaseServiceReducer } from './purchase-service/state/purchase-service.reducer';
import { purchaseServiceFeatureKey } from './purchase-service/state/purchase-service.state';
import { ReservationMileagePlanServiceEffects } from './reservation-mileage-plan-service/state/reservation-mileage-plan-service.effects';
import { ReservationServiceEffects } from './reservation-service/state/reservation-service.effects';
import { reservationServiceReducer } from './reservation-service/state/reservation-service.reducer';
import { reservationServiceFeatureKey } from './reservation-service/state/reservation-service.state';
import { ReservationTransactionServiceEffects } from './reservation-transaction-service/state/reservation-transaction-service.effects';
import { reservationTransactionServiceReducer } from './reservation-transaction-service/state/reservation-transaction-service.reducer';
import { reservationTransactionServiceFeatureKey } from './reservation-transaction-service/state/reservation-transaction-service.state';
import { SdcEligibilityService } from './sdc-eligibility-service/sdc-eligibility-service';
import { SdcEligibilityServiceEffects } from './sdc-eligibility-service/state/sdc-eligibility-service.effects';
import { sdcEligibilityServiceReducer } from './sdc-eligibility-service/state/sdc-eligibility-service.reducers';
import { sdcEligibilityServiceFeatureKey } from './sdc-eligibility-service/state/sdc-eligibility-service.state';
import { SeatsService } from './seat-service/seats.service';
import { SeatsServiceEffects } from './seat-service/state/seats-service.effects';
import { seatsServiceReducer } from './seat-service/state/seats-service.reducers';
import { seatsServiceFeatureKey } from './seat-service/state/seats-service.state';
import { SecureFlightInfoService } from './secure-flight-info-service/secure-flight-info.service';
import { SecureFlightInfoEffects } from './secure-flight-info-service/state/secure-flight-info.effects';
import { secureFlightInfoReducer } from './secure-flight-info-service/state/secure-flight-info.reducer';
import { secureFlightInfoFeatureKey } from './secure-flight-info-service/state/secure-flight-info.state';
import { SplitioService } from './splitio-service/splitio.service';
import { SsrService } from './ssr-service/ssr.service';
import { SsrServiceEffects } from './ssr-service/state/ssr-service.effects';
import { ssrServiceReducer } from './ssr-service/state/ssr-service.reducer';
import { ssrServiceFeatureKey } from './ssr-service/state/ssr-service.state';
import { TicketServiceEffects } from './ticket-service/state/ticket-service.effects';
import { ticketServiceReducer } from './ticket-service/state/ticket-service.reducer';
import { ticketServiceFeatureKey } from './ticket-service/state/ticket-service.state';
import { TicketService } from './ticket-service/ticket.service';
import { WaiverServiceEffects } from './waivers-service/state/waiver-service.effects';
import { WaiverService } from './waivers-service/waiver.service';
import { reservationSearchHistoryFeatureKey } from './reservation-search-history-service/state/reservation-search-history.state';
import { reservationSearchHistoryReducer } from './reservation-search-history-service/state/reservation-search-history.reducer';
import { seatMapDisplayServiceFeatureKey } from './seat-map-display-service/state/seat-map-display-service.state';
import { seatMapDisplayServiceReducer } from './seat-map-display-service/state/seat-map-display-service.reducers';
import { SeatMapDisplayService } from './seat-map-display-service/seat-map-display.service';
import { messageReducer } from './message-service/state/message.reducer';
import { messageStateFeatureKey } from './message-service/state/message.state';
import { MessageEffects } from './message-service/state/message.effects';
import { savePassengerFeatureKey } from './save-passenger-service/state/save-passenger.state';
import { savePassengerReducer } from './save-passenger-service/state/save-passenger.reducer';
import { SavePassengerEffects } from './save-passenger-service/state/save-passenger.effects';
import { SavePassengerService } from './save-passenger-service/save-passenger.service';
import { phoneFieldFeatureKey } from './phone-field-service/state/phone-field.state';
import { phoneFieldReducer } from './phone-field-service/state/phone-field.reducer';
import { PhoneFieldEffects } from './phone-field-service/state/phone-field.effects';
import { PhoneFieldService } from './phone-field-service/phone-field.service';
import { passengerFormsFeatureKey } from '../shared/passenger-forms/state/passenger-forms.state';
import { passengerFormsReducer } from '../shared/passenger-forms/state/passenger-forms.reducers';
import { OsiServiceEffects } from './osi-service/state/osi-service.effects';
import { osiServiceFeatureKey } from './osi-service/state/osi-service.state';
import { OsiService } from './osi-service/osi-service';
import { osiServiceReducer } from './osi-service/state/osi-service.reducer';
import { drawerDisplaysFeatureKey } from '../shared/drawer-displays/state/drawer-displays.state';
import { drawerDisplaysReducer } from '../shared/drawer-displays/state/drawer-displays.reducers';
import { PreOrderMealServiceEffects } from './preorder-meal-service/state/preorder-meal-service.effects';
import { PreOrderMealService } from './preorder-meal-service/preorder-meal.service';
import { preOrderMealServiceReducer } from './preorder-meal-service/state/preorder-meal-service.reducer';
import { preorderMealServiceFeatureKey } from './preorder-meal-service/state/preorder-meal-service.state';
import { mileagePlanAutoFillServiceReducer } from './mileage-plan-auto-fill-service/state/mileage-plan-auto-fill-service.reducers';
import { mileagePlanAutoFillServiceFeatureKey } from './mileage-plan-auto-fill-service/state/mileage-plan-auto-fill-service.state';
import { MileagePlanAutoFillServiceEffects } from './mileage-plan-auto-fill-service/state/mileage-plan-auto-fill-service.effects';
import { newsFeedServiceReducer } from './news-feed-service/state/news-feed-service.reducer';
import { newsFeedServiceFeatureKey } from './news-feed-service/state/news-feed-service.state';
import { NewsFeedServiceEffects } from './news-feed-service/state/news-feed-service.effects';
import { NewsFeedService } from './news-feed-service/news-feed-service';
import { MileagePlanProfileService } from './mileage-plan-profile-service/mileage-plan-profile.service';
import { TravelAdvisoriesService } from './travel-advisories-service/travel-advisories.service';
import { travelAdvisoriesServiceReducer } from './travel-advisories-service/state/travel-advisories-service.reducers';
import { travelAdvisoriesServiceFeatureKey } from './travel-advisories-service/state/travel-advisories-service.state';
import { TravelAdvisoriesServiceEffects } from './travel-advisories-service/state/travel-advisories-service.effects';
import { advisoriesServiceFeatureKey } from './advisories-service/state/advisories-service.state';
import { advisoryServiceReducer } from './advisories-service/state/advisories-service.reducers';
import { AdvisoriesService } from './advisories-service/advisories-services';
import { AdvisoriesServiceEffects } from './advisories-service/state/advisories-service.effects';
import { SeatMapEffects } from '../features/seat-map-v2/state/seat-map-v2.effects';
import { seatMapV2FeatureKey } from '../features/seat-map-v2/state/seat-map-v2.state';
import { seatMapV2Reducer } from '../features/seat-map-v2/state/seat-map-v2.reducers';
import { voluntaryChangeReducer } from '../features/voluntary-change/state/voluntary-change.reducers';
import { voluntaryChangeFeatureKey } from '../features/voluntary-change/state/voluntary-change.state';
import { initialBookingReducer } from '../features/initial-booking/state/initial-booking.reducer';
import { initialBookingFeatureKey } from '../features/initial-booking/state/initial-booking.state';
import { InitialBookingEffects } from '../features/initial-booking/state/initial-booking.effects';
import { flightReshopServiceFeatureKey } from './flight-reshop-service/state/flight-reshop-service.state';
import { flightReshopServiceReducer } from './flight-reshop-service/state/flight-reshop-service.reducer';
import { FlightReshopServiceEffects } from './flight-reshop-service/state/flight-reshop-service.effects';
import { FlightReshopService } from './flight-reshop-service/flight-reshop.service';
import { baggageServiceFeatureKey } from './baggage-service/state/baggage-service.state';
import { baggageServiceReducer } from './baggage-service/state/baggage-service.reducer';
import { BaggageServiceEffects } from './baggage-service/state/baggage-service.effects';
import { BaggageService } from './baggage-service/baggage-service';
import { flightSearchFormsFeatureKey } from '../shared/flight-search-forms/state/flight-search-forms.state';
import { flightSearchFormsReducer } from '../shared/flight-search-forms/state/flight-search-forms.reducer';
import { contactInformationFormsFeatureKey } from '../shared/contact-information-forms/state/contact-information-forms.state';
import { contactInformationFormsReducer } from '../shared/contact-information-forms/state/contact-information-forms.reducer';

@NgModule({
  imports: [
    CommonModule,
    // -- Keep below section alphabetized for ease of search in Redux chrome extension --
    StoreModule.forFeature(advisoriesServiceFeatureKey, advisoryServiceReducer),
    StoreModule.forFeature(baggageServiceFeatureKey, baggageServiceReducer),
    StoreModule.forFeature(callerContextFeatureKey, callerContextReducer),
    StoreModule.forFeature(cardOnFileFeatureKey, cardOnFileServiceReducer),
    StoreModule.forFeature(compensationServiceFeatureKey, compensationServiceReducer),
    StoreModule.forFeature(contactInformationFormsFeatureKey, contactInformationFormsReducer),
    StoreModule.forFeature(contactTracingFeatureKey, contactTracingReducer),
    StoreModule.forFeature(couponRecordSearchFeatureKey, couponRecordSearchReducer),
    StoreModule.forFeature(drawerDisplaysFeatureKey, drawerDisplaysReducer),
    StoreModule.forFeature(feeFeatureKey, feeServiceReducer),
    StoreModule.forFeature(flightAvailabilityServiceFeatureKey, flightAvailabilityServiceReducer),
    StoreModule.forFeature(flightReshopServiceFeatureKey, flightReshopServiceReducer),
    StoreModule.forFeature(flightSearchFormsFeatureKey, flightSearchFormsReducer),
    StoreModule.forFeature(flightServiceFeatureKey, flightServiceReducer),
    StoreModule.forFeature(flightShoppingServiceFeatureKey, flightShoppingServiceReducer),
    StoreModule.forFeature(involuntaryChangeFeatureKey, involuntaryChangeReducer),
    StoreModule.forFeature(itineraryFeatureKey, itineraryReducer),
    StoreModule.forFeature(initialBookingFeatureKey, initialBookingReducer),
    StoreModule.forFeature(loginServiceFeatureKey, loginServiceReducer),
    StoreModule.forFeature(mileagePlanAutoFillServiceFeatureKey, mileagePlanAutoFillServiceReducer),
    StoreModule.forFeature(mileagePlanEnrollmentServiceFeatureKey, mileagePlanEnrollmentServiceReducer),
    StoreModule.forFeature(mileagePlanProfileSearchFeatureKey, mileagePlanProfileSearchReducer),
    StoreModule.forFeature(mileagePlanProfileServiceFeatureKey, mileagePlanProfileServiceReducer),
    StoreModule.forFeature(mileagePlanCompanionServiceFeatureKey, mileagePlanCompanionServiceReducer),
    StoreModule.forFeature(messageStateFeatureKey, messageReducer),
    StoreModule.forFeature(newsFeedServiceFeatureKey, newsFeedServiceReducer),
    StoreModule.forFeature(osiServiceFeatureKey, osiServiceReducer),
    StoreModule.forFeature(passengerFormsFeatureKey, passengerFormsReducer),
    StoreModule.forFeature(phoneFieldFeatureKey, phoneFieldReducer),
    StoreModule.forFeature(preorderMealServiceFeatureKey, preOrderMealServiceReducer),
    StoreModule.forFeature(purchaseFeatureKey, purchaseReducer),
    StoreModule.forFeature(purchaseServiceFeatureKey, purchaseServiceReducer),
    StoreModule.forFeature(reservationServiceFeatureKey, reservationServiceReducer),
    StoreModule.forFeature(reservationSearchHistoryFeatureKey, reservationSearchHistoryReducer),
    StoreModule.forFeature(reservationTransactionServiceFeatureKey, reservationTransactionServiceReducer),
    StoreModule.forFeature(savePassengerFeatureKey, savePassengerReducer),
    StoreModule.forFeature(sdcEligibilityServiceFeatureKey, sdcEligibilityServiceReducer),
    StoreModule.forFeature(seatMapDisplayServiceFeatureKey, seatMapDisplayServiceReducer),
    StoreModule.forFeature(seatMapFeatureKey, seatMapReducer),
    StoreModule.forFeature(seatMapV2FeatureKey, seatMapV2Reducer),
    StoreModule.forFeature(seatsServiceFeatureKey, seatsServiceReducer),
    StoreModule.forFeature(secureFlightInfoFeatureKey, secureFlightInfoReducer),
    StoreModule.forFeature(ssrServiceFeatureKey, ssrServiceReducer),
    StoreModule.forFeature(ticketServiceFeatureKey, ticketServiceReducer),
    StoreModule.forFeature(travelAdvisoriesServiceFeatureKey, travelAdvisoriesServiceReducer),
    StoreModule.forFeature(voluntaryChangeFeatureKey, voluntaryChangeReducer),
    StoreModule.forFeature(zipLookupFeatureKey, zipLookupServiceReducer),
    // -- Keep above section alphabetized for ease of search in Redux chrome extension --
    EffectsModule.forFeature([
      TicketServiceEffects,
      FlightServiceEffects,
      LoginServiceEffects,
      ReservationServiceEffects,
      ReservationMileagePlanServiceEffects,
      ReservationTransactionServiceEffects,
      CallerContextServiceEffects,
      CompensationServiceEffects,
      FlightAvailabilityServiceEffects,
      ItineraryEffects,
      InitialBookingEffects,
      SsrServiceEffects,
      CardOnFileServiceEffects,
      SecureFlightInfoEffects,
      ContactTracingEffects,
      MileagePlanAutoFillServiceEffects,
      MileagePlanProfileServiceEffects,
      MileagePlanEnrollmentServiceEffects,
      MessageEffects,
      SdcEligibilityServiceEffects,
      FlightShoppingServiceEffects,
      FeeEffects,
      PurchaseServiceEffects,
      SeatsServiceEffects,
      WaiverServiceEffects,
      ZipLookupEffects,
      SavePassengerEffects,
      PhoneFieldEffects,
      PreOrderMealServiceEffects,
      OsiServiceEffects,
      NewsFeedServiceEffects,
      TravelAdvisoriesServiceEffects,
      AdvisoriesServiceEffects,
      SeatMapEffects,
      FlightReshopServiceEffects,
      BaggageServiceEffects,
    ]),
  ],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        provideHttpClient(withInterceptorsFromDi()),
        AuthService,
        GlobalEventService,
        LoginService,
        PurchaseService,
        SeatsService,
        FlightService,
        TicketService,
        ItineraryService,
        FlightAvailabilityService,
        SsrService,
        CardOnFileService,
        SecureFlightInfoService,
        MileagePlanProfileService,
        SdcEligibilityService,
        FlightShoppingService,
        FeeService,
        PurchaseService,
        ContactTracingService,
        ZipLookupService,
        WaiverService,
        SplitioService,
        SeatMapDisplayService,
        SavePassengerService,
        PhoneFieldService,
        OsiService,
        PreOrderMealService,
        NewsFeedService,
        TravelAdvisoriesService,
        AdvisoriesService,
        FlightReshopService,
        BaggageService,
      ],
    };
  }
}
