/**
 * Determine how many days are in the diff between departure and arrival, do not account for time
 */
export function calculateDateDiff(departureTime: string, arrivalTime: string): number {
  // Set departure and arrival times from the segment input, and remove time
  const departureDate = new Date(departureTime?.split('T')[0]);
  const arrivalDate = new Date(arrivalTime?.split('T')[0]);

  // Check if the departure and arrival dates differ
  if (
    departureDate.getDay() !== arrivalDate.getDay() ||
    departureDate.getMonth() !== arrivalDate.getMonth() ||
    departureDate.getFullYear() !== arrivalDate.getFullYear()
  ) {
    return Math.floor((arrivalDate.getTime() - departureDate.getTime()) / (1000 * 60 * 60 * 24));
  }
  // No difference between the dates
  return 0;
}

/**
 * Calculate the date with offset and convert to string, without knowing the station timezone
 * @param stationDateTime the datetime of the station (not UTC)
 * @param utcDateTime the UTC datetime of the station
 * @returns The date formatted for ISO 8601 with offset, for AURO components
 */
export function calculateDateWithOffset(stationDateTime: string, utcDateTime: string): string {
  let offset = 0;
  let isBehind = false;

  if (stationDateTime && utcDateTime) {
    // Split out the dates - yyyy-mm-dd
    const stationDate = stationDateTime.split('T')[0];
    const utcDate = utcDateTime.split('T')[0];

    // Split out the hours - hh
    const stationTime = stationDateTime.split('T')[1].split(':')[0];
    const utcTime = utcDateTime.split('T')[1].split(':')[0];

    // The station date is behind the UTC date
    if (stationDate < utcDate) {
      const diff: number = parseInt(utcTime, 10) + (24 - parseInt(stationTime, 10));
      isBehind = true;
      offset = diff;
    }
    // The station date is ahead of the UTC date
    else if (utcDate < stationDate) {
      const diff: number = 24 - parseInt(utcTime, 10) + parseInt(stationTime, 10);
      offset = diff;
    }
    // The station date is the same as the UTC date
    else {
      // The station time is behind the UTC time
      if (parseInt(utcTime, 10) > parseInt(stationTime, 10)) {
        offset = parseInt(utcTime, 10) - parseInt(stationTime, 10);
        isBehind = true;
      }
      // The station time is ahead of the UTC time
      else {
        offset = parseInt(stationTime, 10) - parseInt(utcTime, 10);
      }
    }

    // Format the date with offset
    const formattedOffset = offset < 10 ? `0${offset}` : `${offset}`;
    const offsetString = isBehind ? `-${formattedOffset}` : `+${formattedOffset}`;
    const dateWithOffset = `${stationDateTime}${offsetString}:00`;
    return dateWithOffset;
  }
  // If the station date or UTC date is not provided, return the station date with no offset
  return `${stationDateTime}+00:00`;
}

/**
 * Check a date string to determine if it is the minimum value date
 * @param dateString the datetime of the station (e.g. 2/5/2024 6:00:00 AM)
 * @returns Boolean as to whether it is a minimum value date (e.g. 1/1/0001)
 */
export function isDateStringMinimumDateValue(dateString: string): boolean {
  const datePart = dateString.split(' ')[0];
  const [month, day, year] = datePart.split('/');

  if (year === '0001' && month === '01' && day === '01') {
    return true;
  } else {
    return false;
  }
}

/**
 * Converts a date string to ISO 8601 with offset
 * @param dateString the datetime of the station (not UTC) (e.g. 2/5/2024 6:00:00 AM)
 * @param utcOffset the UTC offset of the dateString (e.g. -5.00)
 * @returns The date formatted for ISO 8601 with offset, for AURO components (e.g. 2024-02-05T06:00:00-05:00)
 */
export function convertToISOStringWithOffset(dateString: string, utcOffset: string): string {
  const [datePart, timePart, amPm] = dateString.split(' ');
  const [month, day, year] = datePart.split('/');
  // eslint-disable-next-line prefer-const
  let [hours, minutes, seconds] = timePart.split(':');

  if (amPm === 'PM' && hours !== '12') {
    hours = String(Number(hours) + 12);
  } else if (amPm === 'AM' && hours === '12') {
    hours = '00';
  }

  const isoString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hours.padStart(2, '0')}:${minutes.padStart(
    2,
    '0'
  )}:${seconds.padStart(2, '0')}`;
  const isoStringWithOffset = isoString.concat(convertTimeZoneOffset(utcOffset));
  return isoStringWithOffset;
}

/**
 * Converts a UTC offset from '-5.00' format to '-05:00' format
 * @param offset the UTC offset of the dateString (e.g. -5.00)
 * @returns The offset formatted for AURO components (e.g. -05:00)
 */
export function convertTimeZoneOffset(offset: string): string {
  if (!offset) {
    return '+00:00';
  }
  const sign = offset.startsWith('-') ? '-' : '+';
  const absoluteOffset = Math.abs(parseFloat(offset));
  const hours = Math.floor(absoluteOffset);
  const minutes = Math.floor((absoluteOffset - hours) * 60);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${sign}${formattedHours}:${formattedMinutes}`;
}

/**
 * Format the duration of a flight
 * Takes hours and minutes and returns a string in the format of 'x hours y minutes'
 */
export function formatDuration(hours: number, minutes: number, shorthand: boolean = false): string {
  let formattedDuration = '';

  // If there are hours, add them to the formatted duration
  if (hours && hours > 0) {
    const hoursDescriptor = hours === 1 ? (shorthand ? 'hr' : 'hour') : shorthand ? 'hrs' : 'hours';
    formattedDuration = `${hours} ${hoursDescriptor}`;
  }
  // If there are minutes, add them to the formatted duration
  if (minutes && minutes > 0) {
    const minutesDescriptor = minutes === 1 ? (shorthand ? 'min' : 'minute') : shorthand ? 'mins' : 'minutes';
    const minutesText = `${minutes} ${minutesDescriptor}`;
    formattedDuration = formattedDuration.length > 0 ? `${formattedDuration} ${minutesText}` : minutesText;
  }
  // No hours or minutes or they are both 0
  else if ((!hours || hours <= 0) && (!minutes || minutes <= 0)) {
    formattedDuration = shorthand ? '0 hrs 0 mins' : '0 hours 0 minutes';
  }

  return formattedDuration;
}

/**
 * Given two date strings, it will return a number representing the difference in minutes
 */
export function calculateMinutesDifference(date1: string, date2: string): number {
  const date1InMs = new Date(date1).getTime();
  const date2InMs = new Date(date2).getTime();
  const diffInMs = date2InMs - date1InMs;
  return Math.round(diffInMs / 60000);
}

/**
 * Given a date string, it will return a string in the format of 'MM/dd/yyyy'
 */
export function convertDateFormats(date: string | undefined): string | undefined {
  if (!!date) {
    // Check if date is in the format yyyy-mm-dd
    if (date.includes('-')) {
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }
  }
  return date;
}

/**
 * Given a date, it will return a string in the format of 'MM/dd/yy'
 */
export function convertDateToTwoDigitYearDateFormat(date: Date): string {
  return (date?.getMonth() + 1).toString().padStart(2, '0')
      + '/' + date?.getDate().toString().padStart(2, '0')
      + '/' + date?.getFullYear().toString().substring(2, 4);
}

/**
 * Determines if a date is within 330 days from today
 * @param date the date to check
 * @returns boolean, true if the date is within 330 days from today
 */
export function isDateWithin330Days(date: string): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayPlus330 = new Date();
  todayPlus330.setDate(todayPlus330.getDate() + 330);
  const inputDate = new Date(date);
  const isValid = inputDate >= today && inputDate <= todayPlus330;
  return isValid;
}

/**
 * Removes the timezone offset from a date string without converting to UTC.
 * Example: Turn "2024-10-21T00:42:00-08:00" into "2024-10-21T00:42:00".
 * @param dateString the date string with timezone offset
 * @returns string, the date string without timezone offset
 */
export function removeTimezoneOffset(dateString: string): string {
  // Check if the input dateString is empty or null
  if (!dateString) {
    return dateString;
  }

  // Split the dateString into date and time parts
  const [datePart, timePartWithOffset] = dateString.split('T');

  // If there is a time part with an offset
  if (timePartWithOffset) {
    // Split the time part to remove the offset (handles both + and - offsets)
    const timePart = timePartWithOffset.split(/[+-]/)[0];
    // Return the date part combined with the time part without the offset
    return `${datePart}T${timePart}`;
  }

  // If there is no time part with an offset, return the original dateString
  return dateString;
}
