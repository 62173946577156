import { createAction } from '@ngrx/store';
import { AssignSeatsRequest } from '../../../dtos/request/assign-seats/assign-seats-request';
import { AssignSeatsStatus } from '../../../dtos/response/seats-save-status';
import { Seat } from '../../../models/seat-map/seat';
import { Status } from '../../../models/status';
import { seatsServiceFeatureKey } from './seats-service.state';
import { SeatMapLookupRequest } from '../../../dtos/request/seat-map-lookup-request/seat-map-lookup-request';
import { SeatMapLookupResponse } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-response';

export const initializeSeatsServiceState = createAction(`[${seatsServiceFeatureKey}] Initialize`);

/**
 * Get a seatmap for a set of flight data
 */
export const reservationSeatMapLookup = createAction(
  `[${seatsServiceFeatureKey}] Reservation seat map lookup`,
  (seatMapLookupRequest: SeatMapLookupRequest) => ({
    seatMapLookupRequest,
  })
);

/**
 * Dispatched when a reservation seatmap lookup is complete, regardless of failure or success
 */
export const reservationSeatMapLookupComplete = createAction(
  `[${seatsServiceFeatureKey}] Reservation seat map lookup complete`,
  (response: SeatMapLookupResponse) => ({
    response,
  })
);

/**
 * Set the request for a static seatmap (meant for displaying, not assigning seats) for a set of flight data
 */
export const setStaticSeatMapRequest = createAction(
  `[${seatsServiceFeatureKey}] Static seat map lookup`,
  (seatMapLookupRequest: SeatMapLookupRequest) => ({
    seatMapLookupRequest,
  })
);

/**
 * Set the flag to include Ssrs for a static seatmap request
 */
export const setStaticSeatMapIncludeSsrs = createAction(
  `[${seatsServiceFeatureKey}] Static seat map lookup include Ssrs flag`,
  (staticSeatMapIncludeSsrs: boolean) => ({
    staticSeatMapIncludeSsrs,
  })
);

/**
 * Assign seats for a segment on a reservation
 */
export const assignSeats = createAction(`[${seatsServiceFeatureKey}] Assign seats`, (request: AssignSeatsRequest) => ({
  request,
}));

/**
 * Assign seats for a segment request is complete, regardless of failure or success
 */
export const assignSeatsComplete = createAction(`[${seatsServiceFeatureKey}] Assign seats complete`, (response: AssignSeatsStatus) => ({
  response,
}));

export const updateSingleSeatMapEntity = createAction(
  `[${seatsServiceFeatureKey}] Update a Seat Map entity`,
  (segmentIndex: number, newSeat: Seat) => ({
    segmentIndex,
    newSeat,
  })
);

export const setSeatMapLookupCrudStatus = createAction(
  `[${seatsServiceFeatureKey}] Update Seat Map Lookup CRUD status`,
  (status: Status) => ({
    status,
  })
);

export const saveSeatAssignments = createAction(`[${seatsServiceFeatureKey}] Save seat assignments`);
