import { createAction } from '@ngrx/store';
import { phoneFieldFeatureKey } from './phone-field.state';
import { PhoneFieldResponse } from '../../../dtos/response/phone-field-response/phone-field-response';
import { BulkPhoneFieldRequest } from '../../../dtos/request/phone-field-request/bulk-phone-field-request';

/**
 * Submits a phone field request
 */
export const savePhoneFields = createAction(`[${phoneFieldFeatureKey}] Save phone field`, (request: BulkPhoneFieldRequest) => ({
  request,
}));

/**
 * The phone field request has been submitted and completed (may contain failures)
 */
export const savePhoneFieldComplete = createAction(`[${phoneFieldFeatureKey}] Save phone field complete`, (response: PhoneFieldResponse) => ({
  response,
}));

/**
 * Initialize the store
 */
export const initializePhoneFieldState = createAction(`[${phoneFieldFeatureKey}] Initialize`);
