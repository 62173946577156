import { AddTcpRequest } from '../../../dtos/request/osi-request/add-tcp-request';
import { osiServiceFeatureKey } from './osi-service.state';
import { createAction } from '@ngrx/store';
import { OsiStatus } from '../../../dtos/response/osi-response/osi-status';
import { TcpDetail } from '../../../dtos/response/osi-response/tcp-detail';
import { AddOsiRequest } from '../../../dtos/request/osi-request/add-osi-request';
import { AddOsiResponse } from '../../../dtos/response/osi-response/add-osi-response';
import { DeleteOsiRequest } from '../../../dtos/request/osi-request/delete-osi-request';
import { DeleteOsiResponse } from '../../../dtos/response/osi-response/delete-osi-response';
import { AddTicketNumberOsiRequest } from '../../../dtos/request/osi-request/add-ticketnumber-osi-request';
import { AddOptOutOsiRequest } from '../../../dtos/request/osi-request/add-opt-out-osi-request';

export const initializeOsiServiceState = createAction(`[${osiServiceFeatureKey}] Initialize`);

/**
 * Add a generic OSI to a reservation based on form inputs
 */
export const addOsi = createAction(`[${osiServiceFeatureKey}] Add OSI`, (addOsiRequest: AddOsiRequest) => ({
  addOsiRequest,
}));

/**
 * Add a ticket number OSI to a reservation based on form inputs
 */
export const addTicketNumberOsi = createAction(
  `[${osiServiceFeatureKey}] Add Ticket Number OSI`,
  (addTicketNumberOsiRequest: AddTicketNumberOsiRequest) => ({
    addTicketNumberOsiRequest,
  })
);

/**
 * Add an opt-out OSI to a reservation
 */
export const addOptOutOsi = createAction(`[${osiServiceFeatureKey}] Add Opt Out OSI`, (request: AddOptOutOsiRequest) => ({
  request,
}));

/**
 * Complete action for adding a generic OSI to a reservation
 */
export const addOsiComplete = createAction(`[${osiServiceFeatureKey}] Add OSI Complete`, (addOsiResponse: AddOsiResponse) => ({
  addOsiResponse,
}));

/**
 * Deletes a list of OSIs from a reservation based on ids
 */
export const deleteOsi = createAction(`[${osiServiceFeatureKey}] Delete OSI`, (deleteOsiRequest: DeleteOsiRequest) => ({
  deleteOsiRequest,
}));

/**
 * Complete action for deleting a list of OSIs from a reservation based on ids
 */
export const deleteOsiComplete = createAction(`[${osiServiceFeatureKey}] Delete OSI Complete`, (deleteOsiResponse: DeleteOsiResponse) => ({
  deleteOsiResponse,
}));

/**
 * Add TCPs to a reservation based on form inputs
 */
export const addTcpOsi = createAction(
  `[${osiServiceFeatureKey}] Add Tcp Osi to reservation`,
  (confirmationCode: string, addTcpRequest: AddTcpRequest) => ({
    confirmationCode,
    addTcpRequest,
  })
);

/**
 * All TCPs successfully added to reservation
 */
export const addTcpOsiFullSuccess = createAction(
  `[${osiServiceFeatureKey}] All OSIs successfully added to reservation`,
  (addOsiSuccess: TcpDetail[] | undefined, addOsiStatus: OsiStatus) => ({
    addOsiSuccess,
    addOsiStatus,
  })
);

/**
 * Some TCPs successfully added to reservation
 */
export const addTcpOsiPartialSuccess = createAction(
  `[${osiServiceFeatureKey}] Some OSIs successfully added to reservation`,
  (addOsiFailure: TcpDetail[] | undefined, addOsiSuccess: TcpDetail[] | undefined, addOsiStatus: OsiStatus) => ({
    addOsiFailure,
    addOsiSuccess,
    addOsiStatus,
  })
);

/**
 * Failed to add any osis to reservation
 */
export const addTcpOsiFullFailure = createAction(
  `[${osiServiceFeatureKey}] Failed to add any OSIs to reservation`,
  (addOsiFailure: TcpDetail[] | undefined, addOsiStatus: OsiStatus) => ({
    addOsiStatus,
    addOsiFailure,
  })
);
