import { createAction } from '@ngrx/store';
import { drawerDisplaysFeatureKey } from './drawer-displays.state';
import { Segment } from '../../../dtos/response/reservation-response/segment';

export const initializeDrawerDisplaysState = createAction(`[${drawerDisplaysFeatureKey}] Initialize`);

/**
 * Set the active segment information for the flight info drawer
 * Set the whole segment since this drawer might not always be used for reservation segments
 */
export const setFlightInfoDrawerSegments = createAction(
  `[${drawerDisplaysFeatureKey}] Set segment info for the flight info drawer`,
  (flightInfoDrawerSegments: Segment[]) => ({
    flightInfoDrawerSegments,
  })
);
