import { Action, createReducer, on } from '@ngrx/store';
import { initialMileagePlanAutoFillServiceState, mileagePlanAutoFillAdapter } from './mileage-plan-auto-fill-service.state';
import { initializeMileagePlanAutoFillServiceState, mileagePlanAutoFillProfileSearch, mileagePlanAutoFillProfileSearchComplete } from './mileage-plan-auto-fill-service.actions';
import { Status } from '../../../models/status';
import { MileagePlanProfileLookupStatus } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-status';

const featureReducer = createReducer(
  initialMileagePlanAutoFillServiceState,

  /**
   * Set the state to the initial value defined in the mileage-plan-auto-fill-service.state file
   */
  on(initializeMileagePlanAutoFillServiceState, (state) => ({ ...state, ...initialMileagePlanAutoFillServiceState })),

  on(mileagePlanAutoFillProfileSearch, (state, { mileagePlanNumber, passengerHashId, autoFillOnLookup }) => {
    return {
      ...state,
      mileagePlanAutoFillResponse: mileagePlanAutoFillAdapter.setOne({
        passengerHashId,
        mileagePlanNumber,
        accountLookupResponse: null,
        memberInfoLookupResponse: null,
        mileagePlanProfileLookupCRUDStatus: Status.LOADING,
        mileagePlanProfileLookupStatus: null,
        mileagePlanProfileLookupError: null,
        autoFillOnLookup
      }, state.mileagePlanAutoFillResponse)
    };
  }),
  /**
   * Completes the mileagePlanLookup request for MP auto fill to the API
   */
  on(mileagePlanAutoFillProfileSearchComplete, (state, { response, passengerHashId, autoFillOnLookup }) => {
    return {
      ...state,
      mileagePlanAutoFillResponse: mileagePlanAutoFillAdapter.setOne({
        passengerHashId,
        mileagePlanNumber: response?.response?.mileagePlanNumber ?? null,
        accountLookupResponse: response?.response?.accountLookupResponse ?? null,
        memberInfoLookupResponse: response?.response?.memberInfoLookupResponse ?? null,
        mileagePlanProfileLookupCRUDStatus: Status.STABLE,
        mileagePlanProfileLookupStatus: response?.status ?? MileagePlanProfileLookupStatus.UNCAUGHT,
        mileagePlanProfileLookupError: response?.error ?? null,
        autoFillOnLookup
      }, state.mileagePlanAutoFillResponse)
    };
  })
);

export function mileagePlanAutoFillServiceReducer(state = initialMileagePlanAutoFillServiceState, action: Action) {
  return featureReducer(state, action);
}
