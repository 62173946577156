import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageState, messageStateFeatureKey } from './message.state';
import { RichMessage } from '../../../models/message/rich-message';
import { AdvisoryLink } from '../../../models/advisory-link';
import { TextFormat } from '../../../models/message/text-format';
import { Format } from '../../../models/message/format';
import { MessageStatus } from '../../../models/message/message-status';
import { getCurrentSegmentIndex } from '../../../features/seat-map/state/seat-map.selectors';
import { MessageType } from '../../../models/message/message-type';
import { NameNumberUtil } from '../../../utils/name-number-util';
import { MessageKey } from '../../../models/message/message-key';

const getMessageState = createFeatureSelector<MessageState>(messageStateFeatureKey);

export const getMessageStateStatus = createSelector(getMessageState, (state): MessageStatus | null => state.status);

export const getMessageStateError = createSelector(getMessageState, (state): MessageStatus | null => state.error);

/**
 * Gets the list of messages set for the stacked long message bar component
 */
export const getStackedLongMessages = createSelector(getMessageState, (state): RichMessage[] => state?.stackedLongMessages ?? []);

export const getGlobalMessages = createSelector(getMessageState, (state): RichMessage[] => state?.globalMessages ?? []);

export const getShortMessages = createSelector(getMessageState, (state): RichMessage[] => state?.shortMessages ?? []);

export const getGlobalJetStreamMessages = createSelector(getMessageState, (state): RichMessage[] => state?.globalJetStreamMessages ?? []);

export const getJetStreamMessages = createSelector(getMessageState, (state): RichMessage[] => state?.jetStreamMessages ?? []);

/**
 * Gets the list of messages set for the jetstream short message bar component
 * Note: This selector will filter out duplicate messages (based on emphasis/main text)
 */
export const getJetStreamShortMessages = createSelector(getMessageState, (state): RichMessage[] => state?.jetStreamShortMessages ?? []);

/**
 * Gets the list of messages set for the stacked long message bar component in the order of their priority
 */
export const getStackedLongMessagesSortedByPriority = createSelector(getStackedLongMessages, (messages) => {
  const messagesCopy = [...messages];
  return prioritySort(messagesCopy);
});

/**
 * Gets the list of messages set for the global message bar component in the order of their priority
 */
export const getGlobalMessagesSortedByPriority = createSelector(getGlobalMessages, (messages) => {
  const messagesCopy = [...messages];
  return prioritySort(messagesCopy);
});

export const getShortMessagesSortedByPriority = createSelector(getShortMessages, (messages) => {
  const messagesCopy = [...messages];
  return prioritySort(messagesCopy);
});

export const getGlobalJetStreamMessagesSortedByPriority = (type: string) =>
  createSelector(getGlobalJetStreamMessages, (messages) => {
    let messageType = MessageType.ERROR;
    if (type === 'warning') {
      messageType = MessageType.WARN;
    }
    if (type === 'success') {
      messageType = MessageType.SUCCESS;
    }
    const messagesCopy = [...messages.filter((message) => message.type === messageType)];
    return prioritySort(messagesCopy);
  });

/**
 * Returns all the global jetstream messages that are of type WARN or ERROR
 */
export const getGlobalJetStreamMessagesCombinedWarningErrorSortedByPriority = createSelector(getGlobalJetStreamMessages, (messages) => {
  const messagesCopy = [...messages.filter((message) => message.type === MessageType.WARN || message.type === MessageType.ERROR)];
    return prioritySort(messagesCopy);
});

export const getMileagePlanOutageMessageActive = createSelector(getGlobalJetStreamMessages, (messages) => {
  return messages.some((message) => message.key === MessageKey.MILEAGE_PLAN_MAINTENANCE);
});

export const getJetStreamMessagesSortedByPriority = createSelector(getJetStreamMessages, (messages) => {
  const messagesCopy = [...messages];
  return prioritySort(messagesCopy);
});

function prioritySort(messagesCopy: RichMessage[]) {
  return messagesCopy.sort((a, b) => {
    if (a.priority > b.priority) {
      return 1;
    }
    return a.priority < b.priority ? -1 : 0;
  });
}

export const getStackedLongMessageCount = createSelector(getMessageState, (state): number => state?.stackedLongMessages?.length ?? 0);

export const getGlobalMessageCount = createSelector(getMessageState, (state): number => state?.globalMessages?.length ?? 0);

export const getShortMessageCount = createSelector(getMessageState, (state): number => state?.shortMessages?.length ?? 0);

export const getCurrentGlobalMessageIndex = createSelector(getMessageState, (state): number => state?.currentGlobalMessageIndex ?? 0);

export const getCurrentStackedLongMessageIndex = createSelector(getMessageState, (state): number => state?.currentLongMessageIndex ?? 0);

export const getCurrentShortMessageIndex = createSelector(getMessageState, (state): number => state?.currentShortMessageIndex ?? 0);

export const getCurrentStackedLongMessage = createSelector(
  getStackedLongMessagesSortedByPriority,
  getCurrentStackedLongMessageIndex,
  (messages, index): RichMessage | null => (messages ? messages[index] ?? null : null)
);

export const getCurrentGlobalMessage = createSelector(
  getGlobalMessagesSortedByPriority,
  getCurrentGlobalMessageIndex,
  (messages, index): RichMessage | null => (messages ? messages[index] ?? null : null)
);

export const getCurrentLongMessage = createSelector(getMessageState, (state): RichMessage | null => state?.longMessage);

/**
 *  Returns the seat map messages per segment. This selector extracts
 *  messages from longMessages array and filter them out based on the
 *  current segment of the seat map page session. This is needed to
 *  maintain the messages integrity and state between seat map navaigation (i.e. next, previous),
 *  for mulitple segments.
 */
export const getSeatMapLongMessages = createSelector(
  getStackedLongMessagesSortedByPriority,
  getCurrentSegmentIndex,
  (messages, segmentIdx): RichMessage[] | null => messages?.filter((msg) => isForContext(msg, segmentIdx))
);

export const getCurrentSeatMapLongMessage = createSelector(
  getSeatMapLongMessages,
  getCurrentStackedLongMessageIndex,
  (messages, index): RichMessage | null => (messages ? messages[index] ?? null : null)
);

export const getSeatMapMessageCount = createSelector(getSeatMapLongMessages, (messages): number => messages?.length ?? 0);

export const getSeatMapCurrentStackedLongMessageInnerHTML = createSelector(getCurrentSeatMapLongMessage, (message): string =>
  getMessageInnerHTML(message)
);

export const getGlobalJetStreamMessagesInnerHtml = (type: string) =>
  createSelector(getGlobalJetStreamMessagesSortedByPriority(type), (messages): string[] => {
    const returnList: string[] = [];
    messages.forEach((message) => {
      if (!message.passengerId) {
        returnList.push(getJetStreamMainTextMessageInnerHTML(message));
      }
    });
    return returnList;
  });

export const getGlobalJetStreamMessagesCombinedWarningErrorInnerHtml = createSelector(
  getGlobalJetStreamMessagesCombinedWarningErrorSortedByPriority,
  (messages): string[] => {
    const returnList: string[] = [];
    messages.forEach((message) => {
      if (!message.passengerId && (message.type === MessageType.ERROR || message.type === MessageType.WARN)) {
        returnList.push(getJetStreamMainTextMessageInnerHTML(message));
      }
    });
    return returnList;
  }
);

export const getJetStreamMessagesInnerHtmlForPassenger = (passengerId: string) =>
  createSelector(getJetStreamMessagesSortedByPriority, (messages): string[] => {
    const returnList: string[] = [];
    messages.forEach((message) => {
      if (
        message.passengerId &&
        NameNumberUtil.ToNonZeroPaddedCollapseDecimal(message.passengerId) === NameNumberUtil.ToNonZeroPaddedCollapseDecimal(passengerId)
      ) {
        returnList.push(getJetStreamMainTextMessageInnerHTML(message));
      }
    });
    return returnList;
  });

export const getJetStreamMessagesInnerHtml = createSelector(getJetStreamMessagesSortedByPriority, (messages): string[] => {
  const returnList: string[] = [];
  messages.forEach((message) => {
    if (!message.passengerId) {
      returnList.push(getJetStreamMainTextMessageInnerHTML(message));
    }
  });
  return returnList;
});

export const getJetStreamShortMessagesInnerHtml = createSelector(getJetStreamShortMessages, (messages): string[] => {
  const returnList: string[] = [];
  messages.forEach((message) => {
    returnList.push(getJetStreamMainTextMessageInnerHTML(message));
  });
  return returnList;
});

export const getCurrentStackedLongMessageInnerHTML = createSelector(getCurrentStackedLongMessage, (message): string =>
  getMessageInnerHTML(message)
);

export const getGlobalCurrentMessageInnerHTML = createSelector(getCurrentGlobalMessage, (message): string => getMessageInnerHTML(message));

export const getCurrentLongMessageInnerHTML = createSelector(getCurrentLongMessage, (message): string => getMessageInnerHTML(message));

export const getCurrentShortMessage = createSelector(
  getShortMessagesSortedByPriority,
  getCurrentShortMessageIndex,
  (messages, index): RichMessage | null => (messages ? messages[index] ?? null : null)
);

export const getCurrentShortMessageInnerHTML = createSelector(getCurrentShortMessage, (message): string => getMessageInnerHTML(message));

export function getMessageInnerHTML(message: RichMessage | null): string {
  if (!message) {
    return '';
  }

  let transformedEmphasisText: string | null = message.emphasisText ?? null;
  let transformedMainText: string | null = message.mainText ?? null;
  let containsIcon = false;
  if (message.links && message.links?.length > 0) {
    transformedEmphasisText = message.emphasisText ? transformTextToLink(transformedEmphasisText, message.links, message.type) : null;
    transformedMainText = message.mainText ? transformTextToLink(transformedMainText, message.links, message.type) : null;
  }
  if (message.textFormats && message.textFormats?.length > 0) {
    if (message.textFormats.some((textFormat) => textFormat.format === Format.AURO_ICON)) {
      containsIcon = true;
    }
    transformedEmphasisText = message.emphasisText ? transformTextFormat(transformedEmphasisText, message.textFormats) : null;
    transformedMainText = message.mainText ? transformTextFormat(transformedMainText, message.textFormats) : null;
  }
  if (containsIcon) {
    return (
      (transformedEmphasisText ? `<span class="emphasize"><strong>${transformedEmphasisText}</strong></span>&nbsp;` : '') +
      (transformedMainText ? `<span class="main-text">${transformedMainText}</span>` : '')
    );
  }
  return (
    (transformedEmphasisText ? `<span class="emphasize"><strong>${transformedEmphasisText}</strong></span>&nbsp;` : '') +
    (transformedMainText ? `<span class="main-text">${transformedMainText}</span>` : '')
  );
}

export function getJetStreamMainTextMessageInnerHTML(message: RichMessage | null): string {
  let transformedMainText: string | null = message?.mainText ?? null;
  if (message?.links && message?.links?.length > 0) {
    transformedMainText = message?.mainText ? transformTextToLink(transformedMainText, message?.links, message.type) : null;
  }
  if (message?.textFormats && message?.textFormats?.length > 0) {
    transformedMainText = message?.mainText ? transformTextFormat(transformedMainText, message?.textFormats) : null;
  }
  return transformedMainText ? `${transformedMainText}` : '';
}

export function transformTextToLink(innerHTML: string | null, links: AdvisoryLink[], type: string = ''): string | null {
  if (!innerHTML) {
    return null;
  }
  let transformedInnerHTML = innerHTML;
  const linkClass = type === MessageType.ERROR ? '-error' : '';
  const color = type === MessageType.ERROR ? '#fff' : '#0074c8'; // $as-white, $blue-link
  links.forEach((link) => {
    const target = `target=${link.externalLink ? '"_blank"' : '"_self"'}`;
    const escapedLinkText = link.linkText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    transformedInnerHTML = transformText(
      transformedInnerHTML,
      RegExp(`${escapedLinkText}`, 'g'),
      `<a
      class="advisory-link${linkClass}"
      style="color: ${color}"
      href="${link.route}"
      ${target}>${link.linkText}</a>`,
      link.occurrences
    );
  });
  return transformedInnerHTML;
}

export function transformTextFormat(innerHTML: string | null, textFormats: TextFormat[]): string | null {
  if (!innerHTML) {
    return null;
  }
  let transformedInnerHTML = innerHTML;
  textFormats.forEach((textFormat) => {
    // 1. Escape the target text to avoid regex issues
    const escapedTargetText = textFormat.targetText?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // 2. Get the corresponding HTML tag for the format
    const htmlTag = getHtmlTag(textFormat.format);
    // 3. Replace the target text with the HTML tag
    if(textFormat.format === Format.AURO_ICON) {
      // a. Handle auro-icon format separately
      const customColor = `customColor style="color: ${textFormat.auroIconAttribute?.color}; margin-top: -4px"`;
      transformedInnerHTML = transformText(
        transformedInnerHTML,
        RegExp(`${escapedTargetText}`, 'g'),
        `<auro-icon
          category="${textFormat.auroIconAttribute?.category ?? ''}"
          name="${textFormat.auroIconAttribute?.name ?? ''}"
          ${textFormat.auroIconAttribute?.color ? customColor : ''}></auro-icon>`,
        textFormat.occurrences
      );
    }
    else if(htmlTag) {
      // b. Replace the target text with the HTML tag
      transformedInnerHTML = transformText(
        transformedInnerHTML,
        RegExp(`${escapedTargetText}`, 'g'),
        `<${htmlTag}>${textFormat.targetText}</${htmlTag}>`,
        textFormat.occurrences);
    }
  });
  return transformedInnerHTML;
}

/**
 * Gets the HTML tag corresponding to the given format.
 *
 * @param format - The format to get the HTML tag for.
 * @returns - The corresponding HTML tag.
 */
function getHtmlTag(format: Format): string {
  switch (format) {
    case Format.BOLD:
      return 'b';
    case Format.IMPORTANT:
      return 'strong';
    case Format.ITALIC:
      return 'i';
    case Format.EMPHASIZED:
      return 'em';
    case Format.MARKED:
      return 'mark';
    case Format.SMALLER:
      return 'small';
    case Format.DELETED:
      return 'del';
    case Format.INSERTED:
      return 'ins';
    case Format.SUBSCRIPT:
      return 'sub';
    case Format.SUPERSCRIPT:
      return 'sup';
    default:
      return '';
  }
}

/**
 * Replaces parts of a string that matches the regex.
 *
 * @param str - The original string.
 * @param regex - The regular expression to match.
 * @param replacement - The replacement string.
 * @param occurrences - The list of occurrences to replace (1-based index).
 * @returns - The modified string with the specified occurrences replaced.
 */
function transformText(str: string, regex: RegExp, replacement: string, occurrences?: number[]): string {
  if(!occurrences || occurrences.length === 0) {
    // No occurrences specified, replace all
    return str.replace(regex, replacement);
  }
  let matchCount = 0;
  const occurrencesSet = new Set(occurrences);
  return str.replace(regex, (match) => {
    matchCount++;
    if (occurrencesSet.has(matchCount)) {
      return replacement;
    }
    return match;
  });
}

/**
 * Returns true if this message should be shown for the given context information
 */
function isForContext(message: RichMessage, segmentIndex?: number | null): boolean {
  let forSegment = false;
  if (message.segmentIndex === null || message.segmentIndex === undefined || segmentIndex === null || segmentIndex === undefined) {
    // Null segment index means for all segments. We can't use falsy because segment 0 is valid
    forSegment = true;
  } else {
    forSegment = message.segmentIndex === segmentIndex;
  }
  return forSegment;
}
