import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CallerContextComponent } from './caller-context.component';
import { CommonModule } from '@angular/common';
import { UXModule } from '../../shared/ux/ux.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { UXPassengersModule } from '../../shared/ux-passengers/ux-passengers.module';
import { RouterModule } from '@angular/router';
import { CallerContextDropdownComponent } from './caller-context-dropdown/caller-context-dropdown.component';

@NgModule({
    declarations: [CallerContextComponent, CallerContextDropdownComponent],
    imports: [CommonModule, UXModule, InlineSVGModule, UXPassengersModule, RouterModule],
    exports: [CallerContextComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CallerContextModule { }
