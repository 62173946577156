import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { AdvisoriesResponse } from '../../dtos/response/advisories-response/advisories-response';
import { AdvisoriesLookupStatus } from '../../dtos/response/advisories-response/advisories-lookup-status';
import { Advisories } from '../../dtos/response/advisories-response/advisories';
import { AdvisoryPageType } from '../../dtos/request/advisories-request/advisory-page-type';
import { AdvisoryPageFilterTag } from '../../dtos/request/advisories-request/advisory-page-filter-tag';

export interface AdvisoryServiceAPI {
  getAdvisories(advisoryPageType: AdvisoryPageType, advisoryPageFilterTag: AdvisoryPageFilterTag): Observable<AdvisoriesResponse>;
}

@Injectable()
export class AdvisoriesService implements AdvisoryServiceAPI {
  constructor(private http: HttpClient) {}

  getAdvisories(advisoryPageType: AdvisoryPageType, advisoryPageFilterTag: AdvisoryPageFilterTag): Observable<AdvisoriesResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };

    return this.http.get<Advisories>(`api/kontent/${advisoryPageType}/${advisoryPageFilterTag}/advisories`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((advisories) => {
        return { advisories, lookupStatus: AdvisoriesLookupStatus.SUCCESS } as unknown as AdvisoriesResponse;
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          return observableOf({ advisories: null, lookupStatus: AdvisoriesLookupStatus.TIMEOUT });
        }
        if (404 === err.status) {
          return observableOf({ advisories: null, lookupStatus: AdvisoriesLookupStatus.DOES_NOT_EXIST });
        }
        return observableOf({ advisories: null, lookupStatus: AdvisoriesLookupStatus.SYSTEM_FAILURE });
      })
    );
  }
}
