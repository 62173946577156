import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { FlightShoppingRequest } from '../../dtos/request/shopping-request/flight-shopping-request';
import { FlightShoppingResponse } from '../../dtos/response/shopping-response/flight-shopping-response';
import { FlightShoppingResponseFull } from '../../dtos/response/shopping-response/flight-shopping-response-full';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { FlightShoppingFrapiRequest } from '../../dtos/request/flight-shopping-frapi-request/flight-shopping-frapi-request';
import { FlightShoppingFrapiDetails } from '../../dtos/response/flight-shopping-frapi-response/flight-shopping-frapi-details';
import { FlightShoppingFrapiResponse } from '../../models/flight-shopping-frapi-response';

export interface FlightShoppingServiceAPI {
  getFlightShoppingResults(request: FlightShoppingRequest): Observable<FlightShoppingResponse>;
  getFlightShoppingFrapi(id: string, request: FlightShoppingFrapiRequest): Observable<FlightShoppingFrapiResponse>;
}

@Injectable()
export class FlightShoppingService implements FlightShoppingServiceAPI {
  constructor(private http: HttpClient) {}

  getFlightShoppingResults(request: FlightShoppingRequest): Observable<FlightShoppingResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<FlightShoppingResponseFull>('api/flight/shopping', request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return {
          success: true,
          response
        };
      }),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          return of({ success: false, error: 'Timeout' });
        } else if (err.status === 500) {
          return of({ success: false, error: 'System failure' });
        } else {
          return of({ success: false, error: 'Uncaught' });
        }
      })
    );
  }

  getFlightShoppingFrapi(id: string, request: FlightShoppingFrapiRequest): Observable<FlightShoppingFrapiResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<FlightShoppingFrapiDetails>('api/flight/shopping/frapi', request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((flightShoppingFrapiResponse) => {
        return {id, response: flightShoppingFrapiResponse, success: true};
      }),
      catchError((err: HttpErrorResponse) => {
        if (timeoutError.statusText === err.statusText) {
          return of({ id, success: false, error: 'Timeout' });
        } else if (err.status === 500) {
          return of({ id, success: false, error: 'System failure' });
        } else {
          return of({ id, success: false, error: 'Uncaught' });
        }
      })
    );
  }
}
