import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializeFlightShoppingServiceState,
  loadFlightShoppingFrapiResultsComplete,
  loadFlightShoppingFrapiResultsInitialBooking,
  loadFlightShoppingResults,
  loadFlightShoppingResultsComplete,
  setSelectedShoppingOption,
} from './flight-shopping-service.actions';
import { flighShoppingFrapiAdapter, initialFlightShoppingServiceState } from './flight-shopping-service.state';

const featureReducer = createReducer(
  initialFlightShoppingServiceState,
  on(initializeFlightShoppingServiceState, (state) => ({ ...state, ...initialFlightShoppingServiceState })),
  on(loadFlightShoppingResults, (state) => ({
    ...state,
    results: null,
    status: Status.UPDATING,
  })),
  on(loadFlightShoppingResultsComplete, (state, { success, response }) => ({
    ...state,
    results: response,
    sortedFilteredResults: response?.sortedFilteredFlightShoppingResults ?? [],
    status: Status.STABLE,
  })),
  on(setSelectedShoppingOption, (state, { selectedOption, selectedIndex }) => ({
    ...state,
    selectedResult: selectedOption,
    currentShoppingIndex: selectedIndex,
  })),
  on(loadFlightShoppingFrapiResultsInitialBooking, (state, { featureId }) => ({
    ...state,
    flightShoppingFrapiResponses: flighShoppingFrapiAdapter.setOne(
      { id: featureId, crudStatus: Status.LOADING },
      state.flightShoppingFrapiResponses
    ),
  })),
  on(loadFlightShoppingFrapiResultsComplete, (state, { result }) => ({
    ...state,
    flightShoppingFrapiResponses: flighShoppingFrapiAdapter.updateOne(
      {
        id: result.id,
        changes: {
          crudStatus: Status.STABLE,
          response: result.response,
          success: result.success,
          error: result.error,
        },
      },
      state.flightShoppingFrapiResponses
    ),
  }))
);

export function flightShoppingServiceReducer(state = initialFlightShoppingServiceState, action: Action) {
  return featureReducer(state, action);
}
