import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { defer as observableDefer, Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { UserData } from '../../dtos/response/login-response/user-data';
import { MileagePlanEnrollmentResult } from '../../dtos/response/mileage-plan-response/mileage-plan-enrollment-result';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { getUser } from '../login-service/state/login-service.selector';
import { LoginServiceState } from '../login-service/state/login-service.state';
import { MileagePlanEnrollmentRequest } from '../../dtos/request/mileage-plan-enrollment-request';
import { HttpStatusCode } from '../../dtos/http-status-code';

export interface MileagePlanEnrollmentServiceAPI {
  enrollMPMember(enrollMPMemberRequest: MileagePlanEnrollmentRequest): Observable<MileagePlanEnrollmentResult>;
}

@Injectable({
  providedIn: 'root',
})
export class MileagePlanEnrollmentService implements MileagePlanEnrollmentServiceAPI {
  private user: UserData;

  constructor(
    private readonly http: HttpClient,
    private readonly eventService: GlobalEventService,
    private readonly loginServiceStore: Store<LoginServiceState>
  ) {
    this.loginServiceStore.pipe(select(getUser)).subscribe((user) => (this.user = user));
  }

  enrollMPMember(enrollMemberRequest: MileagePlanEnrollmentRequest): Observable<MileagePlanEnrollmentResult> {
    return this.http.post<MileagePlanEnrollmentResult>('api/mileage-plan-enrollment', enrollMemberRequest).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((mileagePlanEnrollmentResult: MileagePlanEnrollmentResult) => mileagePlanEnrollmentResult),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({
            statusCode: HttpStatusCode.GATEWAY_TIMEOUT,
            exceptionContent: 'Mileage plan enrollment service timed out',
          });
        }
        if (err.status === HttpStatusCode.BAD_REQUEST) {
          return of({ statusCode: HttpStatusCode.BAD_REQUEST, exceptionContent: err.error });
        }
        return of({ statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR, exceptionContent: err.error });
      })
    );
  }
}
