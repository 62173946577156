import { createAction } from '@ngrx/store';
import { savePassengerFeatureKey } from './save-passenger.state';
import { SavePassengerRequest } from '../../../dtos/request/save-passenger-request/save-passenger-request';
import { SavePassengerResponse } from '../../../dtos/response/save-passenger-response/save-passenger-response';
import { RemovePassengerRequest } from '../../../dtos/request/save-passenger-request/remove-passenger-request';
import { AddSsrsResponse } from '../../../dtos/response/ssr-response/add-ssrs-response';
import { AddUpdateLapInfantRequest } from '../../../dtos/request/ssr-requests/add-lapInfant-request';
import { RemovePassengerDataSlice } from '../../../dtos/request/save-passenger-request/remove-passenger-data-slice';

/**
 * Resets the state
 */
export const initializeSavePassengerState = createAction(`[${savePassengerFeatureKey}] Initialize save passenger info`);

/**
 * Submits the savePassenger request to the API
 */
export const savePassengerData = createAction(
  `[${savePassengerFeatureKey}] Save passenger info`,
  (request: SavePassengerRequest, passengerHashId: string) => ({
    request,
    passengerHashId
  })
);

/**
 * Submits the AddUpdateLapInfant request to the API
 */
export const saveLapInfantData = createAction(
  `[${savePassengerFeatureKey}] Save lap infant info`,
  (request: AddUpdateLapInfantRequest, passengerHashId: string) => ({
    request,
    passengerHashId
  })
);

/**
 * Submits the AddUpdateLapInfant request to the API
 */
export const updateLapInfantData = createAction(
  `[${savePassengerFeatureKey}] Update lap infant info`,
  (request: AddUpdateLapInfantRequest, passengerHashId: string) => ({
    request,
    passengerHashId
  })
);

/**
 * Called when the API call is complete
 */
export const savePassengerDataComplete = createAction(
  `[${savePassengerFeatureKey}] Save passenger info complete`,
  (response: SavePassengerResponse, passengerHashId: string) => ({
    response,
    passengerHashId
  })
);

/**
 * Called when the API call is complete
 */
export const saveLapInfantDataComplete = createAction(
  `[${savePassengerFeatureKey}] Save lap infant info complete`,
  (response: AddSsrsResponse, infantHashId: string) => ({
    response,
    infantHashId
  })
);

/**
 * Called when the API call is complete
 */
export const resetSaveLapInfantResponseInfo = createAction(
  `[${savePassengerFeatureKey}] Clear Save lap infant info response state`,
  (infantHashId: string) => ({
    infantHashId
  })
);

/**
 * Submits the removePassenger request to the API to remove a slice of data
 */
export const removePassengerData = createAction(
  `[${savePassengerFeatureKey}] Remove passenger info`,
  (request: RemovePassengerRequest, passengerHashId: string) => ({
    request,
    passengerHashId,
  })
);

/**
 * Resets the removePassenger slice of data for a passenger
 */
export const resetRemovePassengerData = createAction(
  `[${savePassengerFeatureKey}] Reset passenger's remove passenger info`,
  (passengerHashId: string) => ({
    passengerHashId,
  })
);

/**
 * Called when the remove API call is complete
 */
export const removePassengerDataComplete = createAction(
  `[${savePassengerFeatureKey}] Remove passenger info complete`,
  (response: SavePassengerResponse, passengerHashId: string) => ({
    response,
    passengerHashId
  })
);

/**
 * Sets the passenger info saved flag
 * Used after removing the International Documents or Emergency Contact forms
 */
export const setPassengerSaved = createAction(
  `[${savePassengerFeatureKey}] Set passenger info saved`,
  (passengerHashId: string, saved: boolean) => ({
    passengerHashId,
    saved
  })
);

/**
 * Sets the type of passenger info being removed
 */
export const setRemovePassengerInfoType = createAction(
  `[${savePassengerFeatureKey}] Set passenger info removal type`,
  (dataSlice: RemovePassengerDataSlice | null) => ({
    dataSlice
  })
);
