import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { RichMessage } from '../../../models/message/rich-message';
import {
  getCurrentGlobalMessage,
  getCurrentGlobalMessageIndex,
  getCurrentSeatMapLongMessage,
  getCurrentStackedLongMessage,
  getCurrentStackedLongMessageIndex,
  getCurrentStackedLongMessageInnerHTML,
  getGlobalCurrentMessageInnerHTML,
  getGlobalMessageCount,
  getSeatMapCurrentStackedLongMessageInnerHTML,
  getSeatMapMessageCount,
  getStackedLongMessageCount,
} from 'src/app/services/message-service/state/message.selectors';
import { RootState } from '../../../state/state';
import { Router } from '@angular/router';
import { removeGlobalMessage, removeStackedLongMessage, setCurrentGlobalMessageIndex, setCurrentStackedLongMessageIndex } from '../../../services/message-service/state/message.actions';
import { MessageType } from '../../../models/message/message-type';

@Component({
  selector: 'app-stacked-long-message-bar',
  templateUrl: './stacked-long-message-bar.component.html',
  styleUrls: ['./stacked-long-message-bar.component.scss'],
})
export class StackedLongMessageBarComponent implements OnInit, OnDestroy {
  @Input() forSeatMap = false;
  @Input() global = false;
  @ViewChild('content', { static: true }) content: ElementRef;
  message: RichMessage | null;
  messageCount: number;
  currentLongMessageIndex = 0;
  currentGlobalIndex = 0;
  messageInnerHTML: string | null;
  isErrorMessage: boolean;
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store<RootState>, private router: Router) { }

  ngOnInit(): void {
    // Message
    this.store.pipe(select(getCurrentStackedLongMessage), takeUntil(this.onDestroy$)).subscribe((message) => {
      if (!this.forSeatMap && !this.global) {
        this.message = message;
        this.isErrorMessage = message?.type === MessageType.ERROR;
      }
    });
    this.store.pipe(select(getCurrentGlobalMessage), takeUntil(this.onDestroy$)).subscribe((message) => {
      if (this.global) {
        this.message = message;
        this.isErrorMessage = message?.type === MessageType.ERROR;
      }
    });
    this.store.pipe(select(getCurrentSeatMapLongMessage), takeUntil(this.onDestroy$)).subscribe((message) => {
      if (this.forSeatMap) {
        this.message = message;
        this.isErrorMessage = message?.type === MessageType.ERROR;
      }
    });
    // Message Count
    this.store.pipe(select(getStackedLongMessageCount), takeUntil(this.onDestroy$)).subscribe((messageCount) => {
      if (!this.forSeatMap && !this.global) {
        this.messageCount = messageCount;
      }
    });
    this.store.pipe(select(getGlobalMessageCount), takeUntil(this.onDestroy$)).subscribe((messageCount) => {
      if (this.global) {
        this.messageCount = messageCount;
      }
    });
    this.store.pipe(select(getSeatMapMessageCount), takeUntil(this.onDestroy$)).subscribe((messageCount) => {
      if (this.forSeatMap) {
        this.messageCount = messageCount;
      }
    });
    // Message Index
    this.store.pipe(select(getCurrentStackedLongMessageIndex), takeUntil(this.onDestroy$))
      .subscribe((index) => (this.currentLongMessageIndex = index));
    this.store.pipe(select(getCurrentGlobalMessageIndex), takeUntil(this.onDestroy$))
      .subscribe((index) => (this.currentGlobalIndex = index));
    // Message Inner HTML
    this.store.pipe(select(getCurrentStackedLongMessageInnerHTML), takeUntil(this.onDestroy$)).subscribe((innerHTML) => {
      if (this.content && !this.global && !this.forSeatMap) {
        this.content.nativeElement.innerHTML = innerHTML;
      }
    });
    this.store.pipe(select(getGlobalCurrentMessageInnerHTML), takeUntil(this.onDestroy$)).subscribe((innerHTML) => {
      if (this.content && this.global) {
        this.content.nativeElement.innerHTML = innerHTML;
      }
    });
    this.store.pipe(select(getSeatMapCurrentStackedLongMessageInnerHTML), takeUntil(this.onDestroy$)).subscribe((innerHTML) => {
      if (this.content && this.forSeatMap) {
        this.content.nativeElement.innerHTML = innerHTML;
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  closeBar(message: RichMessage): void {
    if (message) {
      if (this.global) {
        this.store.dispatch(removeGlobalMessage(message));
      } else {
        this.store.dispatch(removeStackedLongMessage(message));
      }
    }
  }

  previous(currentIndex: number): void {
    const nextIndex = currentIndex === 0 ? this.messageCount - 1 : currentIndex - 1;
    if (this.global) {
      this.store.dispatch(setCurrentGlobalMessageIndex(nextIndex));
    } else {
      this.store.dispatch(setCurrentStackedLongMessageIndex(nextIndex));
    }
  }

  next(currentIndex: number): void {
    const nextIndex = currentIndex === this.messageCount - 1 ? 0 : currentIndex + 1;
    if (this.global) {
      this.store.dispatch(setCurrentGlobalMessageIndex(nextIndex));
    } else {
      this.store.dispatch(setCurrentStackedLongMessageIndex(nextIndex));
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    event.preventDefault();
    if (event.target.target === '_blank') {
      window.open(event.target.href);
    } else {
      const target = event.target as HTMLAnchorElement;
      this.router.navigate([target.pathname]);
    }
  }
}
