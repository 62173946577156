import { Action, createReducer, on } from '@ngrx/store';
import { initialNewsFeedServiceState } from './news-feed-service.state';
import { clearNewsFeed, loadNewsFeed, loadNewsFeedComplete } from './news-feed-service.actions';
import { NewsFeedLookupStatus } from '../../../dtos/response/news-feed-response/news-feed-lookup-status';

const featureReducer = createReducer(
  initialNewsFeedServiceState,

  on(clearNewsFeed, (state) => {
    return {
      ...state,
      newsFeed: null,
      lookupStatus: NewsFeedLookupStatus.UNKNOWN,
    };
  }),

  on(loadNewsFeed, (state) => ({
    ...state,
    newsFeed: {
      newsList: [],
      lookupStatus: NewsFeedLookupStatus.LOADING
    }
  })),

  on(loadNewsFeedComplete, (state, { newsFeedResponse }) => {
    return {
      ...state,
      newsFeed: newsFeedResponse
    };
  })
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function newsFeedServiceReducer(state = initialNewsFeedServiceState, action: Action) {
  return featureReducer(state, action);
}
