import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { RootState } from '../../../state/state';
import { PassengerForm } from '../../../models/passenger-forms/passenger-form';
import { LapInfantForm } from '../../../models/passenger-forms/lap-infant-form';
import { ContactInfoForm } from '../../../models/passenger-forms/contact-info-form';

/**
 * The string name of the passenger forms 'slice' of state
 */
export const passengerFormsFeatureKey = 'passengerForms';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  passengerForms: PassengerFormsState;
}

/**
 * The state that represents the passenger forms
 */
export interface PassengerFormsState {
  contactInfoForm: ContactInfoForm;
  infantIds: number[];
  passengerForms: PassengerFormEntity;
  lapInfantForms: LapInfantFormEntity;
}

/**
 * Interface for storing PassengerForm objects as an ordered array of ids and an unordered dictionary
 */
export interface PassengerFormEntity extends EntityState<PassengerForm> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the passengerForms entity
 */
export const passengerFormsAdapter: EntityAdapter<PassengerForm> = createEntityAdapter<PassengerForm>({
  selectId: (passengerForm) => passengerForm.passengerHashId,
});

/**
 * Initial state of the PassengerFormEntity as defined by the passengerFormsAdapter
 */
export const initialPassengerFormsEntityState: PassengerFormEntity = passengerFormsAdapter.getInitialState({});


/**
 * Interface for storing LapInfantForm objects as an ordered array of ids and an unordered dictionary
 */
export interface LapInfantFormEntity extends EntityState<LapInfantForm> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the lapInfantForms entity
 */
export const lapInfantFormsAdapter: EntityAdapter<LapInfantForm> = createEntityAdapter<LapInfantForm>({
  selectId: (passengerForm) => passengerForm.passengerHashId,
});

/**
 * Initial state of the LapInfantFormEntity as defined by the lapInfantFormsAdapter
 */
export const initialLapInfantFormsEntityState: LapInfantFormEntity = lapInfantFormsAdapter.getInitialState({});

/**
 * Initial state for the passenger forms state
 */
export const initialPassengerFormsState: PassengerFormsState = {
  contactInfoForm: { formExpanded: true },
  infantIds: [],
  passengerForms: initialPassengerFormsEntityState,
  lapInfantForms: initialLapInfantFormsEntityState,
};
