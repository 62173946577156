import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, defer, map, of, throwError, timeout } from 'rxjs';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { TimeoutLimit } from '../../models/timeout-limit';
import { timeoutError } from '../../models/timeout-error';
import { HttpStatusCode } from '../../dtos/http-status-code';
import { BulkPhoneFieldRequest } from '../../dtos/request/phone-field-request/bulk-phone-field-request';
import { PhoneFieldResponse } from '../../dtos/response/phone-field-response/phone-field-response';
import { PhoneFieldResponseInfo } from '../../dtos/response/phone-field-response/phone-field-response-info';

export interface PhoneFieldServiceAPI {
  savePhoneFields(request: BulkPhoneFieldRequest, confirmationCode: string): Observable<PhoneFieldResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class PhoneFieldService implements PhoneFieldServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  savePhoneFields(request: BulkPhoneFieldRequest, confirmationCode: string): Observable<PhoneFieldResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http.post<PhoneFieldResponseInfo>(
      `api/reservation/${confirmationCode}/phone-field/bulk-add-or-update`, request, options).pipe(
      timeout({
        each: TimeoutLimit.MEDIUM,
        with: () => defer(() => throwError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        const statusCode =
          response?.failedList?.length > 0 && response?.successList.length > 0 ? HttpStatusCode.MULTI_STATUS : HttpStatusCode.OK;
        const fullResponse: PhoneFieldResponse = { statusCode, response };
        return fullResponse;
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ statusCode: HttpStatusCode.GATEWAY_TIMEOUT, exceptionContent: 'Timeout', response: null });
        }
        return of({ statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR, exceptionContent: err.error, response: null });
      })
    );
  }
}
