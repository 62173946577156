import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaggageJourneyServiceState, baggageServiceFeatureKey } from './baggage-service.state';
import { Status } from '../../../models/status';

const getBaggageJourneyServiceState = createFeatureSelector<BaggageJourneyServiceState>(baggageServiceFeatureKey);

export const getBaggageJourney = createSelector(getBaggageJourneyServiceState, (state) => state?.baggageJourneyResponse);
export const getBaggageJourneyStatus = createSelector(getBaggageJourneyServiceState, (state) => state?.status);
export const getBaggageJourneyLastNames = createSelector(getBaggageJourneyServiceState, (state) => state?.lastNames);
export const getBaggageJourneyConfirmationCode = createSelector(getBaggageJourneyServiceState, (state) => state?.confirmationCode);
export const getShowSearchForm = createSelector(getBaggageJourneyServiceState, (state) => state?.showSearchForm);
export const getBaggageJourneySelectedGuest = createSelector(getBaggageJourneyServiceState, (state) => state?.selectedGuest);
export const getBaggageJourneyError = createSelector(getBaggageJourney, (response) => response?.error);
export const getShowCheckedBags = createSelector(getBaggageJourneyServiceState, (state) => state?.showCheckedBags);

/*
 * This selector returns the distinct list of guests in the baggage journey response.
 */
export const getBaggageJourneyGuests = createSelector(getBaggageJourney, (baggageJourneyResponse) =>
  Array.from(
    new Set(
      baggageJourneyResponse?.baggageJourneyDetailsList.map(
        (baggageJourneyDetails) => baggageJourneyDetails.bookingFirstName + ' ' + baggageJourneyDetails.bookingLastName
      )
    )
  )
);

/*
 * This selector returns the list of baggageJourneyDetails for the selected passenger.
 */
export const getBaggageJourneyForPassenger = createSelector(getBaggageJourneyServiceState, (state) =>
  state?.baggageJourneyResponse?.baggageJourneyDetailsList.filter(
    (baggageJourneyDetails) => baggageJourneyDetails.bookingFirstName + ' ' + baggageJourneyDetails.bookingLastName === state?.selectedGuest
  )
);

/*
 * This selector returns the is loaded state for checked baggage.
 */
export const getIsLoadedCheckedBaggage = createSelector(
  getBaggageJourneyServiceState,
  (state): boolean => state.status === Status.STABLE
);

/*
 * This selector returns a list of all baggageJourneyDetails for Checked baggage.
 */
export const getCheckedBags = createSelector(getBaggageJourney, (baggageJourneyResponse) =>
  baggageJourneyResponse?.baggageJourneyDetailsList.map(
    (baggageJourneyDetails) => baggageJourneyDetails
  )
);
