import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as validators from '../../form-input/validators/commonValidators';
import { Router } from '@angular/router';
import { ReservationUseCase } from '../../../use-cases/reservation.use-case';
import { setMileagePlanProfileSearchMileagePlanNumber } from '../../../state/mileage-plan-profile-search/mileage-plan-profile-search.actions';
import { RootState } from '../../../state/state';
import { Store, select } from '@ngrx/store';
import { setCouponRecordSearchTicketNumber } from '../../../state/coupon-record-search/coupon-record-search.actions';
import { resetTicketSearchResults, searchByTicketNumber } from '../../../services/ticket-service/state/ticket-service.actions';
import { CouponRecordSearchUseCase } from '../../../use-cases/coupon-record-search.use-case';
import { TicketSearchByTicketNumber } from '../../../dtos/request/ticket-search-request/ticket-search-by-ticket-number';
import { AirlineCode } from '../../../models/airlines/airline-code';
import { getRoutedConfirmationCode } from '../../../services/reservation-service/state/reservation-service.selectors';
import { Subject, take, takeUntil } from 'rxjs';
import { GlobalEvent, GlobalEventService } from '../../../services/global-event-service/global-event.service';
import { mileagePlanProfileSearch } from '../../../services/mileage-plan-profile-service/state/mileage-plan-profile-service.actions';
import { resetJetStreamMessagesForNonFiltered } from '../../../services/message-service/state/message.actions';
import { LoggerService } from '../../../services/logger-service/logger.service';
import { getUser } from '../../../services/login-service/state/login-service.selector';
import { UserData } from '../../../dtos/response/login-response/user-data';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  validators = validators;
  mileagePlanLabel: string;
  prefix: string;
  private user: UserData;
  private onDestroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<RootState>,
    private reservationUseCase: ReservationUseCase,
    private couponRecordSearchUseCase: CouponRecordSearchUseCase,
    private eventService: GlobalEventService,
    private loggerService: LoggerService) { }

  ngOnInit(): void {
    this.mileagePlanLabel = 'Mileage Plan<sup style="top: -0.4em; left: -0.2em; font-size: 0.6em;">TM</sup> number';
    this.prefix = this.couponRecordSearchUseCase.getTicketPrefix(AirlineCode.AS);
    this.store.pipe(select(getUser), takeUntil(this.onDestroy$)).subscribe((user) => { this.user = user;});
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  /**
   * @param confirmationCode Reservation code
   * The dropdown component will allow only 6 alphabets based on the
   * pattern set with [attr.data-pattern] and [attr.data-allowedchars]. This method will always recieve
   * values based on those patterns.
   */
  researvationSearch(confirmationCode: string) {
    if (confirmationCode) {
      confirmationCode = confirmationCode.toUpperCase();

      this.loggerService.log({
        event: 'GlobalSearch',
        searchType: 'ByConfirmationCode',
        searchCriteria: confirmationCode,
        username: this.user.username,
        homeCity: this.user.sabreAAA,
      });

      this.reservationUseCase.clearReservation();
      this.store.select(getRoutedConfirmationCode).pipe(take(1)).subscribe(val => {
        if (confirmationCode === val) {
          this.eventService.broadcast(GlobalEvent.RESERVATION_RELOAD);
        }
      });
      this.router.navigate(['reservation', confirmationCode]);
    }
  }

  /**
   *
   * @param mileagePlanNumber Passenger mileage plan number
   * The dropdown component will allow only numbers based on the
   * pattern set with [attr.data-pattern] and [attr.data-allowedchars]. This method will always recieve
   * values based on those patterns.
   */
  guestProfileSearch(mileagePlanNumber: string) {
    if (mileagePlanNumber) {

      this.loggerService.log({
        event: 'GlobalSearch',
        searchType: 'ByMileagePlanNumber',
        searchCriteria: mileagePlanNumber,
        username: this.user.username,
        homeCity: this.user.sabreAAA,
      });

      // Update store
      this.store.dispatch(resetJetStreamMessagesForNonFiltered());
      this.store.dispatch(setMileagePlanProfileSearchMileagePlanNumber(mileagePlanNumber));
      this.store.dispatch(mileagePlanProfileSearch());
      this.router.navigateByUrl(`mileage-plan-profile-search/${mileagePlanNumber}/profile/summary`);
    }
  }

 /**
  * Searches for ticket details based on the provided ticket number.
  * @param ticketNumber The ticket number to be searched.
  * The dropdown component only allows numbers based on the pattern set with [attr.data-pattern] and [attr.data-allowedchars].
  * This method will always receive values based on those patterns.
  */
  ticketSearch(ticketNumber: string) {
    if (ticketNumber) {
      // The search input will always return 10 digits because of the validation.
      // Add the prefix to the returned value to perform the search.
      ticketNumber = this.prefix + ticketNumber;

      this.loggerService.log({
        event: 'GlobalSearch',
        searchType: 'ByTicketNumber',
        searchCriteria: ticketNumber,
        username: this.user.username,
        homeCity: this.user.sabreAAA,
      });

      this.store.dispatch(setCouponRecordSearchTicketNumber(ticketNumber));
      this.store.dispatch(resetTicketSearchResults());
      this.store.dispatch(
        searchByTicketNumber(this.couponRecordSearchUseCase.grabTicketNumber({ ticketNumber } as TicketSearchByTicketNumber)));

      // Navigate to the coupon record search page with the ticket number route.
      this.router.navigate(['coupon-record-search', 'ticket-number']);
    }
  }
}
