import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { PassengerSeatLocation } from '../../../dtos/response/seat-map-lookup-response/passenger-seat-location';
import { SeatMapLookupStatus } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-status';
import { getCurrentSegmentIndex } from '../../../features/seat-map/state/seat-map.selectors';
import { SeatMap } from '../../../models/seat-map/seat-map';
import { SSRCode } from '../../../models/ssr/ssr-code';
import { Status } from '../../../models/status';
import {
  getPassengers,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getSegmentById,
  getTicketNumbersNoVoid,
} from '../../reservation-service/state/reservation-service.selectors';
import { seatsServiceFeatureKey, SeatsServiceState } from './seats-service.state';
import { SeatMapLookupRequest } from '../../../dtos/request/seat-map-lookup-request/seat-map-lookup-request';
import { SeatMapLookupRequestV2 } from '../../../dtos/request/seat-map-lookup-request/seat-map-lookup-request-v2';
import { LapInfant } from '../../../dtos/request/seat-map-lookup-request/LapInfant';
import { Loyalty } from '../../../dtos/request/seat-map-lookup-request/Loyalty';
import { RequestPassenger } from '../../../dtos/request/seat-map-lookup-request/RequestPassenger';
import { mapPassengerLoyaltyToSeatMapRequestLoyalty } from '../../../utils/tier-status-helper';

const getSeatsServiceState = createFeatureSelector<SeatsServiceState>(seatsServiceFeatureKey);

/**
 * Gets the seat map lookup request SSRs
 */
export const getAllSeatMapLookupRequestSsrs = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getPassengers,
  (segments: Segment[], passengers: Passenger[]) => {
    const uniqueSSRsPerSegment = [];

    for (const segment of segments) {
      const ssrsPerSeg = [];
      passengers.forEach((passenger) => {
        if (passenger.ssrs) {
          const passengerSsrs = passenger.ssrs.filter((ssr) => ssr.segmentHashId === segment.hashId).map((el) => el.serviceCode);
          ssrsPerSeg.push.apply(ssrsPerSeg, passengerSsrs);
        }
      });

      uniqueSSRsPerSegment.push([...new Set(ssrsPerSeg)]);
    }

    return uniqueSSRsPerSegment;
  }
);

/**
 * Gets the seat map lookup request SSRs
 */
export const getSeatMapLookupRequestSsrsBySegment = (segmentHashId: string) => createSelector(
  getSegmentById(segmentHashId),
  getPassengers,
  (segment: Segment, passengers: Passenger[]): string[] => {
    if(segment === null || segment === undefined) {
      return [];
    }

    const ssrs = passengers?.flatMap((passenger) =>
      passenger.ssrs
        ? passenger.ssrs
            .filter((ssr) => ssr.segmentHashId === segment.hashId)
            .map((ssr) => ssr.serviceCode)
            .filter((serviceCode) => serviceCode !== null)
        : []
    ) ?? [];
    return [...new Set(ssrs)]; // Remove duplicates
  }
);

/**
 * Gets the seat map lookup request SSRs
 * This mapping logic is needed as the Tier Status is being passed up to the Seats Service to determine
 * the Premium Class and Exit Row Upgrade pricing which includes Auto-Waiver logic.  The Seats Service
 * requires the following format for Tier Status values:
 * MVP, Gold, Gold75K, Gold100K, AA_EMD, AA_SPH, AA_RBY, Unknown
 */
export const getSeatMapLookupRequestTierStatuses = createSelector(
  getPassengers,
  (passengers: Passenger[]) => {
    const tierStatuses: string[] = [];
    passengers?.forEach((passenger) => {
      switch (passenger?.loyalty?.tierStatus) {
        case 'MVP':
        case 'Gold':
          tierStatuses.push(passenger?.loyalty?.tierStatus);
          break;
        case 'MVP Gold 75K':
          tierStatuses.push('Gold75K');
          break;
        case 'MVP Gold 100K':
          tierStatuses.push('Gold100K');
          break;
        case 'EMD':
        case 'SPH':
        case 'RBY':
          tierStatuses.push('AA_' + passenger?.loyalty?.tierStatus);
          break;
        default:
          tierStatuses.push('Unknown');
          break;
      }
    });
    return tierStatuses;
  }
);

/**
 * Get a list of seat locations for passengers assigned to seats in the reservation
 */
export const getPrereservedSeatsForAllSegments = createSelector(
  getPassengers,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  (passengers: Passenger[], segments: Segment[]): PassengerSeatLocation[][] => {
    const prereservedSeatsAllSegments = [];
    for (let i = 0; i < segments.length; i++) {
      const preReservedSeats = [];
      passengers?.forEach((passenger) => {
        if (passenger.seats && passenger.seats[i] && passenger.seats[i]?.letter && passenger.seats[i].row) {
          const prereservedSeat: PassengerSeatLocation = {
            passenger,
            letter: passenger?.seats[i]?.letter,
            row: passenger?.seats[i]?.row,
            upsellName: passenger?.seats[i]?.upsellName,
            upsellPrice: passenger?.seats[i]?.upsellPrice,
            upsellPriceBeforeTaxes: passenger?.seats[i]?.upsellPriceBeforeTaxes,
            upsellTaxes: passenger?.seats[i]?.upsellTaxes,
          };
          preReservedSeats.push(prereservedSeat);
        }
        // Check if the current passenger has EXST or CBBG seats preassigned
        if (passenger.extraSeatRefs) {
          passenger.extraSeatRefs.forEach((extraSeatRef) => {
            if (extraSeatRef.seats[i]?.letter && extraSeatRef.seats[i]?.row) {
              const prereservedExtraSeat: PassengerSeatLocation = {
                passenger: extraSeatRef,
                letter: extraSeatRef.seats[i]?.letter,
                row: extraSeatRef.seats[i]?.row,
                upsellName: extraSeatRef.seats[i]?.upsellName,
                upsellPrice: extraSeatRef.seats[i]?.upsellPrice,
                upsellPriceBeforeTaxes: extraSeatRef.seats[i]?.upsellPriceBeforeTaxes,
                upsellTaxes: extraSeatRef.seats[i]?.upsellTaxes,
              };
              preReservedSeats.push(prereservedExtraSeat);
            }
          });
        }
      });
      prereservedSeatsAllSegments.push(preReservedSeats);
    }
    return prereservedSeatsAllSegments.length > 0 ? prereservedSeatsAllSegments : [];
  }
);

/**
 * Get the seat map for the current segment index
 */
export const getCurrentSeatMap = createSelector(
  getSeatsServiceState,
  getCurrentSegmentIndex,
  (state: SeatsServiceState, currentSeatMapSegmentIndex: number): SeatMap => {
    return state?.seatMaps.entities[currentSeatMapSegmentIndex]?.processedSeatMap;
  }
);

/**
 * Get the seat map success status for the current segment index
 */
export const getCurrentSeatMapStatus = createSelector(
  getSeatsServiceState,
  getCurrentSegmentIndex,
  (state: SeatsServiceState, currentSeatMapSegmentIndex: number): SeatMapLookupStatus => {
    return state?.seatMaps.entities[currentSeatMapSegmentIndex]?.status;
  }
);

/**
 * Get the CRUD status for seatmap lookup
 */
export const getSeatMapLookupCrudStatus = createSelector(
  getSeatsServiceState,
  (state: SeatsServiceState): Status => state?.seatMapLookupCrudStatus
);

/**
 * Gets the seat map lookup request
 */
export const getLookupAllSeatMapsRequest = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getAllSeatMapLookupRequestSsrs,
  getTicketNumbersNoVoid,
  getPrereservedSeatsForAllSegments,
  (
    segments: Segment[],
    seatMapLookupSsrs: SSRCode[][],
    ticketNumbers: string[],
    preReservedSeats: PassengerSeatLocation[][]
  ): SeatMapLookupRequest[] => {
    const seatMapRequests = [];
    for (let i = 0; i < segments.length; i++) {
      if (segments[i]) {
        const seatMapLookupRequest: SeatMapLookupRequest = {
          carrierCode: segments[i].operatedByAirlineCode,
          departureDate: segments[i].departureDateTime,
          destination: segments[i].arrivalAirport,
          fareClass: segments[i].serviceClassCode,
          flightNumber: segments[i].operatingAirlineFlightNumber,
          origin: segments[i].departureAirport,
          plane: segments[i]?.plane,
          seatArea: segments[i].seatArea,
          ssrs: seatMapLookupSsrs[i] ?? [],
          ticketNumbers,
          preReservedSeats: preReservedSeats[i] ?? [],
          segmentIndex: i,
        };
        seatMapRequests.push(seatMapLookupRequest);
      }
    }
    return seatMapRequests;
  }
);

/**
 * Gets the seat map lookup V2 request
 * Note: 1039370 - This is minimum data required for seat map lookup V2 to success. Needs to revisit.
 */
export const getSeatMapLookupV2Request = (segmentHashId: string) => createSelector(
  getSegmentById(segmentHashId),
  getPassengers,
  (
    segment: Segment,
    passengers: Passenger[],
  ): SeatMapLookupRequestV2 => {
    const seatMapRequest: SeatMapLookupRequestV2 = {
      segments: [],
      passengers: [],
      passengersSegments: [],
    };
    if (segment) {
      seatMapRequest.segments.push({
        departureDateTime: segment.departureDateTime,
        destination: segment.arrivalAirport,
        origin: segment.departureAirport,
        fareClass: segment.serviceClassCode,
        flightNumber: Number(segment.operatingAirlineFlightNumber),
        isASOperated: segment.operatedByAirlineCode === 'AS',
        isQXOperated: segment.operatedByAirlineCode === 'QX',
        marketingCarrier: segment.marketedByAirlineCode,
        operatingCarrier: segment.operatedByAirlineCode,
        isCheckedIn: false, // This property doesn't do anything
        operatingFlightNumber: Number(segment.operatingAirlineFlightNumber),
      });
    }

    for(let i = 0; i < passengers.length; i++) {
      let lapInfant: LapInfant;
      if(passengers[i].lapInfant?.firstName && passengers[i].lapInfant?.lastName) {
        lapInfant = {
          firstName: passengers[i].lapInfant?.firstName,
          lastName: passengers[i].lapInfant?.lastName,
        };
      }

      let loyalty: Loyalty;
      if(passengers[i].loyalty?.airlineCode && passengers[i].loyalty?.tierStatus) {
        loyalty = {
          companyCode: passengers[i].loyalty?.airlineCode,
          tierStatus: mapPassengerLoyaltyToSeatMapRequestLoyalty(passengers[i].loyalty?.tierStatus),
        };
      }

      seatMapRequest.passengers.push({
        id: i,
        firstName: passengers[i].firstName,
        lastName: passengers[i].lastName,
        birthdate: passengers[i].secureFlightInfo?.dateOfBirth,
        lapInfant,
        loyalty,
        referenceId: i,
        specialServiceRequestCodes: passengers[i].ssrs?.filter(ssr => ssr.serviceCode !== 'OTHS').map(ssr => ssr.serviceCode),
        othsSsrSubcategories: passengers[i].ssrs?.filter(ssr => ssr.serviceCode === 'OTHS').map(ssr => ssr.serviceCode),
        isPositiveSpace: true
      } as unknown as RequestPassenger);
    }
    return seatMapRequest;
  }
);

/**
 * Get the request for a static seat map
 */
export const getStaticSeatMapRequest = createSelector(
  getSeatsServiceState,
  (state: SeatsServiceState): SeatMapLookupRequest | null => state?.staticSeatMapRequest
);

/**
 * Get the include Ssrs flag for a static seat map
 */
export const getStaticSeatMapIncludeSsrs = createSelector(
  getSeatsServiceState,
  (state: SeatsServiceState): boolean => state?.staticSeatMapIncludeSsrs ?? false
);
