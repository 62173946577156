import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { BaggageJourneyRequestByConfirmationCode } from '../../../dtos/request/baggage-journey/baggage-journey-request-by-confirmation-code';
import { BaggageJourneyDetails } from '../../../dtos/response/baggage-journey-response/BaggageJourneyDetails';
import { MessageKey } from '../../../models/message/message-key';
import { loadBaggageJourneyByConfirmationCode, resetBaggageJourneyState } from '../../../services/baggage-service/state/baggage-service.actions';
import { getBaggageJourneyError, getCheckedBags, getIsLoadedCheckedBaggage, getShowCheckedBags } from '../../../services/baggage-service/state/baggage-service.selectors';
import { addJetStreamShortMessage, resetJetStreamShortMessages } from '../../../services/message-service/state/message.actions';
import { getPassengers, getRoutedConfirmationCode } from '../../../services/reservation-service/state/reservation-service.selectors';
import { RootState } from '../../../state/state';
import { convertDateToTwoDigitYearDateFormat } from '../../../utils/date-time-util/date-time-util';

const BAG_STATUS_DEFAULT = 'Bag tag created';
const BAG_STATUS_ACTIVE_BUT_NOT_SCANNED = 'Bag tag created, scan dates arriving soon!';
const BAG_STATUS_INACTIVE = 'Checked bag tag is inactive';

@Component({
  selector: 'app-checked-baggage-drawer',
  templateUrl: './checked-baggage-drawer.component.html',
  styleUrls: ['./checked-baggage-drawer.component.scss']
})
export class CheckedBaggageDrawerComponent implements OnInit, OnDestroy {

    private onDestroy$ = new Subject<void>();
    confirmationCode = '';
    lastNames = [];
    checkedBaggage = [];
    showCheckedBags = false;
    isLoading = false;
    showAdvisory = false;

    constructor(private store: Store<RootState>) { }

    ngOnInit(): void {
        this.initializeState();
        this.subscribe();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    subscribe(): void {
        this.store.pipe(select(getShowCheckedBags), takeUntil(this.onDestroy$)).subscribe((showCheckedBags) => {
            this.showCheckedBags = showCheckedBags;
            this.initializeState();
            if (this.showCheckedBags) {
                this.loadData();
            }
        });

        this.store.pipe(select(getRoutedConfirmationCode), takeUntil(this.onDestroy$)).subscribe((confCode) =>
        {
            this.confirmationCode = confCode;
            this.store.pipe(takeUntil(this.onDestroy$), select(getPassengers)).subscribe((passengers) => {
                this.lastNames = Array.from(new Set(passengers.map(p => p.lastName)));
            });
        });

        this.store.pipe(select(getIsLoadedCheckedBaggage), takeUntil(this.onDestroy$)).subscribe((isLoaded) => {
            this.isLoading = !isLoaded;
        });

        this.store.pipe(select(getCheckedBags), takeUntil(this.onDestroy$)).subscribe((results) => {
            if (results && this.checkedBaggage.length === 0) {
                this.mapGuestBagTags(results);
            }
        });

        this.store.pipe(select(getBaggageJourneyError), takeUntil(this.onDestroy$)).subscribe((error) => {
            if (error) {
                this.showAdvisory = true;
                this.store.dispatch(resetJetStreamShortMessages());
                this.store.dispatch(addJetStreamShortMessage(MessageKey.CHECKED_BAGGAGE_GENERIC));
            } else {
                this.showAdvisory = false;
            }
        });
    }

    initializeState(): void {
        this.checkedBaggage = [];
        this.store.dispatch(resetBaggageJourneyState());
    }

    loadData(): void {
        const request: BaggageJourneyRequestByConfirmationCode = {
            confirmationCode: this.confirmationCode,
            lastNames: this.lastNames,
        };
        this.store.dispatch(loadBaggageJourneyByConfirmationCode(request));
    }

    mapGuestBagTags(results: BaggageJourneyDetails[]): void {
        results.forEach(result => {
            const guestFirstName = result?.bookingFirstName;
            const guestLastName = result?.bookingLastName;
            const bagTagNumber = result?.fullBagTagNumber;

            const bagTagActivationStatus = result?.bagTagActivationStatus;
            const scansExist = result?.scans.length > 0;
            const firstCheckedBagDate = result?.scans[0]?.processingDateTimeLocal;
            const bagCheckedStatus = this.getCheckedBagStatus(bagTagActivationStatus, scansExist, firstCheckedBagDate);

            const guestExists = this.checkedBaggage.some(e => e.guestFirstName === guestFirstName && e.guestLastName === guestLastName);

            if (guestExists) {
                const guest = this.checkedBaggage.find(g => g.guestFirstName === guestFirstName && g.guestLastName === guestLastName);
                guest.bags.push({bagTagNumber, bagCheckedStatus});
            } else {
                const newGuestWithBagTag = {guestFirstName, guestLastName, bags: [{bagTagNumber, bagCheckedStatus}]};
                this.checkedBaggage.push(newGuestWithBagTag);
            }
        });
    }

    getCheckedBagStatus(bagTagActivationStatus: string, scansExist: boolean, firstCheckedBagDate: Date): string {
        let bagCheckedStatus = BAG_STATUS_DEFAULT;

        if (bagTagActivationStatus === 'A' && !scansExist) {
            bagCheckedStatus = BAG_STATUS_ACTIVE_BUT_NOT_SCANNED;
        } else if (['I','N','C'].includes(bagTagActivationStatus)) {
            bagCheckedStatus = BAG_STATUS_INACTIVE;
        } else if (scansExist) {
            const checkedDate: Date = new Date(firstCheckedBagDate);
            bagCheckedStatus = 'Checked ' + convertDateToTwoDigitYearDateFormat(checkedDate);
        }

        return bagCheckedStatus;
    }
}
