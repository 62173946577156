import { Action, createReducer, on } from '@ngrx/store';
import { initialPassengerFormsState, lapInfantFormsAdapter, passengerFormsAdapter } from './passenger-forms.state';
import {
  addNewInfant,
  initializeLapInfantForm,
  initializePasengerForm,
  initializePasengerFormsState,
  removeInfant,
  setContactInfoFormData,
  setContactInfoSaved,
  setContactInfoValidity,
  setPassengerFormExpanded,
  setFormSaved,
  setHasPrepopulatedData,
  setInfantIds,
  setLapInfantAssociatedPassengerFormData,
  setLapInfantLastSavedData,
  setPassengerBioFormData,
  setPassengerEmergencyContactFormData,
  setPassengerEmergencyContactSaved,
  setPassengerInternationalDocumentsFormData,
  setPassengerInternationalDocumentsSaved,
  setPassengerKtnRedressFormData,
  setPassengerLastSavedData,
  setPassengerMileagePlanFormData,
  setShowPassengerEmergencyContactForm,
  setShowPassengerInternationalDocumentsForm,
  setLapInfantFormExpanded,
  setGuestContactFormExpanded,
  autoFillPassengerData,
  setAutoFillTrigger,
  setPassengerMileagePlanSaved,
  setLastSavedDataBioForm,
  setLastSavedDataMileagePlanForm,
  setLastSavedDataEmergencyContactForm,
  setLastSavedDataKtnRedressForm,
  setLastSavedDataInternationalDocumentsForm,
  setLastSavedDataLapInfantAssociatedPassenger,
  resetPassengerInternationalDocumentsForm,
  resetPassengerEmergencyContactForm,
  setPassengerContactTracingFormData,
  setShowPassengerContactTracingForm,
  setLastSavedDataContactTracingForm,
  setPassengerContactTracingSaved,
  resetPassengerContactTracingForm,
} from './passenger-forms.actions';

const featureReducer = createReducer(
  initialPassengerFormsState,
  /**
   * Reinitialize the passenger forms state
   */
  on(initializePasengerFormsState, (state) => ({ ...state, ...initialPassengerFormsState })),
  /**
   * Creates a blank entity for a given passenger
   */
  on(initializePasengerForm, (state, { passengerFormData }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.setOne(passengerFormData, state.passengerForms),
    };
  }),
  /**
   * Creates a blank entity for a given lap infant
   */
  on(initializeLapInfantForm, (state, { lapInfantFormData }) => {
    return {
      ...state,
      lapInfantForms: lapInfantFormsAdapter.setOne(lapInfantFormData, state.lapInfantForms),
    };
  }),
  /**
   * Sets the form expanded state for the guest contact form
   */
  on(setGuestContactFormExpanded, (state, { formExpanded }) => {
    return {
      ...state,
      contactInfoForm: {
        ...state.contactInfoForm,
        formExpanded,
      },
    };
  }),
  /**
   * Sets the form expanded state for a given passenger
   */
  on(setPassengerFormExpanded, (state, { passengerHashId, formExpanded }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            formExpanded,
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the form expanded state for a given lap infant
   */
  on(setLapInfantFormExpanded, (state, { passengerHashId, formExpanded }) => {
    return {
      ...state,
      lapInfantForms: lapInfantFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            formExpanded,
          },
        },
        state.lapInfantForms
      ),
    };
  }),
  /**
   * Sets the guest contact info
   */
  on(setContactInfoFormData, (state, { contactInfoForm }) => {
    return {
      ...state,
      contactInfoForm: {
        ...state.contactInfoForm,
        ...contactInfoForm,
      },
    };
  }),
  /**
   * Sets the guest/caller contact information validity
   */
  on(setContactInfoValidity, (state, { formValid }) => ({
    ...state,
    contactInfoForm: {
      ...state.contactInfoForm,
      formValid,
    },
  })),
  /**
   * Sets the contactInfoSaved property
   */
  on(setContactInfoSaved, (state, { formSaved, lastSavedData }) => {
    return {
      ...state,
      contactInfoForm: {
        ...state.contactInfoForm,
        formSaved,
        lastSavedData,
      },
    };
  }),
  on(addNewInfant, (state) => ({ ...state, infantIds: [...state.infantIds, (state.infantIds[state.infantIds.length - 1] ?? 0) + 1] })),
  /**
   * Removes the lap infant form for a given lap infant hashId
   */
  on(removeInfant, (state, { infantHashId }) => {
    const infantIdToRemove = Number(infantHashId?.replace('infant', ''));
    if (!infantHashId || !infantIdToRemove) {
      return state;
    }
    return {
      ...state,
      infantIds: state.infantIds.filter((id) => id !== infantIdToRemove),
      lapInfantForms: lapInfantFormsAdapter.removeOne(infantHashId, state.lapInfantForms),
    };
  }),
  /**
   * Sets the associated passenger form data for a given lap infant
   */
  on(setLapInfantAssociatedPassengerFormData, (state, { lapInfantHashId, passengerHashId, isValid }) => {
    return {
      ...state,
      lapInfantForms: lapInfantFormsAdapter.updateOne(
        {
          id: lapInfantHashId,
          changes: { associatedPassengerHashId: passengerHashId, associatedPassengerHashIdValid: isValid, formSaved: false },
        },
        state.lapInfantForms
      ),
    };
  }),

  /**
   * Sets the mileage plan form data for a given passenger
   */
  on(setPassengerMileagePlanFormData, (state, { passengerHashId, mileagePlanForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          { id: passengerHashId, changes: { mileagePlanForm, formSaved: false } },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        { id: passengerHashId, changes: { mileagePlanForm, formSaved: false } },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the bio form data for a given passenger
   */
  on(setPassengerBioFormData, (state, { passengerHashId, bioForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          { id: passengerHashId, changes: { bioForm, formSaved: false } },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        { id: passengerHashId, changes: { bioForm, formSaved: false } },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the ktn/redress form data for a given passenger
   */
  on(setPassengerKtnRedressFormData, (state, { passengerHashId, ktnRedressForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          { id: passengerHashId, changes: { ktnRedressForm, formSaved: false } },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        { id: passengerHashId, changes: { ktnRedressForm, formSaved: false } },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the emergency contact form data for a given passenger
   */
  on(setPassengerEmergencyContactFormData, (state, { passengerHashId, emergencyContactForm }) => {
    const passengerForm = state.passengerForms.entities[passengerHashId];
    const updatedEmergencyContactForm = {
      ...passengerForm?.emergencyContactForm,
      ...emergencyContactForm,
    };
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        { id: passengerHashId, changes: { emergencyContactForm: updatedEmergencyContactForm, formSaved: false } },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the international documents form data for a given passenger
   */
  on(setPassengerInternationalDocumentsFormData, (state, { passengerHashId, internationalDocumentsForm, isLapInfant }) => {
    const form = isLapInfant ? state.lapInfantForms.entities[passengerHashId] : state.passengerForms.entities[passengerHashId];
    const updatedInternationalDocumentsForm = {
      ...form?.internationalDocumentsForm,
      ...internationalDocumentsForm,
    };
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          { id: passengerHashId, changes: { internationalDocumentsForm: updatedInternationalDocumentsForm, formSaved: false } },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        { id: passengerHashId, changes: { internationalDocumentsForm: updatedInternationalDocumentsForm, formSaved: false } },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the contact tracing form data for a given passenger
   */
  on(setPassengerContactTracingFormData, (state, { passengerHashId, contactTracingForm, isLapInfant }) => {
    const form = isLapInfant ? state.lapInfantForms.entities[passengerHashId] : state.passengerForms.entities[passengerHashId];
    const updatedContactTracingForm = {
      ...form?.contactTracingForm,
      ...contactTracingForm,
    };
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          { id: passengerHashId, changes: { contactTracingForm: updatedContactTracingForm, formSaved: false } },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        { id: passengerHashId, changes: { contactTracingForm: updatedContactTracingForm, formSaved: false } },
        state.passengerForms
      ),
    };
  }),
  on(autoFillPassengerData, (state, { passengerHashId, autoFillData }) => {
    const currentPassengerForm = state.passengerForms.entities[passengerHashId];
    const currentBioForm = currentPassengerForm?.bioForm;
    const currentKtnRedressForm = currentPassengerForm?.ktnRedressForm;
    if(!currentPassengerForm && !autoFillData) {
      return state;
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            bioForm: {
              ...currentBioForm,
              middleName: autoFillData?.middleName != null ? autoFillData.middleName : currentBioForm?.middleName,
              gender: autoFillData?.gender != null ? autoFillData.gender : currentBioForm?.gender,
              dateOfBirth: autoFillData?.dateOfBirth != null ? autoFillData.dateOfBirth : currentBioForm?.dateOfBirth,
            },
            ktnRedressForm: {
              ktn: {
                ...currentKtnRedressForm?.ktn,
                number: autoFillData?.ktnNumber != null
                  ? autoFillData?.ktnNumber : currentKtnRedressForm?.ktn?.number,
                countryCode: autoFillData?.ktnCountryCode != null
                  ? autoFillData?.ktnCountryCode : currentKtnRedressForm?.ktn?.countryCode,
              },
              redress: {
                ...currentKtnRedressForm?.redress,
                number: autoFillData?.redressNumber != null
                  ? autoFillData?.redressNumber : currentKtnRedressForm?.redress?.number,
                countryCode: autoFillData?.redressCountryCode != null
                  ? autoFillData?.redressCountryCode : currentKtnRedressForm?.redress?.countryCode,
              }
            }
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets whether the emergency contact form data for a given passenger should be shown
   */
  on(setShowPassengerEmergencyContactForm, (state, { passengerHashId, showForm }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            emergencyContactForm: {
              showForm,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets whether the international documents form data for a given passenger should be shown
   */
  on(setShowPassengerInternationalDocumentsForm, (state, { passengerHashId, showForm }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            internationalDocumentsForm: {
              showForm,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets whether the contact tracing form data for a given passenger should be shown
   */
  on(setShowPassengerContactTracingForm, (state, { passengerHashId, showForm }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            contactTracingForm: {
              showForm,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets whether the a passenger's emergency contact form has been saved
   */
  on(setPassengerEmergencyContactSaved, (state, { passengerHashId, saved }) => {
    const passengerForm = state.passengerForms.entities[passengerHashId];
    const updatedEmergencyContactForm = {
      ...passengerForm?.emergencyContactForm,
      saved,
      showForm: (saved || passengerForm?.emergencyContactForm?.showForm) ?? false,
    };
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            emergencyContactForm: updatedEmergencyContactForm,
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets whether the a passenger's international documents form has been saved
   */
  on(setPassengerInternationalDocumentsSaved, (state, { passengerHashId, saved }) => {
    const passengerForm = state.passengerForms.entities[passengerHashId];
    const updatedInternationalDocumentsForm = {
      ...passengerForm?.internationalDocumentsForm,
      saved,
      showForm: (saved || passengerForm?.internationalDocumentsForm?.showForm) ?? false,
    };
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            internationalDocumentsForm: updatedInternationalDocumentsForm,
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setPassengerContactTracingSaved, (state, { passengerHashId, saved }) => {
    const passengerForm = state.passengerForms.entities[passengerHashId];
    const updatedContactTracingForm = {
      ...passengerForm?.contactTracingForm,
      saved,
      showForm: (saved || passengerForm?.contactTracingForm?.showForm) ?? false,
    };
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            contactTracingForm: updatedContactTracingForm,
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the last saved data for a given passenger
   */
  on(setPassengerLastSavedData, (state, { passengerHashId, passengerData }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: passengerData,
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets the last saved data for a given lap infant
   */
  on(setLapInfantLastSavedData, (state, { infantHashId, lapInfantData }) => {
    return {
      ...state,
      lapInfantForms: lapInfantFormsAdapter.updateOne(
        {
          id: infantHashId,
          changes: {
            formSaved: true,
            lastSavedData: lapInfantData,
          },
        },
        state.lapInfantForms
      ),
    };
  }),
  /**
   * Sets the hasPrepopulatedData property for a given passenger
   */
  on(setHasPrepopulatedData, (state, { passengerHashId, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              hasPrepopulatedData: true,
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            hasPrepopulatedData: true,
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets whether the a passenger's form has been saved
   */
  on(setFormSaved, (state, { passengerHashId, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              formSaved: true,
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            formSaved: true,
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setAutoFillTrigger, (state, { passengerHashId, autoFill }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            autoFill,
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setPassengerMileagePlanSaved, (state, { passengerHashId, mileagePlanSaved, mileagePlanNumber }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            mileagePlanSaved,
            savedMileagePlanNumber: mileagePlanNumber,
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Sets infant ids
   */
  on(setInfantIds, (state, { infantIds }) => {
    return {
      ...state,
      infantIds,
    };
  }),
  on(setLastSavedDataBioForm, (state, { passengerHashId, bioForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                bio: bioForm,
                passengerHashId,
              },
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              bio: bioForm,
              passengerHashId,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setLastSavedDataMileagePlanForm, (state, { passengerHashId, mpForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                mileagePlan: mpForm,
                passengerHashId,
              },
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              mileagePlan: mpForm,
              passengerHashId,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setLastSavedDataKtnRedressForm, (state, { passengerHashId, ktnRedressForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                ktnRedress: ktnRedressForm,
                passengerHashId,
              },
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              ktnRedress: ktnRedressForm,
              passengerHashId,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setLastSavedDataEmergencyContactForm, (state, { passengerHashId, emergencyContactForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                emergencyContact: emergencyContactForm,
                passengerHashId,
              },
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              emergencyContact: emergencyContactForm,
              passengerHashId,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setLastSavedDataInternationalDocumentsForm, (state, { passengerHashId, internationalDocumentsForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                internationalDocument: internationalDocumentsForm,
                passengerHashId,
              },
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              internationalDocument: internationalDocumentsForm,
              passengerHashId,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setLastSavedDataContactTracingForm, (state, { passengerHashId, contactTracingForm, isLapInfant }) => {
    if (isLapInfant) {
      return {
        ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: passengerHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                contactTracing: contactTracingForm,
                passengerHashId,
              },
            },
          },
          state.lapInfantForms
        ),
      };
    }
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              contactTracing: contactTracingForm,
              passengerHashId,
            },
          },
        },
        state.passengerForms
      ),
    };
  }),
  on(setLastSavedDataLapInfantAssociatedPassenger, (state, { lapInfantHashId, passengerHashId }) => {
    return {
      ...state,
        lapInfantForms: lapInfantFormsAdapter.updateOne(
          {
            id: lapInfantHashId,
            changes: {
              lastSavedData: {
                ...state.lapInfantForms.entities[passengerHashId]?.lastSavedData,
                associatedPassengerHashId: passengerHashId,
                passengerHashId: lapInfantHashId,
              },
            },
          },
          state.lapInfantForms
        ),
    };
  }),
  /**
   * Resets the international documents form data for a given passenger
   */
  on(resetPassengerInternationalDocumentsForm, (state, { passengerHashId }) => {
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            internationalDocumentsForm: {},
            lastSavedData: {
              ...state.passengerForms.entities[passengerHashId]?.lastSavedData,
              passengerHashId,
              internationalDocument: undefined,
            }
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Resets the emergency contact form data for a given passenger
   */
  on(resetPassengerEmergencyContactForm, (state, { passengerHashId }) => {
    const currentPassengerForm = state.passengerForms?.entities[passengerHashId];
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            emergencyContactForm: {},
            lastSavedData: {
              ...currentPassengerForm?.lastSavedData,
              passengerHashId,
              emergencyContact: undefined,
            }
          },
        },
        state.passengerForms
      ),
    };
  }),
  /**
   * Resets the contact tracing form data for a given passenger
   */
  on(resetPassengerContactTracingForm, (state, { passengerHashId }) => {
    const currentPassengerForm = state.passengerForms?.entities[passengerHashId];
    return {
      ...state,
      passengerForms: passengerFormsAdapter.updateOne(
        {
          id: passengerHashId,
          changes: {
            contactTracingForm: {},
            lastSavedData: {
              ...currentPassengerForm?.lastSavedData,
              passengerHashId,
              contactTracing: undefined,
            }
          },
        },
        state.passengerForms
      ),
    };
  }),
);

export function passengerFormsReducer(state = initialPassengerFormsState, action: Action) {
  return featureReducer(state, action);
}
