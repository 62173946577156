<div>
  <alaska-navigation id="nav-menu"  slidebar sticky [contentClass]="contentClass">
    <img src="../../../../assets/alaska-rain-logo-light.svg" slot="logo" alt="Alaska RAIN" (click)="$event.preventDefault()" routerLink="/home"/>
    <alaska-navmenu vertical="true" slot="sidebar">
      <ng-container *ngFor="let menu of menuItems">
        @if (!menu.externalLink && menu.id !== 'book-flights') {
          <alaska-navmenuitem
              bottomIndicator
              [label]="menu.label"
              [id]="menu.id"
              (click)="$event.preventDefault()"
              [routerLink]="menu.link"
              routerLinkActive
              #rla="routerLinkActive"
              #nav
              [selected]="rla.isActive || nav.id === menuId"
              appSpaceOrEnterKeySelector>
            <auro-icon slot="icon" [category]="menu.iconCategory" [name]="menu.icon" customsize style="width: 24px;height: 24px;"></auro-icon>
            <alaska-elitetags *ngIf="menu.isBeta" slot="tag" title="Beta: feature testing in progress" custom size="small" label="Beta" color='{"light": "#E27A05", "dark": "#E38F32"}' outline></alaska-elitetags>
          </alaska-navmenuitem>
        }
        @else if (menu.id === 'classic-shop' && showClassicShopMenuOption) {
          <alaska-navmenuitem
            [id]="menu.id"
            [label]="menu.label"
            (click)="openExternalLink($event, menu.link, menu.linkTarget, menu.id)"
            appSpaceOrEnterKeySelector>
            <auro-icon slot="icon" [category]="menu.iconCategory" [name]="menu.icon" customsize style="width: 24px;height: 24px;"></auro-icon>
          </alaska-navmenuitem>
        }
        @else if (menu.id === 'book-flights' && showBookFlightsMenuOption) {
          <alaska-navmenuitem
              bottomIndicator
              [label]="menu.label"
              [id]="menu.id"
              (click)="navigateToBookFlights($event)"
              [routerLink]="menu.link"
              routerLinkActive
              #rla="routerLinkActive"
              #nav
              [selected]="nav.id === menuId"
              appSpaceOrEnterKeySelector>
            <auro-icon slot="icon" [category]="menu.iconCategory" [name]="menu.icon" customsize style="width: 24px;height: 24px;"></auro-icon>
            <alaska-elitetags *ngIf="menu.isBeta" slot="tag" title="Beta: feature testing in progress" custom size="small" label="Beta" color='{"light": "#E27A05", "dark": "#E38F32"}' outline></alaska-elitetags>
          </alaska-navmenuitem>
        }
      </ng-container>
      <alaska-navmenuitem
        bottomIndicator
        expanded
        expandonmaximize
        label="Tools"
        id="tools">
        <auro-icon slot="icon" category="interface" name="more-stroke" customsize style="width: 24px;height: 24px;"></auro-icon>
          <alaska-navmenuitem
            bottomIndicator
            label="Mileage Plan™ enrollment"
            id="mileage-plan-enrollment"
            (click)="$event.preventDefault()"
            routerLink="/home/mileage-plan"
            routerLinkActive
            #rlamp="routerLinkActive"
            [selected]="rlamp.isActive"
            appSpaceOrEnterKeySelector>
          </alaska-navmenuitem>
          <alaska-navmenuitem
            bottomIndicator
            label="Care-Kit"
            id="guest-compensation"
            (click)="$event.preventDefault()"
            routerLink="/home/care-kit"
            routerLinkActive
            #rlagc="routerLinkActive"
            [selected]="rlagc.isActive"
            appSpaceOrEnterKeySelector>
          </alaska-navmenuitem>
          <alaska-navmenuitem
            label="Dynamic baggage"
            id="baggage-information"
            (click)="openDrawerHelper($event, '#drawerBaggage', 'baggage-information')"
            appSpaceOrEnterKeySelector>
          </alaska-navmenuitem>
          <alaska-navmenuitem
            label="Bag journey"
            id="baggage-journey"
            (click)="$event.preventDefault()"
            routerLink="/home/baggage-journey"
            routerLinkActive
            #rlabj="routerLinkActive"
            [selected]="rlabj.isActive"
            appSpaceOrEnterKeySelector
            *ngIf="showBagJourneyMenuOption">
            <alaska-elitetags slot="tag" title="Beta: feature testing in progress" custom size="small" label="Beta" color='{"light": "#E27A05", "dark": "#E38F32"}' outline></alaska-elitetags>
          </alaska-navmenuitem>
          <alaska-navmenuitem
            bottomIndicator
            label="Feedback"
            id="feedback"
            (click)="$event.preventDefault()"
            routerLink="/home/feedback"
            routerLinkActive
            #rlafd="routerLinkActive"
            [selected]="rlafd.isActive"
            appSpaceOrEnterKeySelector
            [hidden]="agentFeedbackTreatment !== 'on'">
          </alaska-navmenuitem>
      </alaska-navmenuitem>
    </alaska-navmenu>
    <alaska-navmenu horizontal slot="right-menu">
      <alaska-navmenuitem
        id="resource-center-without-label"
        label="resource-center"
        nolabel="true"
        horizontal
        (click)="openDrawerHelper($event, '#resourceCenter', 'resource-center-without-label')"
        appSpaceOrEnterKeySelector>
        <auro-icon slot="icon" category="alert" name="question-stroke" customsize style="width: 24px;height: 24px;"></auro-icon>
      </alaska-navmenuitem>
    <alaska-navmenuitem
        id="profile-without-label"
        [label]="agentName"
        nolabel="true"
        horizontal
        (click)="userProfileClick($event, 'profile-without-label')"
        appSpaceOrEnterKeySelector>
      <auro-icon slot="icon" category="interface" name="profile-stroke" customSize style="width: 24px; height: 24px;"></auro-icon>
    </alaska-navmenuitem>
    <alaska-navmenuitem
        id="logout-without-label"
        label="Log out"
        nolabel="true"
        (click)="logout($event)"
        appSpaceOrEnterKeySelector>
      <img src="../../../../assets/logout.svg" slot="icon" alt="log out" style="margin-left: -3px;"/>
    </alaska-navmenuitem>
  </alaska-navmenu>
  <alaska-navmenu slot="sidebarFooter" vertical="true">
    <div style="display: flex; flex-direction: column; margin: 10px 0px;">
      <alaska-navmenuitem
          id="resource-center-with-label"
          label="Resource center"
          level="0"
          (click)="openDrawerHelper($event, '#resourceCenter', 'resource-center-with-label')"
          appSpaceOrEnterKeySelector>
        <auro-icon slot="icon" category="alert" name="question-stroke" customsize style="width: 24px;height: 24px;"></auro-icon>
      </alaska-navmenuitem>
    </div>
    <alaska-navmenuitem
          id="user"
          [label]="agentName"
          level="0"
          (click)="userProfileClick($event, 'user')"
          [subLabel]="user.email"
          appSpaceOrEnterKeySelector>
      <auro-icon slot="icon" category="interface" name="profile-stroke" customSize style="width: 32px; height:32px;"></auro-icon>
    </alaska-navmenuitem>
    <alaska-navmenuitem
          id="logout-with-label"
          label="Log out"
          level="0"
          (click)="logout($event)"
          appSpaceOrEnterKeySelector>
      <span [inlineSVG]="'../../../../assets/logout.svg'" slot="icon" alt="log out"></span>
    </alaska-navmenuitem>
  </alaska-navmenu>
</alaska-navigation>
</div>
