import { Action, createReducer, on } from '@ngrx/store';
import { Status } from '../../../models/status';
import {
  initializeOsiServiceState,
  addTcpOsi,
  addTcpOsiFullSuccess,
  addTcpOsiFullFailure,
  addTcpOsiPartialSuccess,
  addOsi,
  addOsiComplete,
  deleteOsi,
  deleteOsiComplete,
  addTicketNumberOsi,
  addOptOutOsi,
} from './osi-service.actions';
import { initialOsiServiceState } from './osi-service.state';

const featureReducer = createReducer(
  initialOsiServiceState,
  on(initializeOsiServiceState, (state) => ({ ...state, ...initialOsiServiceState })),
  /**
   * On addOsi, update OSI loading status and the add OSI response
   */
  on(addOsi, (state) => ({
    ...state,
    osiLoadingStatus: Status.UPDATING,
    addOsiResponse: null,
  })),
  /**
   * On addTicketNumberOsi, update OSI loading status and the add OSI response
   */
  on(addTicketNumberOsi, (state) => ({
    ...state,
    osiLoadingStatus: Status.UPDATING,
    addOsiResponse: null,
  })),
  /**
   * On addOptOutOsi, update OSI loading status and the add OSI response
   */
  on(addOptOutOsi, (state) => ({
    ...state,
    osiLoadingStatus: Status.UPDATING,
    addOsiResponse: null,
  })),
  /**
   * On addOsiComplete, update OSI loading status and the add OSI response
   */
  on(addOsiComplete, (state, { addOsiResponse }) => ({
    ...state,
    addOsiResponse,
    osiLoadingStatus: Status.STABLE,
  })),
  /**
   * On deleteOsi, update OSI loading status and the delete OSI response
   */
  on(deleteOsi, (state) => ({
    ...state,
    osiDeletingStatus: Status.UPDATING,
    deleteOsiResponse: null,
  })),
  /**
   * On deleteOsiComplete, update OSI loading status and the delete OSI response
   */
  on(deleteOsiComplete, (state, { deleteOsiResponse }) => ({
    ...state,
    deleteOsiResponse,
    osiDeletingStatus: Status.STABLE,
  })),
  on(addTcpOsi, (state) => ({
    ...state,
    osiLoadingStatus: Status.UPDATING,
  })),
  /**
   * On addOsisFullSuccess, update osi status and the add osi
   */
  on(addTcpOsiFullSuccess, (state, { addOsiSuccess, addOsiStatus }) => ({
    ...state,
    osiLoadingStatus: Status.STABLE,
    tcpOsiStatus: addOsiStatus,
    tcpOsiSuccess: addOsiSuccess,
    tcpOsiFailure: [],
  })),
  /**
   * On addOsisPartialSuccess, update the add osi status and add the success/failure models
   */
  on(addTcpOsiPartialSuccess, (state, { addOsiFailure, addOsiSuccess, addOsiStatus }) => ({
    ...state,
    osiLoadingStatus: Status.STABLE,
    tcpOsiStatus: addOsiStatus,
    tcpOsiSuccess: addOsiSuccess,
    tcpOsiFailure: addOsiFailure,
  })),
  /**
   * On addOsisFullFailure, update the add osi status and add the failed SSRs
   */
  on(addTcpOsiFullFailure, (state, { addOsiStatus, addOsiFailure }) => ({
    ...state,
    osiLoadingStatus: Status.STABLE,
    tcpOsiStatus: addOsiStatus,
    tcpOsiSuccess: [],
    tcpOsiFailure: addOsiFailure,
  }))
);

export function osiServiceReducer(state = initialOsiServiceState, action: Action) {
  return featureReducer(state, action);
}
