import { SsrDetail } from '../../../dtos/request/ssr-requests/ssr-details';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';
import { RemoveSsrStatus } from '../../../dtos/response/ssr-response/remove-ssr-status';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { BatchDeleteSsrResponse } from '../../../dtos/response/ssr-response/batch-delete-ssr-response';

/**
 * The string name of the ssr service "slice" of state
 */
export const ssrServiceFeatureKey = 'ssrService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  ssrService: SsrServiceState;
}

/**
 * The state that is used in relation to ssr service, it is an extension of root state
 * these values are available in and should be accessed from the root state model
 */
export interface SsrServiceState {
  selectedSsrOption?: string;
  ssrFormValid: boolean;
  ssrOthsTeenFormValid: boolean;
  selectFlightsFormValid: boolean;
  selectGuestsFormValid: boolean;
  addSsrStatus: Status;
  addedSsrStatus: AddSsrStatus;
  addSsrsFailure: SsrDetail[];
  addSsrsSuccess: SsrDetail[];
  removeSsrStatus: RemoveSsrStatus;
  removeSsrId?: string;
  batchDeleteSsrsResponse: BatchDeleteSsrResponse;
}

/**
 * Initial state of the ssr service slice of state,
 * used when ssr service state is bootstrapped via the reducer in an app module
 * also used when state is reset
 */
export const initialSsrServiceState: SsrServiceState = {
  selectedSsrOption: undefined,
  ssrFormValid: false,
  ssrOthsTeenFormValid: false,
  selectFlightsFormValid: false,
  selectGuestsFormValid: false,
  addSsrStatus: Status.STABLE,
  addedSsrStatus: AddSsrStatus.UNCAUGHT,
  addSsrsFailure: [],
  addSsrsSuccess: [],
  removeSsrStatus: RemoveSsrStatus.NULL,
  batchDeleteSsrsResponse: {} as BatchDeleteSsrResponse,
};
