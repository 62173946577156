import { createAction } from '@ngrx/store';
import { mileagePlanCompanionServiceFeatureKey } from './mileage-plan-companion-service.state';
import { MileagePlanAddCompanionRequest } from '../../../dtos/request/mileage-plan-companion-request/mileage-plan-add-companion-request';
import { MileagePlanEditCompanionRequest } from '../../../dtos/request/mileage-plan-companion-request/mileage-plan-edit-companion-request';
import { MileagePlanRemoveCompanionRequest } from '../../../dtos/request/mileage-plan-companion-request/mileage-plan-remove-companion-request';
import { MileagePlanCompanionStatus } from '../../../dtos/response/mileage-plan-companion-response/mileage-plan-companion-status';
import { MileagePlanAddCompanionResponse } from '../../../dtos/response/mileage-plan-companion-response/mileage-plan-add-companion-response';
import { MileagePlanEditCompanionResponse } from '../../../dtos/response/mileage-plan-companion-response/mileage-plan-edit-companion-response';
import { MileagePlanRemoveCompanionResponse } from '../../../dtos/response/mileage-plan-companion-response/mileage-plan-remove-companion-response';

export const initializeMileagePlanCompanionServiceState = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Initialize`
);

// Add Companion actions
export const addMileagePlanCompanion = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Add Mileage Plan Companion`,
  (mileagePlanAddCompanionRequest: MileagePlanAddCompanionRequest) => ({
    mileagePlanAddCompanionRequest,
  })
);

export const addMileagePlanCompanionSuccess = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Add Mileage Plan Companion success`,
  (response: MileagePlanAddCompanionResponse) => ({
    response,
  })
);

export const addMileagePlanCompanionFailure = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Add Mileage Plan Companion failure`,
  (error: MileagePlanCompanionStatus) => ({
    error,
  })
);

// Edit Companion actions
export const editMileagePlanCompanion = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Edit Mileage Plan Companion`,
  (mileagePlanEditCompanionRequest: MileagePlanEditCompanionRequest) => ({
    mileagePlanEditCompanionRequest,
  })
);

export const editMileagePlanCompanionSuccess = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Edit Mileage Plan Companion success`,
  (response: MileagePlanEditCompanionResponse) => ({
    response,
  })
);

export const editMileagePlanCompanionFailure = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Edit Mileage Plan Companion failure`,
  (error: MileagePlanCompanionStatus) => ({
    error,
  })
);

// Remove Companion actions
export const removeMileagePlanCompanion = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Remove Mileage Plan Companion`,
  (mileagePlanRemoveCompanionRequest: MileagePlanRemoveCompanionRequest) => ({
    mileagePlanRemoveCompanionRequest,
  })
);

export const removeMileagePlanCompanionSuccess = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Remove Mileage Plan Companion success`,
  (response: MileagePlanRemoveCompanionResponse) => ({
    response,
  })
);

export const removeMileagePlanCompanionFailure = createAction(
  `[${mileagePlanCompanionServiceFeatureKey}] Remove Mileage Plan Companion failure`,
  (error: MileagePlanCompanionStatus) => ({
    error,
  })
);
