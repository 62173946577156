import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PurchaseStatus } from '../../../dtos/response/purchase-response/purchase-status';
import { RootState } from '../../../state/state';
import { getAllOKAndUsedCoupons } from '../../ticket-service/state/ticket-service.selectors';
import { PurchaseService } from '../purchase.service';
import { getPurchaseCaptureContext, purchase, purchaseComplete, setCaptureContext } from './purchase-service.actions';
import { getPurchaseEmail } from './purchase-service.selectors';

@Injectable()
export class PurchaseServiceEffects {
  constructor(private actions$: Actions, private service: PurchaseService, private store: Store<RootState>) {}

  purchase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(purchase),
      withLatestFrom(this.store.pipe(select(getAllOKAndUsedCoupons)), this.store.pipe(select(getPurchaseEmail))),
      mergeMap(([action, coupons, email]) =>
        this.service
          .purchase(
            action.reservation,
            action.totalDue,
            action.sabreId,
            action.paymentInfo,
            action.paymentType,
            email,
            coupons,
            action.filteredSegments
          )
          .pipe(
            map((result) => {
              return result.status === PurchaseStatus.SUCCESS ? purchaseComplete(true, result) : purchaseComplete(false, result);
            })
          )
      ),
      catchError(() => of(purchaseComplete(false, null)))
    )
  );

  getPurchaseCaptureContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPurchaseCaptureContext),
      mergeMap(() =>
        this.service.getCyberSourceCaptureContext().pipe(map((result) => setCaptureContext(result.captureContext, result.success)))
      ),
      catchError(() => of(setCaptureContext('', false)))
    )
  );
}
