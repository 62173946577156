import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { SavePassengerResponseState } from '../../../dtos/response/save-passenger-response/save-passenger-response-state';
import { SaveLapInfantResponseState } from '../../../dtos/response/ssr-response/save-lap-infant-response-state';
import { RemovePassengerDataSlice } from '../../../dtos/request/save-passenger-request/remove-passenger-data-slice';

/**
 * The string name of the slice of state
 */
export const savePassengerFeatureKey = 'savePassenger';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  savePassengerState: SavePassengerState;
}

/**
 * The state that represents the passenger save data
 */
export interface SavePassengerState {
  savePassengerResponseInfos: SavePassengerEntity;
  saveLapInfantResponseInfos: SaveLapInfantEntity;
  removePassengerResponseInfos: RemovePassengerEntity;
  removePassengerInfoType: RemovePassengerDataSlice | null,
  loadStatus: Status;
}

export interface SavePassengerEntity extends EntityState<SavePassengerResponseState> {}
export const savePassengerAdapter: EntityAdapter<SavePassengerResponseState> = createEntityAdapter<SavePassengerResponseState>({
  selectId: (info) => info?.passengerHashId,
});

export interface SaveLapInfantEntity extends EntityState<SaveLapInfantResponseState> {}
export const saveLapInfantAdapter: EntityAdapter<SaveLapInfantResponseState> = createEntityAdapter<SaveLapInfantResponseState>({
  selectId: (info) => info?.infantHashId,
});

export interface RemovePassengerEntity extends EntityState<SavePassengerResponseState> {}
export const removePassengerAdapter: EntityAdapter<SavePassengerResponseState> = createEntityAdapter<SavePassengerResponseState>({
  selectId: (info) => info?.passengerHashId,
});

/**
 * Initial state for the passenger save data
 */
export const initialSavePassengerState: SavePassengerState = {
  savePassengerResponseInfos: savePassengerAdapter.getInitialState({}),
  saveLapInfantResponseInfos: saveLapInfantAdapter.getInitialState({}),
  removePassengerResponseInfos: removePassengerAdapter.getInitialState({}),
  removePassengerInfoType: null,
  loadStatus: Status.STABLE
};
