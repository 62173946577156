// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js'; // Included with Angular CLI.
import { OperatingEnvironment } from '../app/models/operating-environment';
import packageJson from '../../package.json';

export const environment = {
  appInsightsConfig: {
    instrumentationKey: '6762e84e-3dcc-4bf1-8181-65f81dbf42e0',
  },
  isDebugMode: false,
  name: OperatingEnvironment.QA,
  hmr: false,
  production: true,
  clientId: '82d62587-2227-4a15-8645-3156b5b8ed7c',
  provider: 'https://fedauth.qa.alaskasworld.com',
  rainVersion: packageJson.version,
  splitAuthorizationKey: 'hnv1cbrloj3lfg06g94nfevuvms8rshrpjr3', // Points to splitio staging environment
  bluefin: {
    baseUrl: 'https://secure-cert.shieldconex.com',
    reference: '55ff07d2d85e680a265758dd654cddf5'
  },
  appDynamicsKey: 'AD-AAB-ADA-YNH',
  sumoLoggerReciever: 'https://collectors.sumologic.com/receiver/v1/http/ZaVnC4dhaV39qMSJL59zBuPS-1SEhhyJDUNCwinNf-ZftwKLgDKy0x9Nn1rFAW12lAuyggM5An8NwpKLNARW1HZmDW4igJXOsa8gyhVZCwr-lTFqzIQWLA==',
  cyberSource: {
    baseUrl: 'https://testflex.cybersource.com/microform/bundle/v2/flex-microform.min.js',
  }
};
