import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { GuestProfileAutoFillResponseState } from '../../../dtos/response/mileage-plan-profile-lookup-response/guest-profile-auto-fill-response-state';
import { RootState } from '../../../state/state';

/**
 * The string name of the slice of state
 */
export const mileagePlanAutoFillServiceFeatureKey = 'mileagePlanAutoFillService';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  mileagePlanAutoFillServiceState: MileagePlanAutoFillServiceState;
}

/**
 * The state that represents the passenger save data
 */
export interface MileagePlanAutoFillServiceState {
  mileagePlanAutoFillResponse: MileagePlanAutoFillEntity;
}

export interface MileagePlanAutoFillEntity extends EntityState<GuestProfileAutoFillResponseState> {}
export const mileagePlanAutoFillAdapter: EntityAdapter<GuestProfileAutoFillResponseState> =
  createEntityAdapter<GuestProfileAutoFillResponseState>({
    selectId: (response) => response?.passengerHashId,
});

/**
 * Initial state for the passenger save data
 */
export const initialMileagePlanAutoFillServiceState: MileagePlanAutoFillServiceState = {
  mileagePlanAutoFillResponse: mileagePlanAutoFillAdapter.getInitialState({}),
};
