<app-spinner></app-spinner>
<div class="sideNav">
  <app-nav-bar
    [menuItems]="menuItems"
    [agentName]="agentName"
    [user]="user"
    [contentClass]="'.contentBody'"
    [menuId]="menuId"
    (openDrawerEvent)="openDrawerHelper($event)"
    (userProfileClickEvent)="userProfileClick($event)"
    (logoutEvent)="logout()"
  >
  </app-nav-bar>
  <div class="contentBody">
    <app-stacked-long-message-bar [global]="true"></app-stacked-long-message-bar>
    <app-global-search-bar></app-global-search-bar>
    <app-recent-confirmation-codes></app-recent-confirmation-codes>
    <div class="content-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div class="drawer-stack">
  <app-drawer [id]="'drawerBaggage'" [header]="'Baggage information'">
    <app-baggage-drawer></app-baggage-drawer>
  </app-drawer>
  <app-drawer [id]="'resourceCenter'" [header]="'Resource center'">
    <app-resource-center-drawer></app-resource-center-drawer>
  </app-drawer>
  <app-small-drawer [id]="'checkedBaggage'" [header]="'Checked bags'" (drawerClosed)="drawerClosed()">
    <app-checked-baggage-drawer></app-checked-baggage-drawer>
  </app-small-drawer>
</div>

