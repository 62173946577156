import { Action, createReducer, on } from '@ngrx/store';
import { initialMessageState } from './message.state';
import {
  addStackedMessage,
  addStackedMessageFailure,
  addStackedMessageSuccess,
  addStackedMessageNoDuplicate,
  removeStackedLongMessage,
  removeStackedLongMessagesByType,
  removeMessageByKey,
  removeSegmentMessages,
  removeShortMessage,
  removeShortMessagesByType,
  resetStackedLongMessages,
  resetShortMessages,
  setCurrentStackedLongMessageIndex,
  setCurrentShortMessageIndex,
  addMessage,
  addMessageSuccess,
  addMessageFailure,
  resetLongMessage,
  resetJetStreamMessagesForPassenger,
  addJetStreamMessageComplete,
  addJetStreamShortMessageComplete,
  resetJetStreamShortMessages,
  addGlobalMessage,
  addGlobalMessageFailure,
  addGlobalMessageSuccess,
  setCurrentGlobalMessageIndex,
  removeGlobalMessage,
  addGlobalJetStreamMessageComplete,
  resetJetStreamMessagesForNonFiltered,
  removeGlobalJetStreamMessage,
  removeMessagesByKeys,
  resetCurrentStackedLongMessageIndex,
  resetGlobalJetStreamMessages,
} from './message.actions';
import { MessageStatus } from '../../../models/message/message-status';
import { MessageStyle } from '../../../models/message/message-style';
import { MessageKey } from '../../../models/message/message-key';
import { RichMessage } from '../../../models/message/rich-message';
import { NameNumberUtil } from '../../../utils/name-number-util';

const featureReducer = createReducer(
  initialMessageState,
  on(addGlobalMessage, (state, { key, messageArgs, messageFilters }) => ({
    ...state,
    status: MessageStatus.LOADING
  })),
  on(addStackedMessage, (state, { key, messageArgs, messageFilters }) => ({
    ...state,
    status: MessageStatus.LOADING
  })),
  on(addMessage, (state, { key, messageArgs }) => ({
    ...state,
    status: MessageStatus.LOADING
  })),
  on(addStackedMessageNoDuplicate, (state, { key, messageArgs, messageFilters }) => {
    const isDuplicateMessage = state?.stackedLongMessages.some((otherMessage: RichMessage) =>
      isMatching(otherMessage, key, messageFilters?.segmentIndex, messageFilters?.passengerId));
    if (isDuplicateMessage) {
      return {
        ...state,
        status: MessageStatus.DUPLICATE
      };
    }

    return {
      ...state,
      status: MessageStatus.LOADING
    };
  }),
  on(addGlobalMessageSuccess, (state, { message }) => {
    // Remove duplicates
    const concatMessages = [...state.globalMessages.concat(message)];
    const uniqueList = concatMessages.filter((obj, index, self) => {
      return (
        index === self.findIndex(
          (o) => o.emphasisText === obj.emphasisText && o.mainText === obj.mainText
        )
      );
    });
    // Only add messages if they don't already exist
    state = {
      ...state,
      globalMessages: uniqueList,
      status: MessageStatus.SUCCESS
    };
    return state;
  }),
  on(addGlobalMessageFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(addStackedMessageSuccess, (state, { message }) => {
    switch (message.style) {
      case MessageStyle.LONG:
        state = {
          ...state,
          stackedLongMessages: [...state.stackedLongMessages, message],
          status: MessageStatus.SUCCESS
        };
        break;
      case MessageStyle.SHORT:
        state = {
          ...state,
          shortMessages: [...state.stackedLongMessages, message],
          status: MessageStatus.SUCCESS
        };
        break;
      default:
        state = {
          ...state,
          status: MessageStatus.SUCCESS
        };
    }
    return state;
  }),
  on(addStackedMessageFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(addGlobalJetStreamMessageComplete, (state, { messages }) => {
    // Remove duplicates based on key
    const uniqueMessages = removeDuplicatesByKey([...state.globalJetStreamMessages.concat(messages)]);
    state = {
      ...state,
      globalJetStreamMessages: uniqueMessages,
      status: MessageStatus.SUCCESS
    };
    return state;
  }),
  on(removeGlobalJetStreamMessage, (state, { key }) => {
    state = {
      ...state,
      globalJetStreamMessages: state.globalJetStreamMessages.filter((msg) => msg.key !== key),
      status: MessageStatus.SUCCESS
    };
    return state;
  }),
  on(addJetStreamMessageComplete, (state, { messages }) => {
    state = {
      ...state,
      jetStreamMessages: [...state.jetStreamMessages.concat(messages)],
      status: MessageStatus.SUCCESS
    };
    return state;
  }),
  on(addJetStreamShortMessageComplete, (state, { messages }) => {
    // Remove duplicates
    const concatMessages = [...state.jetStreamShortMessages.concat(messages)];
    const uniqueList = concatMessages.filter((obj, index, self) => {
      return (
        index === self.findIndex(
          (o) => o.emphasisText === obj.emphasisText && o.mainText === obj.mainText
        )
      );
    });
    // Only add messages if they don't already exist
    state = {
      ...state,
      jetStreamShortMessages: uniqueList,
      status: MessageStatus.SUCCESS
    };
    return state;
  }),
  on(addMessageSuccess, (state, { message }) => ({
    ...state,
    longMessage: message,
    status: MessageStatus.SUCCESS
  })),
  on(addMessageFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(setCurrentGlobalMessageIndex, (state, { index }) => ({
    ...state,
    currentGlobalMessageIndex: index > state.globalMessages.length - 1 ? state.globalMessages.length - 1 : index
  })),
  on(setCurrentStackedLongMessageIndex, (state, { index }) => ({
    ...state,
    currentLongMessageIndex: index > state.stackedLongMessages.length - 1 ? state.stackedLongMessages.length - 1 : index
  })),
  on(setCurrentShortMessageIndex, (state, { index }) => ({
    ...state,
    currentShortMessageIndex: index > state.shortMessages.length - 1 ? state.shortMessages.length - 1 : index
  })),
  on(resetStackedLongMessages, (state) => ({
    ...state,
    stackedLongMessages: [],
    error: null,
    status: MessageStatus.STABLE
  })),
  on(resetCurrentStackedLongMessageIndex, (state) => ({
    ...state,
    currentLongMessageIndex: 0
  })),
  on(resetShortMessages, (state) => ({
    ...state,
    shortMessages: []
  })),
  on(resetLongMessage, (state) => ({
    ...state,
    longMessage: null,
    error: null,
    status: MessageStatus.STABLE
  })),
  on(resetJetStreamMessagesForPassenger, (state, { passengerId }) => {
    const updatedMessages = state.jetStreamMessages.filter((msg) => {
      if (!msg.passengerId || (msg.passengerId &&
        NameNumberUtil.ToNonZeroPaddedCollapseDecimal(msg.passengerId) !==
        NameNumberUtil.ToNonZeroPaddedCollapseDecimal(passengerId))) {
        return true;
      }
      return false;
    });
    return {
      ...state,
      jetStreamMessages: updatedMessages,
    };
  }),
  on(resetJetStreamMessagesForNonFiltered, (state) => {
    const updatedMessages = state.jetStreamMessages.filter((msg) => {
      if (!msg.passengerId) {
        return false;
      }
      return true;
    });
    return {
      ...state,
      jetStreamMessages: updatedMessages,
    };
  }),
  on(resetJetStreamShortMessages, (state) => ({
    ...state,
    jetStreamShortMessages: [],
  })),
  on(resetGlobalJetStreamMessages, (state) => ({
    ...state,
    globalJetStreamMessages: [],
  })),
  on(removeGlobalMessage, (state, { message }) => {
    const messages = state.globalMessages.filter((msg) => msg !== message);
    const currentMessageIndex = state.currentGlobalMessageIndex < messages.length
      ? state.currentGlobalMessageIndex : 0;
    return {
      ...state,
      globalMessages: messages,
      currentGlobalMessageIndex: currentMessageIndex
    };
  }),
  on(removeStackedLongMessage, (state, { message }) => {
    const stackedLongMessages = state.stackedLongMessages.filter((msg) => msg !== message);
    const currentLongMessageIndex = state.currentLongMessageIndex < stackedLongMessages.length
      ? state.currentLongMessageIndex : 0;
    return {
      ...state,
      stackedLongMessages,
      currentLongMessageIndex
    };
  }),
  on(removeShortMessage, (state, { message }) => {
    const shortMessages = state.shortMessages.filter((msg) => msg !== message);
    const currentShortMessageIndex = state.currentShortMessageIndex < shortMessages.length
      ? state.currentShortMessageIndex : 0;
    return {
      ...state,
      shortMessages,
      currentShortMessageIndex
    };
  }),
  on(removeStackedLongMessagesByType, (state, { messageType }) => ({
    ...state,
    stackedLongMessages: state.stackedLongMessages.filter((msg) => msg.type !== messageType)
  })),
  on(removeShortMessagesByType, (state, { messageType }) => ({
    ...state,
    shortMessages: state.shortMessages.filter((msg) => msg.type !== messageType)
  })),
  on(removeMessageByKey, (state, { messageKey, segmentIndex, passengerId }) => {
    return {
      ...state,
      stackedLongMessages: state.stackedLongMessages.filter(
        (message: RichMessage) => !isMatching(message, messageKey, segmentIndex, passengerId)
      ),
    };
  }),
  on(removeMessagesByKeys, (state, { messageKeys, segmentIndex, passengerId }) => {
    messageKeys.forEach(key => {
      state = {
        ...state,
        stackedLongMessages: state.stackedLongMessages.filter(
          (message: RichMessage) => !isMatching(message, key, segmentIndex, passengerId)
        ),
      };
    });
    return state;
  }),
  on(removeSegmentMessages, (state, { segmentIndex }) => {
    return {
      ...state,
      stackedLongMessages: state.stackedLongMessages.filter((message: RichMessage) => message.segmentIndex !== segmentIndex),
    };
  }),
);

export function messageReducer(state = initialMessageState, action: Action) {
  return featureReducer(state, action);
}

/**
 * Returns true if this message matches the key, segment and passengerId
 */
function isMatching(message: RichMessage, messageKey: MessageKey, segmentIndex?: number, passengerId?: string): boolean {
  const keyMatch = messageKey === message.key;
  const segmentIndexMatch = segmentIndex === message.segmentIndex;
  const passengerIdMatch = passengerId === message.passengerId;

  return keyMatch && segmentIndexMatch && passengerIdMatch;
}

/**
 * Returns a list of messages without duplicates based on the message key
 */
function removeDuplicatesByKey(messages: RichMessage[]): RichMessage[] {
  return [...new Map(messages.map(msg => [msg.key, msg])).values()];
}
