import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SeatsService } from '../../../services/seat-service/seats.service';
import { RootState } from '../../../state/state';
import { seatMapLookup, seatMapLookupComplete } from './seat-map-v2.actions';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { getCurrentSeatMapSegment, getCurrentSegmentHash } from '../../seat-map/state/seat-map.selectors';
import { setCurrentSegmentHashId } from '../../seat-map/state/seat-map.actions';
import { SeatMapLookupStatus } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-status';

@Injectable()
export class SeatMapEffects {
  constructor(private actions$: Actions, private seatsService: SeatsService, private store: Store<RootState>) {}

  /**
   * Gets a seatmap for a flight on a reservation
   * Handles pre-reserved seats, passenger data, etc in post processing
   */
  seatMapLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(seatMapLookup),
      withLatestFrom(
        this.store.select(getCurrentSegmentHash),
        this.store.select(getCurrentSeatMapSegment),
      ),
      mergeMap(([action, currentSegmentHashId, currentSeatMapSegment]) => {

        if (!currentSegmentHashId) {
          this.store.dispatch(setCurrentSegmentHashId(currentSeatMapSegment?.hashId || null));
        }

        return this.seatsService.seatMapLookupV2(action.seatMapLookupRequest, true).pipe(
          map((result) => {
            return seatMapLookupComplete(result);
          }),
          /**
           * When unknown errors happen from calling the service, instead of catching the error
           * inside outer stream (e.g., mergeMap, exhaustMap), catch error on inner stream, to keep the corresponding effect alive.
           * See NgRx effects error handling section for more details: https://v7.ngrx.io/guide/effects
           */
          catchError(() => {
            return of(
              seatMapLookupComplete({
                success: false,
                status: SeatMapLookupStatus.SYSTEM_FAILURE,
              })
            );
          })
        );
      })
    )
  );
}
