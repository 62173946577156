import { SelectMultipleGuestsPetForm } from '../../../models/forms/ssr-pet-details/select-multiple-guests-pet-form';
import { FormKey } from '../../../models/forms/form-key';
import { OthsTeenForm } from '../../../models/forms/oths-teen.form';
import { SelectFlightsForm } from '../../../models/forms/select-flights-form';
import { SelectGuestsForm } from '../../../models/forms/select-guests-form';
import { WchrForm } from '../../../models/forms/wchr-form';
import { RootState } from '../../../state/state';
import { SelectGuestsPetForm } from '../../../models/forms/ssr-pet-details/select-guests-pet-form';

export const formsFeatureKey = 'forms';

export interface State extends RootState {
  forms: FormsState;
}

// Each form requires a key that is defined in the FormKey enum
// and a model that represents the values the form will emit when you call formGroup.value
export interface FormsState {
  [FormKey.wchrForm]?: WchrForm;
  [FormKey.selectFlightsForm]: SelectFlightsForm;
  [FormKey.selectGuestsForm]: SelectGuestsForm;
  [FormKey.othsTeenForm]: OthsTeenForm;
  [FormKey.selectGuestsPetForm]: SelectGuestsPetForm;
  [FormKey.selectMultipleGuestsPetForm]: SelectMultipleGuestsPetForm;
}

export const initialFormsState: FormsState = {
  [FormKey.wchrForm]: undefined,
  [FormKey.selectFlightsForm]: { selectAllCheckbox: false, segments: [] },
  [FormKey.selectGuestsForm]: { selectAllCheckbox: false, passengerRows: [] },
  [FormKey.othsTeenForm]: { assistRequested: undefined },
  [FormKey.selectGuestsPetForm]: {
    selectAllCheckbox: false,
    passengerRows: [],
    intraAlaskaOrMilitaryCheckbox: false,
  },
  [FormKey.selectMultipleGuestsPetForm]: {
    selectAllCheckbox: false,
    passengerRows: [],
  },
};
