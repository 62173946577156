import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { AdvisoriesService } from '../advisories-services';
import { loadAdvisories, loadAdvisoriesComplete } from './advisories-service.actions';
import { AdvisoriesLookupStatus } from '../../../dtos/response/advisories-response/advisories-lookup-status';

@Injectable()
export class AdvisoriesServiceEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private advisoriesService: AdvisoriesService) {}

  loadAdvisories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAdvisories),
      mergeMap((action) => {
        return this.advisoriesService
          .getAdvisories(action.advisoryPageType, action.advisoryPageFilterTag)
          .pipe(map((advisoriesResponse) => loadAdvisoriesComplete(advisoriesResponse)));
      }),
      catchError(() => {
        return of(loadAdvisoriesComplete({lookupStatus: AdvisoriesLookupStatus.SYSTEM_FAILURE, advisories: null }));
      })
    )
  );
}
