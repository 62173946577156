/**
 * Contains FormsState keys that correspond stored form values
 */
export enum FormKey {
  wchrForm = 'wchrForm',
  selectFlightsForm = 'selectFlightsForm',
  selectGuestsForm = 'selectGuestsForm',
  othsTeenForm = 'othsTeenForm',
  blndForm = 'blndForm',
  toCompletePartyForm = 'toCompletePartyForm',
  selectGuestsPetForm = 'selectGuestPetDetailsForm',
  selectMultipleGuestsPetForm = 'selectMultipleGuestPetDetailsForm',
  selectedRemarkForm = 'selectedRemarkForm',
  selectedOsiForm = 'selectedOsiForm',
}
