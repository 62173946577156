import { Action, createReducer, on } from '@ngrx/store';
import { initializeFlightServiceState, loadFlightStatus, loadFlightStatusComplete } from './flight-service.actions';
import { flightStatusAdapter, initialFlightServiceState } from './flight-service.state';
import { Status } from '../../../models/status';

const featureReducer = createReducer(
  initialFlightServiceState,
  on(loadFlightStatusComplete, (state, { flightStatusResponse }) => {
    return {
      ...state,
      flightStatus: flightStatusAdapter.addOne(flightStatusResponse, state.flightStatus),
      status: Status.STABLE,
    };
  }),
  on(loadFlightStatus, (state) => ({ ...state, status: Status.LOADING })),
  on(initializeFlightServiceState, (state) => ({ ...state, ...initialFlightServiceState }))
);

export function flightServiceReducer(state = initialFlightServiceState, action: Action) {
  return featureReducer(state, action);
}
