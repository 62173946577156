import { Action, createReducer, on } from '@ngrx/store';
import { contactInformationFormsAdapter, initialContactInformationFormsState } from './contact-information-forms.state';
import {
  addContactInformationFormData,
  initializeContactInformationFormsState,
  updateContactInformationFormEmailData,
  updateContactInformationFormPhoneData,
} from './contact-information-forms.actions';

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialContactInformationFormsState,
  /**
   * Reinitialize the contact information forms state
   */
  on(initializeContactInformationFormsState, (state) => ({ ...state, ...initialContactInformationFormsState })),
  /**
   * Add/update a contact information form data
   */
  on(addContactInformationFormData, (state, { featureId, contactInformationForm }) => {
    return {
      ...state,
      contactInformationForms: contactInformationFormsAdapter.setOne(contactInformationForm, state.contactInformationForms),
    };
  }),
  /**
   * Update a contact information form phone data
   */
  on(updateContactInformationFormPhoneData, (state, { featureId, phoneData, index }) => {
    const existingContactInformationForm = state.contactInformationForms.entities[featureId];
    const updatedPhoneForms = existingContactInformationForm.phoneForms?.map((phoneForm, i) => (i === index ? phoneData : phoneForm));
    const updatedContactInformationForm = {
      ...existingContactInformationForm,
      phoneForms: updatedPhoneForms,
    };
    return {
      ...state,
      contactInformationForms: contactInformationFormsAdapter.setOne(updatedContactInformationForm, state.contactInformationForms),
    };
  }),
  /**
   * Update a contact information form email data
   */
  on(updateContactInformationFormEmailData, (state, { featureId, emailData, index }) => {
    const existingContactInformationForm = state.contactInformationForms.entities[featureId];
    const updatedEmailForms = existingContactInformationForm.emailForms?.map((emailForm, i) => (i === index ? emailData : emailForm));
    const updatedContactInformationForm = {
      ...existingContactInformationForm,
      emailForms: updatedEmailForms,
    };
    return {
      ...state,
      contactInformationForms: contactInformationFormsAdapter.setOne(updatedContactInformationForm, state.contactInformationForms),
    };
  })
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function contactInformationFormsReducer(state = initialContactInformationFormsState, action: Action) {
  return featureReducer(state, action);
}
