import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteNestedParam } from '../selectors';
import { mileagePlanProfileSearchFeatureKey, MileagePlanProfileSearchState } from './mileage-plan-profile-search.state';

export const getMileagePlanProfileSearchState = createFeatureSelector<MileagePlanProfileSearchState>(mileagePlanProfileSearchFeatureKey);

export const getMileagePlanProfileSearchMileagePlanNumber = createSelector(
  getMileagePlanProfileSearchState,
  (state): string | null => state.mileagePlanNumber
);


/**
 * Gets the mileage plan number found in the route
 */
 export const getRoutedMileagePlanNumber = createSelector(
  selectRouteNestedParam('mileagePlanNumber'),
  (mileagePlanNumber: string): string => {
    return mileagePlanNumber;
  }
);
