import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OsiService } from '../osi-service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  addOptOutOsi,
  addOsi,
  addOsiComplete,
  addTcpOsi,
  addTcpOsiFullFailure,
  addTcpOsiFullSuccess,
  addTcpOsiPartialSuccess,
  addTicketNumberOsi,
  deleteOsi,
  deleteOsiComplete,
} from './osi-service.actions';
import { OsiStatus } from '../../../dtos/response/osi-response/osi-status';
import { of } from 'rxjs';

@Injectable()
export class OsiServiceEffects {
  constructor(private actions$: Actions, private osiService: OsiService) {}

  addOsi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOsi),
      mergeMap((action) => {
        return this.osiService.addOsi(action.addOsiRequest).pipe(
          map((result) => {
            return addOsiComplete(result);
          }),
          catchError(() =>
            of(addOsiComplete({ status: OsiStatus.SYSTEM_FAILURE, id: null, actionResult: { code: '500', messages: ['Request failed'] } }))
          )
        );
      })
    )
  );

  addTicketNumberOsi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTicketNumberOsi),
      mergeMap((action) => {
        return this.osiService.addTicketNumberOsi(action.addTicketNumberOsiRequest).pipe(
          map((result) => {
            return addOsiComplete(result);
          }),
          catchError(() =>
            of(addOsiComplete({ status: OsiStatus.SYSTEM_FAILURE, id: null, actionResult: { code: '500', messages: ['Request failed'] } }))
          )
        );
      })
    )
  );

  addOptOutOsi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOptOutOsi),
      mergeMap((action) => {
        return this.osiService.addOptOutOsi(action.request).pipe(
          map((result) => {
            return addOsiComplete(result);
          }),
          catchError(() =>
            of(addOsiComplete({ status: OsiStatus.SYSTEM_FAILURE, id: null, actionResult: { code: '500', messages: ['Request failed'] } }))
          )
        );
      })
    )
  );

  deleteOsi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOsi),
      mergeMap((action) =>
        this.osiService.deleteOsi(action.deleteOsiRequest).pipe(
          map((result) => {
            return deleteOsiComplete(result);
          })
        )
      ),
      catchError(() => of(deleteOsiComplete({ status: OsiStatus.SYSTEM_FAILURE, successOsis: [], failureOsis: [] })))
    )
  );

  addTcpOsi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTcpOsi),
      mergeMap((action) =>
        this.osiService.addTcpOsi(action.addTcpRequest).pipe(
          map((result) => {
            if (result.status === OsiStatus.SUCCESS) {
              return addTcpOsiFullSuccess(result.successfullyAddedTcps, OsiStatus.SUCCESS);
            } else if (result.status === OsiStatus.PARTIAL_SUCCESS) {
              return addTcpOsiPartialSuccess(result.failedToAddTcps, result.successfullyAddedTcps, OsiStatus.PARTIAL_SUCCESS);
            } else {
              return addTcpOsiFullFailure(result.failedToAddTcps, OsiStatus.SYSTEM_FAILURE);
            }
          })
        )
      ),
      catchError(() => of(addTcpOsiFullFailure([], OsiStatus.UNCAUGHT)))
    )
  );
}
