import { Injectable } from '@angular/core';
import { Seat } from '../../models/seat-map/seat';
import { SeatCode } from '../../models/seat-map/seat-code';
import { SeatProperty } from '../../models/seat-map/seat-property';

@Injectable({
  providedIn: 'root',
})
export class SeatMapSeatUtil {
  /**
   * Returns true is the seat is on the seat map but is broken
   */
  isInoperable(seatData: Seat): boolean {
    return this.hasProperty(SeatProperty.Inoperative, seatData);
  }

  /**
   * Returns true is seat is a "windowless seat"
   */
  isWindowlessWindowSeat(seatData: Seat): boolean {
    return this.hasProperty(SeatProperty.Windowless, seatData);
  }

  /**
   * Checks if the seat is flagged as a premium class seat
   */
  isPremiumClass(seatData: Seat): boolean {
    return this.hasProperty(SeatProperty.PremiumClass, seatData) || this.hasProperty(SeatProperty.OtherAirlinePremium, seatData);
  }

  /**
   * Checks if a seat has a given property
   */
  hasProperty(property: SeatProperty, seat: Seat): boolean {
    if (!seat.properties && !seat.primaryProperty) {
      return false;
    }

    return seat.properties?.includes(property) || seat.primaryProperty === property;
  }

  /**
   * If the seat has an upsell price
   */
  hasUpsellPrice(seat: Seat): boolean {
    return seat.upsellPrice !== undefined && seat.upsellPrice !== null;
  }

  /**
   * Returns a seat code based on property
   */
  mapSeatPropertyToCode(property: SeatProperty): string {
    switch (property) {
      case SeatProperty.Inoperative:
        return SeatCode.INOPERATIVE;
      case SeatProperty.UnaccompaniedMinorRecommended:
        return SeatCode.UNACCOMPANIED_MINOR;
      case SeatProperty.AirportAssignOnly:
        return SeatCode.AIRPORT_CONTROL;
      case SeatProperty.PremiumClass:
      case SeatProperty.PreferredPlus:
      case SeatProperty.ExitRowSeat:
      case SeatProperty.OtherAirlinePremium:
        return SeatCode.PREMIUM;
      case SeatProperty.Accessible:
        return SeatCode.DISABLED;
      case SeatProperty.Preferential:
      case SeatProperty.MainPreferred:
        return SeatCode.PREFERRED;
      case SeatProperty.BlockedForInfant:
        return SeatCode.INFANT_BLOCKED;
      case SeatProperty.ZoneRestricted:
        return SeatCode.ZONE_RESTRICTED;
      case SeatProperty.RestrictedRecline:
        return SeatCode.UNDESIRABLE;
      default:
        return '';
    }
  }

  /**
   * Returns a seat indicator based on seat properties
   */
  public seatPrimaryPropertyMap = new Map<SeatProperty, string>([
    [SeatProperty.Accessible, 'H'],
    [SeatProperty.AirportAssignOnly, 'Z'],
    [SeatProperty.BlockedForInfant, 'I'],
    [SeatProperty.Blocked, '"/'],
    [SeatProperty.Bulkhead, 'B'],
    [SeatProperty.ExitRowSeat, 'Q'],
    [SeatProperty.Galley, 'U'],
    [SeatProperty.Inoperative, 'N'],
    [SeatProperty.Lavatory, 'U'],
    [SeatProperty.NoInfant, 'M'],
    [SeatProperty.NoSeat, 'O'],
    [SeatProperty.OtherAirlinePremium, 'P'],
    [SeatProperty.MainPreferred, 'Q'],
    [SeatProperty.Preferential, 'Q'],
    [SeatProperty.PreferredPlus, 'P'],
    [SeatProperty.PremiumClass, 'P'],
    [SeatProperty.RestrictedRecline, 'U'],
    [SeatProperty.UnaccompaniedMinorRecommended, 'L'],
    [SeatProperty.Unknown, 'M'],
    [SeatProperty.Windowless, 'U'],
    [SeatProperty.ZoneRestricted, 'Z'],
    [SeatProperty.Main, 'M'],
    [SeatProperty.Saver, 'D'],
    [SeatProperty.Business, 'C'],
  ]);

  /**
   * Sorts an array of seat properties based on predefined priorities using a Map.
   * @param seatProperties An array of seat properties to be sorted.
   * @returns A new array sorted by the seat properties' priorities.
   */
  public sortSeatProperties(seatProperties: SeatProperty[]) {
    const priorityMap = new Map<SeatProperty, number>([
      [SeatProperty.Accessible, 1],
      [SeatProperty.AirportAssignOnly, 2],
      [SeatProperty.BlockedForInfant, 3],
      [SeatProperty.Blocked, 4],
      [SeatProperty.Bulkhead, 5],
      [SeatProperty.ExitRowSeat, 6],
      [SeatProperty.Galley, 7],
      [SeatProperty.Inoperative, 8],
      [SeatProperty.Lavatory, 9],
      [SeatProperty.NoInfant, 10],
      [SeatProperty.NoSeat, 11],
      [SeatProperty.OtherAirlinePremium, 12],
      [SeatProperty.MainPreferred, 13],
      [SeatProperty.Preferential, 14],
      [SeatProperty.PreferredPlus, 15],
      [SeatProperty.PremiumClass, 16],
      [SeatProperty.RestrictedRecline, 17],
      [SeatProperty.UnaccompaniedMinorRecommended, 18],
      [SeatProperty.Unknown, 19],
      [SeatProperty.Windowless, 20],
      [SeatProperty.ZoneRestricted, 21],
      [SeatProperty.Main, 22],
      [SeatProperty.Saver, 23],
      [SeatProperty.Business, 24],
    ]);

    seatProperties?.sort((a, b) => {
      const priorityA = priorityMap.get(a) ?? Number.MAX_SAFE_INTEGER;
      const priorityB = priorityMap.get(b) ?? Number.MAX_SAFE_INTEGER;

      return priorityA - priorityB;
    });
  }

  /**
   * Seat to check if it is a no seat
   */
  public isNoSeat = (seat: Seat) => {
    return seat?.properties?.includes(SeatProperty.NoSeat) || false;
  };
}
