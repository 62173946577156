import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PreorderMealServiceState, preorderMealServiceFeatureKey } from './preorder-meal-service.state';
import { PreOrderMealResponse } from '../../../dtos/response/pre-order-meal-response/pre-order-meal-response';
import { PreOrderMealSummary } from '../../../dtos/response/pre-order-meal-response/pre-order-meal-summary';
import { getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, getPassengers } from '../../reservation-service/state/reservation-service.selectors';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { PreOrderMealSegmentDetail } from '../../../dtos/request/pre-oreder-meal-request/pre-order-meal-request-segment-detail';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';

const getPreorderMealServiceState = createFeatureSelector<PreorderMealServiceState>(preorderMealServiceFeatureKey);

export const getAllPreorderMeals = createSelector(getPreorderMealServiceState, (state) => state.preOrderMeals);

export const getPreorderMealsRequestSegmentDetails = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (allSegments: Segment[]): PreOrderMealSegmentDetail[] => {
    const segmentDetails: PreOrderMealSegmentDetail[] = [];
    allSegments?.forEach((segment: Segment) =>
      segmentDetails.push({
        segmentHashId: segment.hashId,
        flightNumber: segment.operatingAirlineFlightNumber,
        departureDate: segment.departureDateTime,
        origin: segment.departureAirport,
        destination: segment.arrivalAirport,
        segmentId: segment.segmentId,
        segmentSequence: segment.sequence
    }));
    return segmentDetails;
  }
);

export const getGuestPreOrders = (guestFirstName: string, guestLastName: string) =>
  createSelector(
    getAllPreorderMeals,
    (preOrderMeals) =>
      preOrderMeals?.preOrdersByRecordLocator?.preOrdersByRecordLocator.flatMap((preOrder) => {
        return preOrder.preOrders.flatMap((order) => {
          const guest = order.guests.find(
            (g) => g.firstName.toUpperCase() === guestFirstName.toUpperCase() && g.lastName.toUpperCase() === guestLastName.toUpperCase()
          );
          return guest ? [{ ...order, guests: [guest] }] : [];
        });
      }) ?? []
  );

export const getMealOrdersByPassenger = createSelector(
  getAllPreorderMeals,
  getPassengers,
  (preOrderMealResponse: PreOrderMealResponse, passengers: Passenger[]): PreOrderMealSummary[] => {
    const preOrderMealSummaries: PreOrderMealSummary[] = [];
    preOrderMealResponse?.preOrdersByRecordLocator?.preOrdersByRecordLocator.forEach((element) => {
      element.preOrders?.forEach((preOrder) => {
        preOrder.guests?.forEach((guest) => {
          const isPreorderMealGuestAPassenger = passengers?.find(
            (passenger) =>
                  passenger.firstName.toUpperCase() === guest.firstName.toUpperCase()
               && passenger.lastName.toUpperCase() === guest.lastName.toUpperCase()
          );
          if(isPreorderMealGuestAPassenger)
          {
            let total = 0;
            if (guest.orderedItems?.length > 0) {
              guest.orderedItems?.forEach((orderedItem) => {
                total = total + orderedItem.discountedPrice;
              });
              const preOrderMealSummary: PreOrderMealSummary = {
                flightNumber: preOrder.operatingFlightNumber,
                departure: preOrder.scheduledDepartureStationCode,
                arrival: preOrder.scheduledArrivalStationCode,
                firstName: guest.firstName,
                lastName: guest.lastName,
                price: total.toString(),
              };
              preOrderMealSummaries.push(preOrderMealSummary);
            }
          }
        });
      });
    });
    return preOrderMealSummaries;
  }
);

export const getTotalMealPreOrderCount = createSelector(
  getAllPreorderMeals,
  getPassengers,
  (preOrderMealResponse: PreOrderMealResponse, passengers: Passenger[]): number => {
    let count = 0;
    preOrderMealResponse?.preOrdersByRecordLocator?.preOrdersByRecordLocator.forEach((element) => {
      element.preOrders.forEach((preOrder) =>
        preOrder.guests.forEach((guest) => {
          const isPreorderMealGuestAPassenger = passengers?.find(
            (passenger) =>
                  passenger.firstName.toUpperCase() === guest.firstName.toUpperCase()
               && passenger.lastName.toUpperCase() === guest.lastName.toUpperCase()
          );
          if (isPreorderMealGuestAPassenger) {
            const orderedItemsLength = guest.orderedItems?.length;
            count = orderedItemsLength ? count + orderedItemsLength : 0;
          }
        }
      ));
    });

    return count;
  });
