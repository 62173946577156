import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { FlightShoppingResponseFull } from '../../../dtos/response/shopping-response/flight-shopping-response-full';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import { FlightShoppingFrapiResponse } from '../../../models/flight-shopping-frapi-response';

export const flightShoppingServiceFeatureKey = 'flightShoppingService';

export interface State extends RootState {
  flightShoppingService: FlightShoppingServiceState;
}

export interface FlightShoppingServiceState {
  results: FlightShoppingResponseFull | null;
  sortedFilteredResults: ShoppingSegment[][];
  status: Status;
  error: string;
  currentShoppingIndex: number;
  selectedResult: ShoppingSegment[];

  flightShoppingFrapiResponses: FlightShoppingFrapiEntity;
}

/**
 * Interface for storing flight shopping FRAPI search result objects as an ordered array of ids and an unordered dictionary
 */
export interface FlightShoppingFrapiEntity extends EntityState<FlightShoppingFrapiResponse> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the flight shopping FRAPI entity
 */
export const flighShoppingFrapiAdapter: EntityAdapter<FlightShoppingFrapiResponse> = createEntityAdapter<FlightShoppingFrapiResponse>({
  selectId: (response: FlightShoppingFrapiResponse) => response.id,
});

/**
 * Initial state of the search results entity as defined by the search results adapter
 */
export const initialFlightShoppingFrapiState: FlightShoppingFrapiEntity = flighShoppingFrapiAdapter.getInitialState({});

export const initialFlightShoppingServiceState: FlightShoppingServiceState = {
  results: null,
  sortedFilteredResults: [],
  status: Status.STABLE,
  error: '',
  currentShoppingIndex: 0,
  selectedResult: [],
  flightShoppingFrapiResponses: initialFlightShoppingFrapiState,
};
