import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { enrollMileagePlanMember, enrollMileagePlanMemberFailure, enrollMileagePlanMemberSuccess } from './mileage-plan-enrollment-service.actions';
import { MileagePlanEnrollmentService } from '../mileage-plan-enrollment.service';
import { MileagePlanMessageConverter } from '../../../utils/message-converters/mileage-plan/mileage-plan-message-converter';
import { HttpStatusCode } from '../../../dtos/http-status-code';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { addStackedMessageNoDuplicate } from '../../message-service/state/message.actions';
import { MessageKey } from '../../../models/message/message-key';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class MileagePlanEnrollmentServiceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private mileagePlanEnrollmentService: MileagePlanEnrollmentService,
    private mileagePlanMessageConverter: MileagePlanMessageConverter
  ) {}

  enrollMileagePlanMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enrollMileagePlanMember),
      switchMap((action) => {
        return this.mileagePlanEnrollmentService.enrollMPMember(action.mileagePlanEnrollmentRequest).pipe(
          map((mileagePlanEnrollmentResult) => {
            if(mileagePlanEnrollmentResult.statusCode !== HttpStatusCode.OK &&
              mileagePlanEnrollmentResult.statusCode !== HttpStatusCode.CREATED
            ){
              this.mileagePlanMessageConverter.convertMileagePlanEnrollmentMessage(mileagePlanEnrollmentResult);
              return enrollMileagePlanMemberFailure(mileagePlanEnrollmentResult);
            }
            const message = mileagePlanEnrollmentResult.statusCode === HttpStatusCode.OK ?
              MessageKey.MILEAGE_PLAN_ENROLLMENT_SUCCESS : MessageKey.MILEAGE_PLAN_ENROLLMENT_MEMBER_EXIST;
            this.store.dispatch(addStackedMessageNoDuplicate(
              message,
              [mileagePlanEnrollmentResult?.result?.mileagePlanNumber]
            ));
            return enrollMileagePlanMemberSuccess(mileagePlanEnrollmentResult);
          }),
          catchError((err: HttpErrorResponse) => {
            this.store.dispatch(addStackedMessageNoDuplicate(
              MessageKey.UNKNOWN_WITH_LINK
            ));
            return of(enrollMileagePlanMemberFailure({statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR, exceptionContent: err.message}));
          })
        );
      })
    )
  );
}
