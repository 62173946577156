import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import SumoLogger, { SumoLoggerOptions } from 'sumo-logger';


@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  /**
   * Sumo logic logger configuration
   * Ref: https://github.com/SumoLogic/js-sumo-logger
   */
  category: string;
  sumoLoggerConfig: SumoLoggerOptions = {
    endpoint: environment.sumoLoggerReciever,
    sourceName: `rain-ui.${environment.name}`
  };

  sumoLogger: SumoLogger;

  constructor() {
    // Initiliaze Sumo logic to send logs
    this.sumoLogger = new SumoLogger(this.sumoLoggerConfig);
  }

  log(msg: {}) {
    this.sumoLogger.log(msg);
  }

  // Force any pending logs to be sent out immediately.
  flushLogs() {
    this.sumoLogger.flushLogs();
  }
}
