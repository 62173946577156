import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { RootState } from '../../../state/state';
import { ContactInformationForm } from '../../../models/contact-information-forms/contact-information-form';

/**
 * The string name of the contact information forms 'slice' of state
 */
export const contactInformationFormsFeatureKey = 'contactInformationForms';

/**
 * Extension pattern used to prevent breaking lazy loading while maintaining a global state model
 */
export interface State extends RootState {
  contactInformationForms: ContactInformationFormsState;
}

/**
 * The state that represents the contact information forms
 */
export interface ContactInformationFormsState {
  contactInformationForms: ContactInformationFormEntity;
}

/**
 * Interface for storing ContactInformationForm objects as an ordered array of ids and an unordered dictionary
 */
export interface ContactInformationFormEntity extends EntityState<ContactInformationForm> {}

/**
 * NGRX provided adapter for interfacing with entities (modifying and selecting), this adapter is for the ContactInformationForm entity
 */
export const contactInformationFormsAdapter: EntityAdapter<ContactInformationForm> = createEntityAdapter<ContactInformationForm>({
  selectId: (contactInformationForm) => contactInformationForm.featureId,
});

/**
 * Initial state of the ContactInformationFormEntity as defined by the contactInformationFormsAdapter
 */
export const initialContactInformationFormsEntityState: ContactInformationFormEntity = contactInformationFormsAdapter.getInitialState({});


/**
 * Initial state for the contact information forms state
 */
export const initialContactInformationFormsState: ContactInformationFormsState = {
  contactInformationForms: initialContactInformationFormsEntityState,
};
