import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PurchaseResponse } from '../../../dtos/response/purchase-response/purchase-response';
import { BluefinShieldConexPayment } from '../../../models/payment-info/bluefin-payment';
import { CardOnFilePayment } from '../../../models/payment-info/card-on-file-payment';
import { Status } from '../../../models/status';
import { purchaseServiceFeatureKey, PurchaseServiceState } from './purchase-service.state';

export const getPurchaseServiceState = createFeatureSelector<PurchaseServiceState>(purchaseServiceFeatureKey);

export const getPurchaseResponse = createSelector(getPurchaseServiceState, (state): PurchaseResponse | null => state.response);
export const getPurchaseStatus = createSelector(getPurchaseServiceState, (state): Status => state.status);
export const getPurchaseEmail = createSelector(getPurchaseServiceState, (state): string | null => state.purchaseEmail);
export const getPurchaseEmailValid = createSelector(getPurchaseServiceState, (state): boolean => state.purchaseEmailValid);
export const getPaymentInfo = createSelector(
  getPurchaseServiceState,
  (state): CardOnFilePayment | BluefinShieldConexPayment | null => state.paymentInfo
);

/**
 * Get the current capture context stored
 */
export const getCaptureContext = createSelector(getPurchaseServiceState, (state): string => state.captureContext);

/**
 * Get the capture context error status
 */
export const getCaptureContextError = createSelector(getPurchaseServiceState, (state): boolean => state.captureContextError);

/**
 * Get the cyber source purchase triggered status
 */
export const getCyberSourcePurchaseTriggered = createSelector(
  getPurchaseServiceState,
  (state): boolean => state.cyberSourcePurchaseTriggered
);
