import { createAction } from '@ngrx/store';
import { seatMapV2FeatureKey } from './seat-map-v2.state';
import { PassengerSortTypes } from '../../../models/seat-map/passenger-sort-types';
import { SeatMapLookupRequestV2 } from '../../../dtos/request/seat-map-lookup-request/seat-map-lookup-request-v2';
import { SeatMapLookupResponse } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-response';
import { Seat } from '../../../models/seat-map/seat';

export const initializeSeatMapV2State = createAction(`[${seatMapV2FeatureKey}] Initialize`);

export const setPassengerSortType = createAction(`[${seatMapV2FeatureKey}] Set Passenger Sort Type`, (sortType: PassengerSortTypes) => ({
  sortType,
}));

export const setSelectedSeatMapPassengerHashId = createAction(
  `[${seatMapV2FeatureKey}] Set Selected Passenger hash ID`,
  (passengerHashId: string) => ({
    passengerHashId,
  })
);

/**
 * Get a seatmap for a set of flight data
 */
export const seatMapLookup = createAction(
  `[${seatMapV2FeatureKey}] Seat map lookup`,
  (seatMapLookupRequest: SeatMapLookupRequestV2) => ({
    seatMapLookupRequest,
  })
);

/**
 * Dispatched when a seatmap lookup is complete, regardless of failure or success
 */
export const seatMapLookupComplete = createAction(
  `[${seatMapV2FeatureKey}] Seat map lookup complete`,
  (seatMapLookupResponse: SeatMapLookupResponse) => ({
    seatMapLookupResponse,
  })
);

/**
 * Set the current seat map hashId
 */
export const setCurrentSeatMapHashId = createAction(
  `[${seatMapV2FeatureKey}] Set current seat map hashId`,
  (hashId: string) => ({
    hashId,
  })
);

export const setSelectedPassengerSeat = createAction(
  `[${seatMapV2FeatureKey}] Set selected passenger seat`,
  (seat: Seat) => ({
    seat,
  })
);
