import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { DirectivesModule } from '../../directives/directives.module';
import { BoundCheckboxComponent } from './bound-checkbox/bound-checkbox.component';
import { CallerNameComponent } from './caller-name/caller-name.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { PersistFormDirective } from './directives/persist-form.directive';
import { InputComponent } from './input/input.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { RadioButtonsComponent } from './radio-buttons/radio-buttons.component';
import { SelectComponent } from './select/select.component';
import { formsReducer } from './state/forms.reducer';
import { formsFeatureKey } from './state/forms.state';
import { TextareaComponent } from './textarea/textarea.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { CheckboxWithPopoverComponent } from './checkbox-with-popover/checkbox-with-popover.component';
import { AuroInputCustomComponent } from './auro-input-custom/auro-input-custom.component';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    CallerNameComponent,
    DatePickerComponent,
    RadioButtonsComponent,
    PhoneInputComponent,
    TextareaComponent,
    BoundCheckboxComponent,
    PersistFormDirective,
    CheckboxComponent,
    RadioButtonComponent,
    CheckboxWithPopoverComponent,
    AuroInputCustomComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DirectivesModule, StoreModule.forFeature(formsFeatureKey, formsReducer)],
  exports: [
    InputComponent,
    SelectComponent,
    CallerNameComponent,
    DatePickerComponent,
    RadioButtonsComponent,
    PhoneInputComponent,
    TextareaComponent,
    BoundCheckboxComponent,
    PersistFormDirective,
    CheckboxComponent,
    RadioButtonComponent,
    CheckboxWithPopoverComponent,
    AuroInputCustomComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormInputModule {}
