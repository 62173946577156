import { Injectable } from '@angular/core';
import { MessageKey } from '../../../models/message/message-key';
import { AddSsrsResponse } from '../../../dtos/response/ssr-response/add-ssrs-response';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';

@Injectable({
  providedIn: 'root',
})
export class SaveLapInfantMessageConverter {
  /**
   * Given an Add / Update Lap Infant response, evaluates the response and converts it into a message bar with content object.
   */
  public convertSaveLapInfantErrorMessage(response: AddSsrsResponse) {
    const mappedMessageKeys: MessageKey[] = [];

    // Convert Failures with invalid response. e.g. System Failure, Timeout, etc.
    this.convertSaveInfantSystemFailure(response, mappedMessageKeys);

    if (response?.failedToAddSsrs && response?.failedToAddSsrs?.length > 0) {
      const failures = response?.failedToAddSsrs;
      failures?.forEach((failure) => {
        const failureMessage = failure.failureMessage;
        this.convertSaveInfantFailure(failureMessage, mappedMessageKeys);
      });
    }
    // If we've identified an error and made it this far without mapping a key, we'll map the unknown error key.
    if (response.status !== AddSsrStatus.SUCCESS && mappedMessageKeys?.length === 0) {
      mappedMessageKeys?.push(MessageKey.LAP_INFANT_UNKNOWN_WITH_LINK);
    }

    // Partial Failure message, this will be on top of other error messages.
    if (response.status === AddSsrStatus.PARTIAL_SUCCESS) {
      mappedMessageKeys?.push(MessageKey.LAP_INFANT_PARTIAL_FAILURE);
    }

    return mappedMessageKeys;
  }

  private convertSaveInfantSystemFailure(response: AddSsrsResponse, mappedMessageKeys: MessageKey[]) {
    switch (true) {
      case response?.errorMessage?.toLowerCase().includes('timeout'):
        mappedMessageKeys?.push(MessageKey.LAP_INFANT_TIMEOUT);
        break;
      case response?.errorMessage?.toLowerCase().includes('invalid lap infant age'):
        mappedMessageKeys?.push(MessageKey.LAP_INFANT_INVALID_AGE);
        break;
      case response?.errorMessage?.toLowerCase().includes('password decryption error'):
        mappedMessageKeys?.push(MessageKey.PASSWORD_DECRYPTION_ERROR);
        break;
      default:
        break;
    }
  }

  private convertSaveInfantFailure(failureMessage: string | undefined, mappedMessageKeys: MessageKey[]) {
    switch (true) {
      case failureMessage?.toLowerCase().includes('adult is already associated with the lap infant'):
        mappedMessageKeys?.push(MessageKey.LAP_INFANT_ASSOCIATED);
        break;
      case failureMessage?.toLowerCase().includes('unknown error adding lap infant'):
        mappedMessageKeys?.push(MessageKey.LAP_INFANT_UNKNOWN);
        break;
      default:
        break;
    }
  }
}
