import { SeatMapData } from '../../../dtos/response/seat-map-lookup-response/seat-map-data';
import { PassengerSortTypes } from '../../../models/seat-map/passenger-sort-types';
import { RootState } from '../../../state/state';
import { Status } from '../../../models/status';

export const seatMapV2FeatureKey = 'seatMapV2';

export interface State extends RootState {
  seatMapV2: SeatMapV2State;
}

export interface SeatMapV2State {
  selectedPassengerHashId: string;
  currentPassengerSortType: PassengerSortTypes;
  seatMap?: SeatMapData;
  seatMapLookupCrudStatus?: Status;
  currentSeatMapHashId?: string;
}

export const initialSeatMapV2State: SeatMapV2State = {
  selectedPassengerHashId: '',
  currentPassengerSortType: PassengerSortTypes.Alphabetical,
  seatMap: undefined,
  seatMapLookupCrudStatus: Status.STABLE,
  currentSeatMapHashId: undefined,
};
