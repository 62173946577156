import { NewsFeedResponse } from '../../../dtos/response/news-feed-response/news-feed-response';
import { RootState } from '../../../state/state';
import { NewsFeedLookupStatus } from '../../../dtos/response/news-feed-response/news-feed-lookup-status';

export const newsFeedServiceFeatureKey = 'newsFeedService';

export interface State extends RootState {
  newsFeedService: NewsFeedServiceState;
}

export interface NewsFeedServiceState {
  newsFeed: NewsFeedResponse | null;
}

export const initialNewsFeedServiceState: NewsFeedServiceState = {
  newsFeed: {
    newsList: null,
    lookupStatus: NewsFeedLookupStatus.UNKNOWN
  },
};
