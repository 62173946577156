import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { PreOrderMealService } from '../preorder-meal.service';
import { loadPreOrderedMeals, loadPreOrderedMealsComplete } from './preorder-meal-service.actions';
import { PreOrderLookupStatus } from '../../../dtos/response/pre-order-meal-response/pre-order-lookup-status';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { getPreorderMealsRequestSegmentDetails } from './preorder-meal-service.selectors';

@Injectable()
export class PreOrderMealServiceEffects {
  constructor(private store: Store<RootState>, private actions$: Actions, private preOrderMealService: PreOrderMealService) {}

  loadPreOrderedMeals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPreOrderedMeals),
      withLatestFrom(this.store.select(getPreorderMealsRequestSegmentDetails)),
      mergeMap(([action, segmentDetails]) => {
        return this.preOrderMealService
          .getPreOrderMeals(action.recordLocator, segmentDetails)
          .pipe(map((preOrderMealResponse) => loadPreOrderedMealsComplete(preOrderMealResponse)));
      }),
      catchError(() => {
        return of(loadPreOrderedMealsComplete({status: PreOrderLookupStatus.SYSTEM_FAILURE, preOrdersByRecordLocator: null }));
      })
    )
  );
}
