import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ItineraryActionCode } from '../../../dtos/request/itinerary-request/itinerary-action-code';
import { ItineraryChangeRequest } from '../../../dtos/request/itinerary-request/itinerary-change-request';
import { ItineraryChangeType } from '../../../dtos/request/itinerary-request/itinerary-change-type';
import { RebookSegment } from '../../../dtos/request/itinerary-request/rebook-segment';
import { RebookSegmentsRequest } from '../../../dtos/request/itinerary-request/rebook-segments-request';
import { ShoppingSegment } from '../../../dtos/request/shopping-request/shopping-segment';
import { revalActionCodes } from '../../../dtos/response/action-code/reval-action-codes';
import { AirgroupAirlineCode } from '../../../dtos/response/flight-availability-response/airgroup-airline-code';
import { FlightSegment } from '../../../dtos/response/flight-availability-response/flight-segment';
import { ItineraryResponse } from '../../../dtos/response/itinerary-response/itinerary-response';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { CouponStatusData } from '../../../dtos/response/vcr-response/coupon-status-data';
import { FeatureContext } from '../../../models/feature-context';
import { FlightChangeFormRow } from '../../../models/flight-change-form/flight-change-form-row';
import { FlightChangeOptions } from '../../../models/flight-change-form/flight-change-option';
import { SelectedFlightOption } from '../../../models/flight-change-form/selected-flight-option';
import { PassengerNumbersAndTickets } from '../../../models/passenger-numbers-and-tickets';
import { SegmentAndCoupon } from '../../../models/segment-and-coupon';
import { getCallerName } from '../../../state/selectors';
import {
  getAllIrropChangedFlights,
  getAllScheduleChangeFlights,
  getAllSelectedAvailabilityOptions,
} from '../../flight-availability-service/state/flight-availability-service.selectors';
import { getSelectedFlightShoppingResult } from '../../flight-shopping-service/state/flight-shopping-service.selectors';
import {
  getAllPastDatedSegmentsExcludingARNK,
  getAllSegments,
  getAllUnmatchedSegments,
  getConfirmedAndPastDatedSegments,
  getConfirmedNonArnkSegments,
  getConfirmedSegments,
  getPassengers,
  getPrimaryPassengerLastName,
  getRoutedConfirmationCode,
  getSelectedInvoluntaryChangeOption,
  getUnconfirmedSegments,
} from '../../reservation-service/state/reservation-service.selectors';
import { getSdcEligibleSegments } from '../../sdc-eligibility-service/state/sdc-eligibility-service.selectors';
import {
  getAllOKAndUsedCoupons,
  getAllOKAndUsedCouponsExcludingIsPastSegmentCoupons,
  getAllOKCoupons,
  getOkAndCKINUniqueTicketNumbers,
  getOkUniqueTicketNumbers,
  getOnlyOkUsedUniqueTicketNumbers,
} from '../../ticket-service/state/ticket-service.selectors';
import { itineraryFeatureKey, ItineraryState } from './itinerary.state';
import { updateSegmentsHashIdForFcaaOsis } from '../../../utils/fcaa-util';
import { SpecialServiceRequest } from '../../../dtos/response/reservation-response/special-service-request';
import { FirstClassCabin } from '../../../models/cabins/first-class-cabin';

const flownStatuses = ['LFTD', 'USED'];

const getItineraryState = createFeatureSelector<ItineraryState>(itineraryFeatureKey);

/**
 * Get schedule change reissue response
 */
export const getReissueResponse = createSelector(getItineraryState, (state): ItineraryResponse => state.reissueResponse);

/**
 * Get reval response
 */
export const getRevalResponse = createSelector(getItineraryState, (state): ItineraryResponse => state.revalResponse);

/**
 * Get the property incidcating whether the reval/reissue path should allow the user to try again upon a FLIFO failure.
 * Returns true if the reissue response OR the reval response contains the given string, false otherwise.
 */
export const getFlifoDisabled = createSelector(getItineraryState, (state): boolean =>
  state.reissueResponse?.exceptionContent?.includes('FLIFO EXISTS FOR THIS FLIGHT') ||
  state.revalResponse?.exceptionContent?.includes('FLIFO EXISTS FOR THIS FLIGHT')
    ? true
    : false
);

/**
 * Get a dictionary of service coupons matched to confirmed segments, provided by segment hash id.
 * The matching here is somewhat loose to account for reissue scenarios where multiple coupons
 * could be associated with a segment.
 */
export const getCouponEntitiesForSegments = createSelector(
  getAllOKAndUsedCoupons,
  getConfirmedAndPastDatedSegments,
  (serviceCoupons: CouponStatusData[], segments: Segment[]): Record<string, CouponStatusData[]> => {
    const serviceCouponsBySegment: Record<string, CouponStatusData[]> = {};
    for (const segment of segments) {
      const matchingCoupons = serviceCoupons.filter((coupon) => {
        // If either the departure airport OR arrival airport matches then the coupon matches
        // the segment. This is to handle scenarios where non-stops become connecting flights...
        // a single coupon may refer to multiple segments.
        // Does not work in scenario where multiple segments have the same departure/arrival airport
        // Ex. SEA->PDX, PDX->SFO, SFO->PDX
        return segment.departureAirport === coupon.departureAirport || segment.arrivalAirport === coupon.arrivalAirport;
      });

      serviceCouponsBySegment[segment.hashId] = matchingCoupons;
    }

    return serviceCouponsBySegment;
  }
);

/**
 * Returns the confirmed segment list with its associated coupon
 */
export const getConfirmedSegmentAndCoupons = createSelector(
  getAllOKCoupons,
  getConfirmedSegments,
  (serviceCoupons: CouponStatusData[], confirmedSegments: Segment[]): SegmentAndCoupon[] =>
    confirmedSegments.map((seg) => {
      return {
        segment: seg,
        coupon: serviceCoupons.find(
          (coupon) =>
            seg.departureAirport === coupon.departureAirport &&
            seg.arrivalAirport === coupon.arrivalAirport &&
            seg.operatingAirlineFlightNumber === coupon.flightNumber &&
            seg.marketedByAirlineCode === coupon.airlineCode
        ),
      };
    })
);

/**
 * Returns the confirmed segment list with its associated coupons for all unflown flights
 */
export const getUnflownConfirmedSegmentsAndCoupons = createSelector(
  getConfirmedSegmentAndCoupons,
  (segmentsAndCoupons: SegmentAndCoupon[]): SegmentAndCoupon[] =>
    segmentsAndCoupons.filter((segmentAndCoupon) => {
      return segmentAndCoupon.coupon && !flownStatuses.includes(segmentAndCoupon.coupon.status);
    })
);

/**
 * Returns OSIs for FCAA segments
 */
export const getFcaaSegmentAndOsiDetails = createSelector(getAllSegments, getPassengers, (segments: Segment[], passengers: Passenger[]) =>
  updateSegmentsHashIdForFcaaOsis(segments, passengers)
);

/**
 * Maps all currently confirmed segments to rebook segments. This selector
 * is intended to be used for reissue requests where the guest accepts the
 * changes as is and does not change the itinerary.
 */
export const getConfirmedSegmentsAsRebookSegments = createSelector(
  getConfirmedAndPastDatedSegments,
  getUnconfirmedSegments,
  getCouponEntitiesForSegments,
  (segments: Segment[], unconfirmedSegments: Segment[], couponsBySegmentHashId: Record<string, CouponStatusData[]>) => {
    return addARNK(
      removeDuplicateSegments(
        segments
          .filter((segment) => revalActionCodes.includes(segment.actionCode))
          .map((seg) => {
            const matchingCoupons: CouponStatusData[] = couponsBySegmentHashId[seg.hashId];

            const couponNumbers: number[] = matchingCoupons.map((coupon) => {
              return +coupon.couponNumber;
            });

            // Grab the sequence id of the original segment (the segment the rebook is replacing)
            // Will only return 1, can only have 1 original segment
            const originalSegmentSequence: string = unconfirmedSegments.filter((unconfirmedSegment) =>
              seg.originalSegmentHashIds.includes(unconfirmedSegment.hashId)
            )[0]?.sequence;

            const originalSegmentId: string = unconfirmedSegments.filter((unconfirmedSegment) =>
              seg.originalSegmentHashIds.includes(unconfirmedSegment.hashId)
            )[0]?.segmentId;

            return {
              flightNumber: seg.operatingAirlineFlightNumber,
              airlineCode: seg.marketedByAirlineCode,
              operatingAirlineCode: seg.operatedByAirlineCode,
              departureDate: seg.departureDateTime,
              origin: seg.departureAirport,
              destination: seg.arrivalAirport,
              classOfService: seg.serviceClassCode,
              originalClassOfService: seg.originalClassOfService ?? null,
              actionCode: ItineraryActionCode.NN,
              overbookIfNeeded: getOverbookIfNeededForSegment(seg),
              couponNumbers,
              originalSegmentSequenceId: originalSegmentSequence,
              originalSegmentId,
            } as RebookSegment;
          })
      )
    );
  }
);

/**
 * For Schedule Change Reissue: gets the desired segment order for the itinerary.
 * Kept segments are accepted,
 * changed segments are updated,
 * cancelled segments are omitted.
 * an "ignored" or other status is invalid for this selector and will throw.
 */
export const getScheduleChangeReissueRebookSegments = createSelector(
  getAllScheduleChangeFlights,
  getAllSelectedAvailabilityOptions,
  getAllUnmatchedSegments,
  getCouponEntitiesForSegments,
  getAllPastDatedSegmentsExcludingARNK,
  getFcaaSegmentAndOsiDetails,
  (
    flightChangeRows: FlightChangeFormRow[],
    changedFlights: SelectedFlightOption[],
    allUnmatchedSegments: Segment[],
    couponsBySegmentHashId: Record<string, CouponStatusData[]>,
    pastDatedSegments: Segment[],
    fcaaOsis: SpecialServiceRequest[]
  ): RebookSegment[] => {
    let rebookSegments = [];
    let changedOptionIndex = 0;

    // This selector can be called when we aren't in the context of a valid
    // schedule change reissue (especially upon leaving the flow) so we bail
    // out with nothing to prevent an error.
    if (!flightChangeRows || flightChangeRows.length === 0) {
      return [];
    }

    rebookSegments = getPastDatedSegmentsWithoutUsedCoupons(pastDatedSegments, couponsBySegmentHashId).map((segment) => {
      return  {
        flightNumber: segment.operatingAirlineFlightNumber,
        airlineCode: segment.marketedByAirlineCode,
        operatingAirlineCode: segment.operatedByAirlineCode,
        departureDate: segment.departureDateTime,
        origin: segment.departureAirport,
        destination: segment.arrivalAirport,
        classOfService: segment.serviceClassCode,
        originalClassOfService: segment.originalClassOfService ?? null,
        actionCode: ItineraryActionCode.NN,
        overbookIfNeeded: getOverbookIfNeededForSegment(segment),
        couponNumbers: [0],
        originalSegmentSequenceId: segment.sequence,
        originalSegmentId: segment.segmentId,
        originalSegmentHashId: segment.hashId,
        isOriginalSegmentFCAA: segment.isAFCAASegment,
      } as RebookSegment;
    });

    for (let i = 0; i < flightChangeRows.length; i++) {
      const row = flightChangeRows[i];
      const currentSegment = allUnmatchedSegments[i];
      if (!currentSegment) {
        continue;
      }
      const matchingCoupons: CouponStatusData[] = !!currentSegment?.hashId ? couponsBySegmentHashId[currentSegment.hashId] : [];
      const couponNumbers: number[] = matchingCoupons?.map((coupon) => {
        return +coupon.couponNumber;
      });
      switch (row.changeType) {
        case FlightChangeOptions.KEEP:
          // We're keeping this segment as is so just return the existing one
          const existingRebookSegment: RebookSegment = {
            flightNumber: currentSegment.operatingAirlineFlightNumber,
            airlineCode: currentSegment.marketedByAirlineCode,
            operatingAirlineCode: currentSegment.operatedByAirlineCode,
            departureDate: currentSegment.departureDateTime,
            origin: currentSegment.departureAirport,
            destination: currentSegment.arrivalAirport,
            classOfService: currentSegment.serviceClassCode,
            originalClassOfService: currentSegment.originalClassOfService ?? null,
            actionCode: ItineraryActionCode.NN,
            overbookIfNeeded: getOverbookIfNeededForSegment(currentSegment),
            couponNumbers,
            originalSegmentSequenceId: currentSegment.sequence,
            originalSegmentId: currentSegment.segmentId,
            originalSegmentHashId: currentSegment.hashId,
            isOriginalSegmentFCAA: currentSegment.isAFCAASegment,
          };
          rebookSegments.push(existingRebookSegment);
          break;
        case FlightChangeOptions.CHANGE:
          const changed = changedFlights[changedOptionIndex];
          if (!changed || !changed.selectedOption) {
            continue;
          }
          const newSegments = changed.selectedOption.flightSegments;
          for (const newSegment of newSegments) {
            const newRebookSegment: RebookSegment = {
              flightNumber: newSegment.flightNumber,
              airlineCode: newSegment.marketingAirline,
              operatingAirlineCode: newSegment.operatingAirline,
              departureDate: newSegment.departureDateTime,
              origin: newSegment.departureAirport,
              destination: newSegment.arrivalAirport,
              // Class of service is taken from the original segment
              classOfService: currentSegment.serviceClassCode,
              originalClassOfService: currentSegment.originalClassOfService ?? null,
              actionCode: ItineraryActionCode.NN,
              overbookIfNeeded: getOverbookIfNeededForFlightSegment(newSegment),
              couponNumbers,
              originalSegmentSequenceId: currentSegment.sequence,
              originalSegmentId: currentSegment.segmentId,
              originalSegmentHashId: currentSegment.hashId,
              isOriginalSegmentFCAA: currentSegment.isAFCAASegment,
            };
            rebookSegments.push(newRebookSegment);
          }
          changedOptionIndex++;
          break;
        case FlightChangeOptions.CANCEL:
          // We've cancelled this part of the segment, so we simply omit it from the desired itinerary
          updateFcaaOsisWithCanceledSegment(fcaaOsis, currentSegment);
          break;
        default:
          throw new Error(`Unhandled flight change option: ${row.changeType}`);
      }
    }

    // Handle ARNK, will return original list if no ARNKs are needed
    return addARNK(removeDuplicateSegments(rebookSegments));
  }
);

function getPastDatedSegmentsWithoutUsedCoupons(
  pastDatedSegments: Segment[],
  couponsBySegmentHashId: Record<string, CouponStatusData[]>): Segment[] {
  return pastDatedSegments.filter(segment => couponsBySegmentHashId[segment.hashId]?.at(0)?.status?.toUpperCase() !== 'USED');
}

function updateFcaaOsisWithCanceledSegment(fcaaOsis: SpecialServiceRequest[], currentSegment: Segment) {
  fcaaOsis
    ?.filter((osi) => osi.segmentHashId === currentSegment.hashId)
    .forEach((osi) => {
      osi.isSegmentCancelled = true;
    });
}

/**
 * Get coupon numbers for a list of flights
 */
function getMatchingCouponNumbers(
  originalSegments: Segment[],
  serviceCoupons: CouponStatusData[],
  currentSegment: Segment,
  couponsBySegmentHashId: Record<string, CouponStatusData[]>
): number[] {
  let couponNumbers: number[] = [];

  originalSegments.forEach((originalSegment) => {
    couponNumbers = couponNumbers.concat(
      serviceCoupons
        .filter(
          (coupon) =>
            originalSegment.departureAirport === coupon.departureAirport &&
            originalSegment.arrivalAirport === coupon.arrivalAirport &&
            originalSegment.operatingAirlineFlightNumber === coupon.flightNumber &&
            coupon.status === 'OK'
        )
        .map((coupon) => +coupon.couponNumber)
    );
    return couponNumbers;
  });

  if (couponNumbers.length === 0) {
    const matchingCoupons: CouponStatusData[] = !!currentSegment?.hashId ? couponsBySegmentHashId[currentSegment.hashId] : [];
    const matchingCouponNumbers: number[] = matchingCoupons?.map((coupon) => {
      return +coupon.couponNumber;
    });
    couponNumbers = matchingCouponNumbers;
  }

  return [...new Set(couponNumbers)]; // Return the unique list - in multi passenger scenarios we might get [1,1]
}

/**
 * For IRROP Reissue: gets the desired segment order for the itinerary.
 * Ignored segments remain unchanged and are flagged not affected by reissue
 * Kept segments are accepted and flagged as impacted by reissue
 * Changed segments are updated and flagged as impacted by reissue
 * cancelled segments are omitted.
 */
export const getIrropReissueRebookSegments = createSelector(
  getAllIrropChangedFlights,
  getAllSelectedAvailabilityOptions,
  getAllUnmatchedSegments,
  getAllOKCoupons,
  getAllPastDatedSegmentsExcludingARNK,
  getAllSegments,
  getCouponEntitiesForSegments,
  getFcaaSegmentAndOsiDetails,
  (
    flightChangeRows: FlightChangeFormRow[],
    changedFlights: SelectedFlightOption[],
    allUnmatchedSegments: Segment[],
    serviceCoupons: CouponStatusData[],
    pastDatedSegments: Segment[],
    allSegments: Segment[],
    couponsBySegmentHashId: Record<string, CouponStatusData[]>,
    fcaaOsis: SpecialServiceRequest[]
  ): RebookSegment[] => {
    let rebookSegments = [];
    let changedOptionIndex = 0;

    // This selector can be called when we aren't in the context of a valid
    // schedule change reissue (especially upon leaving the flow) so we bail
    // out with nothing to prevent an error.
    if (!flightChangeRows || flightChangeRows.length === 0) {
      return [];
    }

    rebookSegments = getPastDatedSegmentsWithoutUsedCoupons(pastDatedSegments, couponsBySegmentHashId).map((segment) => {
      return {
        flightNumber: segment.operatingAirlineFlightNumber,
        airlineCode: segment.marketedByAirlineCode,
        operatingAirlineCode: segment.operatedByAirlineCode,
        departureDate: segment.departureDateTime,
        origin: segment.departureAirport,
        destination: segment.arrivalAirport,
        classOfService: segment.serviceClassCode,
        originalClassOfService: segment.originalClassOfService ?? null,
        actionCode: ItineraryActionCode.NN,
        overbookIfNeeded: getOverbookIfNeededForSegment(segment),
        couponNumbers: [0],
        impactedByReissue: false,
        originalSegmentHashId: segment.hashId,
        isOriginalSegmentFCAA: segment.isAFCAASegment,
      } as RebookSegment;
    });

    let couponsForCancelledSegment: number[] = [];
    for (let i = 0; i < flightChangeRows.length; i++) {
      const flightChangeRow = flightChangeRows[i];
      const currentSegment = allUnmatchedSegments[i];
      if (!currentSegment) {
        continue;
      }
      // Grab the original segment: either
      // This is the original segment already (dept/arriv cities will match)
      // This is 1 of 1+ flights replacing the original segment,
      //      the original segment hashId will be in the current segments originalSegmentHashIds
      const originalSegments = allSegments.filter(
        (segment) =>
          (currentSegment.originalSegmentHashIds?.length > 0 && currentSegment.originalSegmentHashIds?.includes(segment.hashId)) ||
          (segment.departureAirport === currentSegment.departureAirport && segment.arrivalAirport === currentSegment.arrivalAirport)
      );
      const couponNumbers: number[] = getMatchingCouponNumbers(
        originalSegments,
        serviceCoupons,
        currentSegment,
        couponsBySegmentHashId
      ).concat(couponsForCancelledSegment);
      // Need to sort because we could be concatenating potentially earlier coupons (in the CANCEL/CHANGE scenario)
      couponNumbers.sort((a, b) => a - b);
      // Reset list
      couponsForCancelledSegment = [];

      switch (flightChangeRow.changeType) {
        case FlightChangeOptions.IGNORE:
          // We're keeping this segment as is so just return the existing one
          const asIsRebookSegment: RebookSegment = {
            flightNumber: currentSegment.operatingAirlineFlightNumber,
            airlineCode: currentSegment.marketedByAirlineCode,
            operatingAirlineCode: currentSegment.operatedByAirlineCode,
            departureDate: currentSegment.departureDateTime,
            origin: currentSegment.departureAirport,
            destination: currentSegment.arrivalAirport,
            classOfService: currentSegment.serviceClassCode,
            originalClassOfService: currentSegment.originalClassOfService ?? null,
            actionCode: ItineraryActionCode.NN,
            overbookIfNeeded: getOverbookIfNeededForSegment(currentSegment),
            couponNumbers,
            impactedByReissue: false,
            isOriginalSegmentFCAA: currentSegment.isAFCAASegment,
            originalSegmentHashId: currentSegment.hashId,
          };
          rebookSegments.push(asIsRebookSegment);
          break;
        case FlightChangeOptions.KEEP:
          const existingRebookSegment: RebookSegment = {
            flightNumber: currentSegment.operatingAirlineFlightNumber,
            airlineCode: currentSegment.marketedByAirlineCode,
            operatingAirlineCode: currentSegment.operatedByAirlineCode,
            departureDate: currentSegment.departureDateTime,
            origin: currentSegment.departureAirport,
            destination: currentSegment.arrivalAirport,
            classOfService: currentSegment.serviceClassCode,
            originalClassOfService: currentSegment.originalClassOfService ?? null,
            actionCode: ItineraryActionCode.NN,
            overbookIfNeeded: getOverbookIfNeededForSegment(currentSegment),
            couponNumbers,
            impactedByReissue: true,
            originalSegmentSequenceId: currentSegment.sequence,
            originalSegmentId: currentSegment.segmentId,
            isOriginalSegmentFCAA: currentSegment.isAFCAASegment,
            originalSegmentHashId: currentSegment.hashId,
          };
          rebookSegments.push(existingRebookSegment);
          break;
        case FlightChangeOptions.CHANGE:
          const changed = changedFlights[changedOptionIndex];
          if (!changed || !changed.selectedOption) {
            continue;
          }
          const newSegments = changed.selectedOption.flightSegments;
          for (const newSegment of newSegments) {
            const newRebookSegment: RebookSegment = {
              flightNumber: newSegment.flightNumber,
              airlineCode: newSegment.marketingAirline,
              operatingAirlineCode: newSegment.operatingAirline,
              departureDate: newSegment.departureDateTime,
              origin: newSegment.departureAirport,
              destination: newSegment.arrivalAirport,
              // Class of service is taken from the original segment
              classOfService: currentSegment.serviceClassCode,
              originalClassOfService: currentSegment.originalClassOfService ?? null,
              actionCode: ItineraryActionCode.NN,
              overbookIfNeeded: getOverbookIfNeededForFlightSegment(newSegment),
              couponNumbers,
              impactedByReissue: true,
              originalSegmentSequenceId: currentSegment.sequence,
              originalSegmentId: currentSegment.segmentId,
              isOriginalSegmentFCAA: currentSegment.isAFCAASegment,
              originalSegmentHashId: currentSegment.hashId,
            };
            rebookSegments.push(newRebookSegment);
          }
          changedOptionIndex++;
          break;
        case FlightChangeOptions.CANCEL:
          // We've cancelled this part of the segment, so we simply omit it from the desired itinerary.
          // However, we still need to exchange the coupon.
          // 1. Determine cancelled coupon number
          const canceledCoupons = getMatchingCouponNumbers(originalSegments, serviceCoupons, currentSegment, couponsBySegmentHashId);
          // 2. If there are rebook segments (i.e. CHANGE/CANCEL scenario), add coupon to previous rebook segment
          if (rebookSegments.length) {
            const previousRebookSegmentCouponNumbers = (rebookSegments[rebookSegments.length - 1] as RebookSegment).couponNumbers;
            (rebookSegments[rebookSegments.length - 1] as RebookSegment).couponNumbers =
              previousRebookSegmentCouponNumbers.concat(canceledCoupons);
            // 3. Else (i.e. CANCEL/CHANGE scenario), push to a list to be added to the following rebook segment coupons
          } else {
            couponsForCancelledSegment = couponNumbers;
          }

          updateFcaaOsisWithCanceledSegment(fcaaOsis, currentSegment);
          break;
        default:
          throw new Error(`Unhandled flight change option: ${flightChangeRow.changeType}`);
      }
    }

    // Handle ARNK, will return original list if no ARNKs are needed
    return addARNK(removeDuplicateSegments(rebookSegments));
  }
);

/**
 * Gets a map of ticket nubmers to passenger numbers
 */
export const getPassengerDetails = createSelector(
  getOnlyOkUsedUniqueTicketNumbers,
  getPassengers,
  (ticketNumbers: string[], passengers: Passenger[]): PassengerNumbersAndTickets[] => {
    const passengerNumbersAndTickets: PassengerNumbersAndTickets[] = [];
    passengers.forEach((passenger, passengerIndex) => {
      passengerNumbersAndTickets.push({
        passengerNumber: passenger.id,
        ticketNumber: ticketNumbers[passengerIndex],
      });
    });
    return passengerNumbersAndTickets;
  }
);

export const getHasFCAA = createSelector(getAllSegments, (segments: Segment[]) => segments.some((segment) => segment.isAFCAASegment));

/**
 * Gets a schedule change reissue request from the store for when the guest
 * accepts the changes as is
 */
export const getScheduleReissueRequestAcceptFlights = createSelector(
  getConfirmedSegmentsAsRebookSegments,
  getCallerName,
  getRoutedConfirmationCode,
  getOkUniqueTicketNumbers,
  getPrimaryPassengerLastName,
  getPassengerDetails,
  getFcaaSegmentAndOsiDetails,
  (
    rebookSegments: RebookSegment[],
    callerName: string,
    confirmationCode: string,
    ticketNumbers: string[],
    primaryPassengerLastName,
    passengerNumbersAndTickets: PassengerNumbersAndTickets[],
    fcaaOsis: SpecialServiceRequest[]
  ): ItineraryChangeRequest => {
    return {
      transactionType: ItineraryChangeType.ScheduleChange,
      featureContext: FeatureContext.SCHEDULE_CHANGE,
      confirmationCode,
      segmentsRequest: {
        primaryPassengerLastName,
        desiredItinerary: rebookSegments,
        receivedFrom: callerName,
      },
      ticketNumbers,
      passengerNumbersAndTickets,
      fcaaOsis,
    };
  }
);

/**
 * Gets the Schedule Change Reissue Request from the store when the guest has elected to change
 * the itinerary.
 */
export const getScheduleChangeReissueNewFlightsRequest = createSelector(
  getScheduleChangeReissueRebookSegments,
  getCallerName,
  getRoutedConfirmationCode,
  getOkUniqueTicketNumbers,
  getPrimaryPassengerLastName,
  getPassengerDetails,
  getFcaaSegmentAndOsiDetails,
  (
    rebookSegments: RebookSegment[],
    callerName: string,
    confirmationCode: string,
    ticketNumbers: string[],
    primaryPassengerLastName,
    passengerNumbersAndTickets: PassengerNumbersAndTickets[],
    fcaaOsis: SpecialServiceRequest[]
  ): ItineraryChangeRequest => {
    return {
      transactionType: ItineraryChangeType.ScheduleChange,
      featureContext: FeatureContext.SCHEDULE_CHANGE,
      confirmationCode,
      segmentsRequest: {
        primaryPassengerLastName,
        desiredItinerary: rebookSegments,
        receivedFrom: callerName,
      },
      ticketNumbers,
      passengerNumbersAndTickets,
      fcaaOsis,
    };
  }
);

/**
 * Gets the IRROP Reissue Request from the store when the guest has elected to change
 * the itinerary.
 */
export const getIrropReissueNewFlightsRequest = createSelector(
  getIrropReissueRebookSegments,
  getCallerName,
  getRoutedConfirmationCode,
  getOkUniqueTicketNumbers,
  getPrimaryPassengerLastName,
  getPassengerDetails,
  getFcaaSegmentAndOsiDetails,
  (
    rebookSegments: RebookSegment[],
    callerName: string,
    confirmationCode: string,
    ticketNumbers: string[],
    primaryPassengerLastName,
    passengerNumbersAndTickets: PassengerNumbersAndTickets[],
    fcaaOsis: SpecialServiceRequest[]
  ): ItineraryChangeRequest => {
    return {
      transactionType: ItineraryChangeType.InvoluntaryReroute,
      featureContext: FeatureContext.IRROP,
      confirmationCode,
      segmentsRequest: {
        primaryPassengerLastName,
        desiredItinerary: rebookSegments,
        receivedFrom: callerName,
      },
      ticketNumbers,
      passengerNumbersAndTickets,
      fcaaOsis,
    };
  }
);

/**
 * Gets a reval request from the store for when the guest accepts their flights
 * This is for the "ACCEPT CHANGES" route where the guest is okay with the new version of their flight
 *      (i.e. the agent doesn't enter the "CHANGE FLIGHTS" portion of the flow)
 */
export const getRevalRequest = createSelector(
  getRoutedConfirmationCode,
  getOkUniqueTicketNumbers,
  getAllPastDatedSegmentsExcludingARNK,
  getConfirmedNonArnkSegments,
  getCallerName,
  getPrimaryPassengerLastName,
  getPassengerDetails,
  (
    confirmationCode: string,
    ticketNumbers: string[],
    pastDatedSegmentsMinusARNK: Segment[],
    nonArnkSegments: Segment[],
    callerName: string,
    primaryPassengerLastName: string,
    passengerNumbersAndTickets: PassengerNumbersAndTickets[]
  ): ItineraryChangeRequest => {
    // Start with pastDatedSegments. We need these to be in the desired itinerary so that
    // they aren't stripped off the reservation during the update itinerary step in the process.
    let rebookSegments = pastDatedSegmentsMinusARNK.map((segment) => {
      return {
        flightNumber: segment.operatingAirlineFlightNumber,
        airlineCode: segment.marketedByAirlineCode,
        departureDate: segment.departureDateTime,
        origin: segment.departureAirport,
        destination: segment.arrivalAirport,
        classOfService: segment.serviceClassCode,
        actionCode: ItineraryActionCode.NN,
        overbookIfNeeded: getOverbookIfNeededForSegment(segment),
        couponNumbers: [0],
        originalSegmentHashId: segment.hashId,
        isOriginalSegmentFCAA: segment.isAFCAASegment,
      } as RebookSegment;
    });

    // Concat our new segments
    rebookSegments = rebookSegments.concat(
      nonArnkSegments
        .filter((segment) => revalActionCodes.includes(segment.actionCode))
        .map((segment) => {
          return {
            flightNumber: segment.operatingAirlineFlightNumber,
            airlineCode: segment.marketedByAirlineCode,
            departureDate: segment.departureDateTime,
            origin: segment.departureAirport,
            destination: segment.arrivalAirport,
            classOfService: segment.serviceClassCode,
            actionCode: ItineraryActionCode.NN,
            overbookIfNeeded: getOverbookIfNeededForSegment(segment),
            couponNumbers: [0],
            originalSegmentHashId: segment.hashId,
            isOriginalSegmentFCAA: segment.isAFCAASegment,
          } as RebookSegment;
        })
    );

    const rebookSegmentsRequest: RebookSegmentsRequest = {
      primaryPassengerLastName,
      desiredItinerary: addARNK(removeDuplicateSegments(rebookSegments)), //addARNK will add ARNKs if needed
      receivedFrom: callerName,
    };
    return {
      transactionType: ItineraryChangeType.ScheduleChange,
      featureContext: FeatureContext.INVOLUNTARY_REVAL,
      confirmationCode,
      segmentsRequest: rebookSegmentsRequest,
      ticketNumbers,
      passengerNumbersAndTickets,
    } as ItineraryChangeRequest;
  }
);

export const getSdcRebookSegments = createSelector(
  getConfirmedSegments,
  getAllPastDatedSegmentsExcludingARNK,
  getSdcEligibleSegments,
  getSelectedFlightShoppingResult,
  getAllOKCoupons,
  (
    confirmedSegments: Segment[],
    pastDatedSegments: Segment[],
    sdcEligibleSegments: Segment[],
    selectedSegments: ShoppingSegment[],
    allCoupons: CouponStatusData[]
  ): RebookSegment[] => {
    // Start with pastDatedSegments. We need these to be in the desired itinerary so that
    // they aren't stripped off the reservation during the update itinerary step in the process.
    let rebookSegments = pastDatedSegments.map((segment) => {
      return {
        flightNumber: segment.operatingAirlineFlightNumber,
        airlineCode: segment.marketedByAirlineCode,
        operatingAirlineCode: segment.operatedByAirlineCode,
        departureDate: segment.departureDateTime,
        origin: segment.departureAirport,
        destination: segment.arrivalAirport,
        classOfService: segment.serviceClassCode,
        actionCode: ItineraryActionCode.NN,
        overbookIfNeeded: getOverbookIfNeededForSegment(segment),
        couponNumbers: [0],
        originalSegmentHashId: segment.hashId,
        isOriginalSegmentFCAA: segment.isAFCAASegment,
      } as RebookSegment;
    });
    rebookSegments = rebookSegments.concat(
      confirmedSegments.map((segment, index) => {
        if (sdcEligibleSegments.includes(segment)) {
          const selectedSegment = selectedSegments.find(
            (seg) => seg.departureAirportCode === segment.departureAirport && seg.arrivalAirportCode === segment.arrivalAirport
          );
          const sdcEligibleSegment = sdcEligibleSegments.find(
            (seg) => seg.departureAirport === segment.departureAirport && seg.arrivalAirport === segment.arrivalAirport
          );
          return {
            flightNumber: selectedSegment?.operationalFlightNumber.toString(),
            airlineCode: selectedSegment?.displayCarrierCode,
            operatingAirlineCode: selectedSegment?.operationalCarrierCode,
            departureDate: selectedSegment?.departureDateTime.toString(),
            origin: selectedSegment?.departureAirportCode,
            destination: selectedSegment?.arrivalAirportCode,
            classOfService: getCorrectClassOfService(
              confirmedSegments[index]?.serviceClassCode,
              selectedSegment,
              sdcEligibleSegment,
              allCoupons
            ),
            actionCode: ItineraryActionCode.NN,
            overbookIfNeeded: true,
            couponNumbers: [0],
            impactedBySdc: true,
            originalSegmentSequenceId: segment.sequence,
            originalSegmentId: segment.segmentId,
            originalSegmentHashId: segment.hashId,
            isOriginalSegmentFCAA: segment.isAFCAASegment,
            isDowngrade: selectedSegment?.isDowngrade ?? false,
          } as RebookSegment;
        } else {
          return {
            flightNumber: segment.operatingAirlineFlightNumber,
            airlineCode: segment.marketedByAirlineCode,
            operatingAirlineCode: segment.operatedByAirlineCode,
            departureDate: segment.departureDateTime.toString(),
            origin: segment.departureAirport,
            destination: segment.arrivalAirport,
            classOfService: confirmedSegments[index]?.serviceClassCode,
            actionCode: ItineraryActionCode.NN,
            overbookIfNeeded: true,
            couponNumbers: [0],
            originalSegmentHashId: segment.hashId,
            isOriginalSegmentFCAA: segment.isAFCAASegment,
          } as RebookSegment;
        }
      })
    );
    return rebookSegments;
  }
);

/**
 * Gets an SDC reval request from the store
 */
export const getSdcRevalRequest = createSelector(
  getRoutedConfirmationCode,
  getOkAndCKINUniqueTicketNumbers,
  getCallerName,
  getPrimaryPassengerLastName,
  getPassengerDetails,
  getSdcRebookSegments,
  getFcaaSegmentAndOsiDetails,
  (
    confirmationCode: string,
    ticketNumbers: string[],
    callerName: string,
    primaryPassengerLastName: string,
    passengerNumbersAndTickets: PassengerNumbersAndTickets[],
    rebookSegments: RebookSegment[],
    fcaaOsis: SpecialServiceRequest[]
  ): ItineraryChangeRequest => {
    const rebookSegmentsRequest: RebookSegmentsRequest = {
      primaryPassengerLastName,
      desiredItinerary: addARNK(removeDuplicateSegments(rebookSegments)),
      receivedFrom: callerName,
    };
    return {
      transactionType: ItineraryChangeType.ScheduleChange,
      confirmationCode,
      featureContext: FeatureContext.SAME_DAY_CONFIRM,
      segmentsRequest: rebookSegmentsRequest,
      ticketNumbers,
      passengerNumbersAndTickets,
      fcaaOsis,
    } as ItineraryChangeRequest;
  }
);

/**
 * Returns true if the request is eligible for revalidation as opposed to reissue, false otherwise.
 * A request is eligible for revalidation if the new flight routing and class of service match
 * the existing coupons.
 */
export const getRevalEligible = createSelector(
  getSelectedInvoluntaryChangeOption,
  getScheduleChangeReissueNewFlightsRequest,
  getIrropReissueNewFlightsRequest,
  getAllOKAndUsedCouponsExcludingIsPastSegmentCoupons,
  (
    involuntaryChangeType,
    scheduleChangeRequest,
    irropRequest,
    coupons
  ): boolean => {
    const desiredItinerary =
      involuntaryChangeType === 'schedule-change'
        ? scheduleChangeRequest.segmentsRequest.desiredItinerary
        : irropRequest.segmentsRequest.desiredItinerary;

    // Ignore ARNK segments, we need to send them with the update itinerary request so they aren't dropped
    //      but they won't match any coupons below which will cause the reval/reissue determination to be incorrect
    const desiredItineraryMinusARNK = desiredItinerary.filter((desiredSegment) => desiredSegment.flightNumber !== 'ARNK');

    // Ignore ARNKs, they won't have an airline code
    const anySegmentsOperatedByOA = desiredItineraryMinusARNK.some((segment) => !(segment.operatingAirlineCode in AirgroupAirlineCode));
    if (anySegmentsOperatedByOA) {
      return false;
    }

    for (let i = 0; i < desiredItineraryMinusARNK.length; i++) {
      if (
        desiredItineraryMinusARNK[i].origin !== coupons[i]?.departureAirport ||
        desiredItineraryMinusARNK[i].destination !== coupons[i]?.arrivalAirport ||
        (desiredItineraryMinusARNK[i].originalClassOfService ?? desiredItineraryMinusARNK[i].classOfService) !== coupons[i]?.classOfService
      ) {
        return false;
      }
    }
    return true;
  }
);

function getOverbookIfNeededForSegment(segment: Segment): boolean {
  if (!(segment.operatedByAirlineCode in AirgroupAirlineCode) && !(segment.marketedByAirlineCode in AirgroupAirlineCode)) {
    return false;
  }
  return true;
}

function getOverbookIfNeededForFlightSegment(segment: FlightSegment): boolean {
  if (!(segment.operatingAirline in AirgroupAirlineCode) && !(segment.marketingAirline in AirgroupAirlineCode)) {
    return false;
  }
  return true;
}

/**
 * Check for U class of service
 * If originally in U class of service and new rebook segment is downgrade,
 *    return the original class of service before the U upgrade found on the matching coupon
 */
function getCorrectClassOfService(
  currentClassOfService: string,
  selectedShoppingSegment: ShoppingSegment,
  sdcEligibleSegment: Segment,
  allCoupons: CouponStatusData[]
): string {
  // Current class of service is in U or FCAA and the selected flight is a downgrade
  // We need to send the class of service on the original coupon
  // Reval with U or FCAA will fail because the request and coupons won't match
  const firstClassCabinWithoutU = FirstClassCabin.filter((cc) => cc !== 'U');
  if (
    (currentClassOfService === 'U' || (firstClassCabinWithoutU.includes(currentClassOfService) && sdcEligibleSegment.isAFCAASegment)) &&
    selectedShoppingSegment?.isDowngrade
  ) {
    return allCoupons?.filter(
      (coupon) =>
        coupon.airlineCode === sdcEligibleSegment.marketedByAirlineCode &&
        coupon.departureAirport === sdcEligibleSegment.departureAirport &&
        coupon.arrivalAirport === sdcEligibleSegment.arrivalAirport &&
        coupon.flightNumber === sdcEligibleSegment.operatingAirlineFlightNumber
    )[0]?.classOfService;
  } else {
    // Non-U class of service, can use this class of service in reval
    return currentClassOfService;
  }
}

// -- Handle ARNKs --
// Add an ARNK where routing does not match up from segment to segment
// The ARNK should have already been on the reservation if it was setup properly
// We add the ARNK to the request so it does not get dropped during the itinerary update
function addARNK(rebookSegments: RebookSegment[]): RebookSegment[] {
  if (rebookSegments.length > 1) {
    const rebookSegmentsWithARNK = [];
    for (let i = 0; i < rebookSegments.length; i++) {
      if (i !== 0 && rebookSegments[i - 1].destination !== rebookSegments[i].origin) {
        rebookSegmentsWithARNK.push({
          flightNumber: 'ARNK',
        } as RebookSegment);
      }
      rebookSegmentsWithARNK.push(rebookSegments[i]);
    }

    return rebookSegmentsWithARNK;
  }

  return rebookSegments;
}

function removeDuplicateSegments(rebookSegments: RebookSegment[]): RebookSegment[] {
  if (rebookSegments.length === 0) {
    return rebookSegments;
  }
  const uniqueSegments = [];
  rebookSegments.forEach((segment) => {
    if (
      !uniqueSegments.some(
        (uniqueSegment) =>
          uniqueSegment.origin === segment.origin &&
          uniqueSegment.destination === segment.destination &&
          uniqueSegment.departureDate === segment.departureDate
      )
    ) {
      uniqueSegments.push(segment);
    }
  });
  return uniqueSegments;
}
