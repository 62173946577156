import { createAction } from '@ngrx/store';
import { AdvisoriesResponse } from '../../../dtos/response/advisories-response/advisories-response';
import { advisoriesServiceFeatureKey } from './advisories-service.state';
import { AdvisoryPageType } from '../../../dtos/request/advisories-request/advisory-page-type';
import { AdvisoryPageFilterTag } from '../../../dtos/request/advisories-request/advisory-page-filter-tag';

export const initializeAdvisoriesServiceState = createAction(`[${advisoriesServiceFeatureKey}] Initialize`);

export const loadAdvisories = createAction(`[${advisoriesServiceFeatureKey}] Load Advisories`,
    (advisoryPageType: AdvisoryPageType, advisoryPageFilterTag: AdvisoryPageFilterTag) => ({
        advisoryPageType,
        advisoryPageFilterTag
    })
);

export const loadAdvisoriesComplete = createAction(
  `[${advisoriesServiceFeatureKey}] Load Advisories Complete`,
  (advisoriesResponse: AdvisoriesResponse | null) => ({
    advisoriesResponse,
  })
);
