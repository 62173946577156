import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CallerContext } from '../../dtos/response/caller-context-response/caller-context';
import { UserData } from '../../dtos/response/login-response/user-data';
import {
  setActiveCallerId,
  startCallerPolling,
  stopCallerPolling,
} from 'src/app/services/caller-context/state/caller-context-service.actions';
import {
  getActiveCaller,
  getActiveCallerErrorMessage,
  getActiveCallerIndexBaseOne,
  getCallerCount,
  getCallers,
} from 'src/app/services/caller-context/state/caller-context-service.selectors';
import { LoggerService } from '../../services/logger-service/logger.service';
import { getUser } from '../../services/login-service/state/login-service.selector';
import { RootState } from '../../state/state';

@Component({
  selector: 'app-caller-context',
  templateUrl: './caller-context.component.html',
  styleUrls: ['./caller-context.component.scss'],
})
export class CallerContextComponent implements OnInit, OnDestroy {
  attributes: SplitIO.Attributes;
  newCallerContextTreatment: boolean;
  callerCount$: Observable<number>;
  callers$: Observable<CallerContext[]>;
  activeCaller$: Observable<CallerContext | null | undefined>;
  activeCallerIndex$: Observable<number | null>;
  errorMessage$: Observable<string | undefined>;
  numberOfCallers = 0;
  callerIds: string[] = [];
  caller: CallerContext;
  private user: UserData;
  private onDestroy$ = new Subject<void>();

  constructor(private store: Store<RootState>, private router: Router, private loggerService: LoggerService) {}

  ngOnInit(): void {
    this.store.dispatch(startCallerPolling());
    this.callerCount$ = this.store.pipe(select(getCallerCount));
    this.callers$ = this.store.select(getCallers);
    this.activeCaller$ = this.store.pipe(select(getActiveCaller));
    this.activeCallerIndex$ = this.store.select(getActiveCallerIndexBaseOne);
    this.errorMessage$ = this.store.pipe(select(getActiveCallerErrorMessage));

    this.store.pipe(select(getCallers), takeUntil(this.onDestroy$)).subscribe((callers) => {
      const mostRecentCallerIds = callers.map((caller) => caller.id);
      const size = callers?.length ?? 0;
      // If there has been a change in callers and the number of callers has either
      // increased or remained the same, find the new caller and set it as active.
      if (this.callerIds !== mostRecentCallerIds && size >= this.numberOfCallers) {
        const newCaller = callers.find((caller) => !this.callerIds.includes(caller.id));
        if (newCaller) {
          this.store.dispatch(setActiveCallerId(newCaller.id));
        }
      }
      this.callerIds = mostRecentCallerIds;
      this.numberOfCallers = size;
    });
    this.store.pipe(select(getActiveCaller)).subscribe((caller) => {
      this.caller = caller;
    });
    this.store.pipe(select(getUser), takeUntil(this.onDestroy$)).subscribe((user) => { this.user = user;});
  }

  ngOnDestroy(): void {
    this.store.dispatch(stopCallerPolling());
    this.onDestroy$.next();
  }

  callerHasInfo(caller: CallerContext): boolean {
    return !!(caller?.callerFirstName || caller?.callerLastName || caller?.confirmationCode || caller?.loyaltyNumber || caller?.tierStatus);
  }

  openReservation(confirmationCode: string): void {
    this.loggerService.log({
      event: 'CallerContext',
      linkType: 'ConfirmationCode',
      linkNumber: confirmationCode,
      username: this.user.username,
      homeCity: this.user.sabreAAA,
    });

    this.router.navigate(['reservation', confirmationCode]);
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.code === 'Space') {
      this.openReservation(this.caller?.confirmationCode);
    }
  }
}
